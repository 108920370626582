import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Radio from '../../components/Radio';
import Row from '../../components/Row';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
//
import { ListFormat, NumberFormat } from '../../modules/utils/format';
import { VacationGender, VacationSalary, VacationProof } from '../../modules/utils/list';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const SettingVacation = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [keyword, setKeyword] = useState('');
  const [vacationData, setVacationData] = useState({});
  const [vacationList, setVacationList] = useState([]);
  //
  const viewList = useMemo(() => {
    return vacationList.filter((v) => !keyword || v.vacationName.match(keyword));
  }, [vacationList, keyword]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrVacation' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false)
            setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load menus
  const loadVacationList = useCallback(() => {
    axios
      .get('/api/setting/vacation/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setVacationList(res.data.result.vacations.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadVacationList();
  }, [loadVacationList]);

  //============================================================
  const handleModalOpen = (type, record) => () => {
    if (record) {
      setVacationData(record);
    }
    setModalProps({ open: true, type: type });
  };
  const handleModalClose = () => {
    setVacationData({});
    setModalProps({ open: false, type: '' });
  };
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };
  const handleVacationDataChange = (name) => (e) => {
    setVacationData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleSave = () => {
    //valid
    const validResult = ValidFormData(vacationData, [
      { name: 'vacationName', type: 'input', required: true },
      { name: 'code', type: 'input', required: true },
      { name: 'gender', type: 'select', required: true },
      { name: 'salaryFlag', type: 'select', required: true },
      { name: 'proofFlag', type: 'select', required: true },
      { name: 'minHours', type: 'number', required: true },
      { name: 'vacationHours', type: 'number', required: true },
      { name: 'limitDaysBefore', type: 'number', required: false },
      { name: 'limitDaysAfter', type: 'number', required: false },
    ]);
    if (!validResult.status) {
      setVacationData(validResult.data);
      return;
    }

    //
    axios
      .post('/api/setting/vacation/save', vacationData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadVacationList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = (vacationId) => () => {
    axios
      .post('/api/setting/vacation/delete', { vacationId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除成功');
          loadVacationList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>假別管理</Title>
            </Col>
            <Col>
              <InputInline
                label="關鍵字"
                placeholder="請輸入假別名稱"
                value={keyword}
                onChange={handleKeywordChange}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen('add', null)} icon={<PlusOutlined />}>
            新增假別
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal width="800px" visible={modalProps.open}>
        <Row>
          <Col span={12}>
            <Display label="假別識別碼" value={vacationData.vacationId || '自動產生'} />
          </Col>
          <Col span={12}>
            <Input
              required
              label="假別代碼"
              value={vacationData.code}
              msg={vacationData.code_msg}
              onChange={handleVacationDataChange('code')}
            />
          </Col>
          <Col span={24}>
            <Input
              required
              label="假別名稱"
              value={vacationData.vacationName}
              msg={vacationData.vacationName_msg}
              onChange={handleVacationDataChange('vacationName')}
            />
          </Col>
          <Col span={8}>
            <Radio
              required
              label="適用性別"
              options={VacationGender}
              value={vacationData.gender}
              msg={vacationData.gender_msg}
              onChange={handleVacationDataChange('gender')}
            />
          </Col>
          <Col span={8}>
            <Radio
              required
              label="計薪"
              options={VacationSalary}
              value={vacationData.salaryFlag}
              msg={vacationData.salaryFlag_msg}
              onChange={handleVacationDataChange('salaryFlag')}
            />
          </Col>
          <Col span={8}>
            <Radio
              required
              label="證明"
              options={VacationProof}
              value={vacationData.proofFlag}
              msg={vacationData.proofFlag_msg}
              onChange={handleVacationDataChange('proofFlag')}
            />
          </Col>
          <Col span={12}>
            <Input
              required
              label="最小可請時數"
              value={vacationData.minHours}
              msg={vacationData.minHours_msg}
              onChange={handleVacationDataChange('minHours')}
            />
          </Col>
          <Col span={12}>
            <Input
              required
              label="年度可請時數"
              value={vacationData.vacationHours}
              msg={vacationData.vacationHours_msg}
              onChange={handleVacationDataChange('vacationHours')}
            />
          </Col>
          <Col span={12}>
            <Input
              label="前延天數"
              value={vacationData.limitDaysBefore}
              msg={vacationData.limitDaysBefore_msg}
              onChange={handleVacationDataChange('limitDaysBefore')}
            />
          </Col>
          <Col span={12}>
            <Input
              label="後延天數"
              value={vacationData.limitDaysAfter}
              msg={vacationData.limitDaysAfter_msg}
              onChange={handleVacationDataChange('limitDaysAfter')}
            />
          </Col>
          <Col span={24}>
            <Textarea
              rows={3}
              label="備註"
              value={vacationData.memo}
              onChange={handleVacationDataChange('memo')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '假別代碼',
      dataIndex: 'code',
      key: 'code',
      width: 100,
    },
    {
      title: '假別名稱',
      dataIndex: 'vacationName',
      key: 'vacationName',
    },
    {
      title: '適用性別',
      dataIndex: 'gender',
      key: 'gender',
      width: 100,
      render: (value) => ListFormat(VacationGender, value),
    },
    {
      title: '計薪',
      dataIndex: 'salaryFlag',
      key: 'salaryFlag',
      width: 80,
      render: (value) => ListFormat(VacationSalary, value),
    },
    {
      title: '證明',
      dataIndex: 'proofFlag',
      key: 'proofFlag',
      width: 120,
      render: (value) => ListFormat(VacationProof, value),
    },
    {
      title: '最小可請時數',
      dataIndex: 'minHours',
      key: 'minHours',
      width: 120,
      render: (value) => NumberFormat(value),
    },
    {
      title: '年度可請時數',
      dataIndex: 'vacationHours',
      key: 'vacationHours',
      width: 120,
      render: (value) => NumberFormat(value),
    },
    {
      title: '前延天數',
      dataIndex: 'limitDaysBefore',
      key: 'limitDaysBefore',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '後延天數',
      dataIndex: 'limitDaysAfter',
      key: 'limitDaysAfter',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '備註',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={openFn('edit', record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確認刪除此假別？"
              placement="topRight"
              okText="確定刪除"
              cancelText="取消"
              onConfirm={deleteFn(record.vacationId)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

export default SettingVacation;
