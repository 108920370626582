import React, { useEffect, useState } from 'react';
import { useHistory, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePickerInline from '../../components/DatePicker/inline';
import Divider from '../../components/Divider';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Table from '../../components/Table';
import Title from '../../components/Title';
import SelectInline from '../../components/Select/inline';
import InputInline from '../../components/Input/inline';
import { Button } from 'antd';
import { SelectOutlined, PlusOutlined } from '@ant-design/icons';
//
import { DateFormat, ListFormat } from '../../modules/utils/format';
import { FormStatus, SealType } from '../../modules/utils/list';
import { SetKeyIntoArray, GenerateFormLink, UseDebounce } from '../../modules/utils/tool';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const DetailColumns = [
  {
    title: '用途',
    dataIndex: 'sealType',
    key: 'sealType',
    width: 100,
    render: (value) => ListFormat(SealType, value),
  },
  {
    title: '文件',
    dataIndex: 'doc',
    key: 'doc',
  },
];
const MainColumns = [
  {
    title: '單號',
    dataIndex: 'formNumber',
    key: 'formNumber',
    width: 150,
    fixed: 'left',
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '申請日期',
    dataIndex: 'applyDate',
    key: 'applyDate',
    width: 110,
    fixed: 'left',
    render: (value) => DateFormat(value),
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '申請人',
    dataIndex: 'userName',
    key: 'userName',
    width: 110,
    fixed: 'left',
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '內容',
    dataIndex: 'contractTarget',
    key: 'contractTarget',
    width: 200,
    render: (value, record) => (
      <div>
        <div>
          <strong>簽約對象</strong>
        </div>
        {(Array.isArray(record.contractTarget) && record.contractTarget.length > 0) ?
          <div>
            {record.contractTarget.map((v, vIdx) => (
              <div key={vIdx}>{v}</div>
            ))}
          </div> : null
        }
        {record.projectName ?
          <div>
            <div>
              <strong>專案名稱</strong>
            </div>
            <div>{record.projectName}</div>
          </div> : null
        }
      </div>
    ),
  },
  {
    title: '文件明細',
    dataIndex: 'details',
    key: 'details',
    className: 'j-table-col',
    width: 700,
    render: (value) => {
      if (Array.isArray(value) && value.length > 0) {
        return (
          <Table className="j-inner-table" columns={DetailColumns} dataSource={value.map(SetKeyIntoArray)} />
        );
      } else {
        return null;
      }
    },
  },
  {
    title: '用印日期',
    dataIndex: 'useSealDate',
    key: 'useSealDate',
    width: 110,
    fixed: 'right',
    render: (value) => DateFormat(value),
  },
  {
    title: '簽核狀態',
    dataIndex: 'signStatus',
    key: 'signStatus',
    width: 110,
    fixed: 'right',
    render: (value) => ListFormat(FormStatus, value),
  },
  {
    title: '目前簽核者',
    dataIndex: 'signerNames',
    key: 'signerNames',
    width: 110,
    fixed: 'right',
    render: (value) => {
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((v, vIdx) => (
              <div key={vIdx}>{v}</div>
            ))}
          </div>
        );
      } else {
        return value;
      }
    },
  },
  {
    title: '連結',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 80,
    fixed: 'right',
    render: (value, record) => {
      return (
        <Link to={GenerateFormLink('seal', record.formSealId)}>
          <Button icon={<SelectOutlined />} />
        </Link>
      );
    },
  },
];

const FormSealSearch = () => {
  const { user } = useProvided(useAuth);
  const [redirectFlag, setRedirectFlag] = useState('');
  const [userList, setUserList] = useState([]);
  const formSealCond = window.sessionStorage.getItem('formSealCond_' + user.userId);
  const storeCond = formSealCond ? JSON.parse(formSealCond) : null;
  const [filterData, setFilterData] = useState({
    begin: (storeCond) ? moment(storeCond.begin, 'YYYY-MM-DD') : moment().startOf('month'),
    end: (storeCond) ? moment(storeCond.end, 'YYYY-MM-DD') : moment().endOf('month'),
    applyId: (storeCond) ? storeCond.applyId : user.userId,
    signStatus: (storeCond) ? storeCond.signStatus : '',
    formNumber: (storeCond) ? storeCond.formNumber : '',
  });
  const [sealList, setSealList] = useState([]);
  let history = useHistory();

  //=======================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'efSeal' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false)
            setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  useEffect(() => {
    let conditions = {
      begin: DateFormat(filterData.begin),
      end: DateFormat(filterData.end),
      applyId: filterData.applyId,
      signStatus: filterData.signStatus,
      formNumber: filterData.formNumber
    };

    axios
      .post(
        '/api/seal/search', conditions,
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          setSealList(res.data.result.seals.map(SetKeyIntoArray));
          window.sessionStorage.setItem('formSealCond_' + user.userId, JSON.stringify(conditions));
        } else {
          Notify.warn('查詢失敗：', res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user, filterData]);

  //load user list
  useEffect(() => {
    axios
      .get('/api/setting/user/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          //增加"全部"選項
          res.data.result.users.unshift({ userId: '', userName: '全部' });

          setUserList(
            res.data.result.users.map((u) => ({
              label: u.userName,
              value: u.userId,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  //=======================================================
  const handleFilterDataChange = (name) => (val) => {
    setFilterData((prev) => ({ ...prev, [name]: val }));
  };
  const handleRowClick = (record) => {
    history.push(GenerateFormLink('seal', record.formSealId));
  };
  const handleOnChangeInput = (event) => {
    event.persist();
    setFilterData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const optimisedHandleChange = UseDebounce(handleOnChangeInput, 1000);

  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>用印申請單查詢</Title>
        </Col>
        <Col>
          <Link to="/form/seal">
            <Button type="primary" size="large" icon={<PlusOutlined />}>
              新增用印申請單
            </Button>
          </Link>
        </Col>
      </Row>
      <br />
      <Row justify="space-between" align="middle">
        <Col>
          <DatePickerInline
            label="申請開始時間"
            value={filterData.begin}
            onChange={handleFilterDataChange('begin')}
            style={{ minWidth: '120px' }}
            allowClear={false}
          />
        </Col>
        <Col>
          <DatePickerInline
            label="申請結束時間"
            value={filterData.end}
            onChange={handleFilterDataChange('end')}
            style={{ minWidth: '120px' }}
            allowClear={false}
          />
        </Col>
        <Col>
          <SelectInline
            canSearch
            label="申請人"
            options={userList}
            value={filterData.applyId}
            onChange={handleFilterDataChange('applyId')}
            style={{ minWidth: '120px' }}
          />
        </Col>
        <Col>
          <SelectInline
            canSearch
            label="簽核狀態"
            options={FormStatus}
            value={filterData.signStatus}
            onChange={handleFilterDataChange('signStatus')}
            style={{ minWidth: '120px' }}
          />
        </Col>
        <Col>
          <InputInline
            label="單號"
            name="formNumber"
            defaultValue={filterData.formNumber}
            onChange={optimisedHandleChange}
          />
        </Col>
      </Row>
      <Divider />
      <Table columns={MainColumns} dataSource={sealList} scroll={{ x: 1600 }} doubleClick={true}
        onRow={(record) => {
          return { onDoubleClick: () => { handleRowClick(record) } }
        }}
      />
    </div>
  );
};

export default FormSealSearch;
