import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
//
import Avatar from './avatar';
import Menu from './menu';
//
import Dashboard from '../Dashboard';
import SettingApproval from '../SettingApproval';
import SettingClockRecord from '../SettingClockRecord';
import SettingInsurance from '../SettingInsurance';
import SettingInvoiceItem from '../SettingInvoiceItem';
import SettingDepartment from '../SettingDepartment';
import SettingEmployee from '../SettingEmployee';
import SettingMenu from '../SettingMenu';
import SettingFeature from '../SettingFeature';
import SettingForm from '../SettingFormFlow/form';
import SettingFlow from '../SettingFormFlow';
import SettingPayroll from '../SettingPayroll';
import SettingPayrollSearch from '../SettingPayroll/search';
import SettingRole from '../SettingRole';
import SettingSchedule from '../SettingSchedule';
import SettingVacation from '../SettingVacation';
import SettingUser from '../SettingUser';
import SettingUserSalary from '../SettingUserSalary';
import SettingUserVacation from '../SettingUserVacation';
import SettingTrello from '../SettingTrello';
import SettingSalaryWithholdingTax from '../SettingSalaryWithholdingTax';
import LeaveCalendar from '../LeaveCalendar';
import FormCharge from '../FormCharge';
import FormChargeSearch from '../FormCharge/search';
import FormVacation from '../FormVacation';
import FormVacationSearch from '../FormVacation/search';
import FormSeal from '../FormSeal';
import FormSealSearch from '../FormSeal/search';
import FormInvoice from '../FormInvoice';
import FormInvoiceSearch from '../FormInvoice/search';
import PageNotFound from '../PageNotFound';

const AppInner = () => {
  return (
    <InnerPage>
      <Sider>
        <Avatar />
        <Menu />
      </Sider>
      <Content>
        <Switch>
          <Route exact path="/home" component={Dashboard} />
          <Route exact path="/leaveCalendar" component={LeaveCalendar} />
          <Route exact path="/setting/approval" component={SettingApproval} />
          <Route exact path="/setting/clock" component={SettingClockRecord} />
          <Route exact path="/setting/insurance" component={SettingInsurance} />
          <Route exact path="/setting/invoice" component={SettingInvoiceItem} />
          <Route exact path="/setting/department" component={SettingDepartment} />
          <Route exact path="/setting/employee" component={SettingEmployee} />
          <Route exact path="/setting/menu" component={SettingMenu} />
          <Route exact path="/setting/feature" component={SettingFeature} />
          <Route exact path="/setting/flow" component={SettingFlow} />
          <Route exact path="/setting/form" component={SettingForm} />
          <Route exact path="/setting/payroll" component={SettingPayroll} />
          <Route exact path="/setting/payroll/search" component={SettingPayrollSearch} />
          <Route exact path="/setting/role" component={SettingRole} />
          <Route exact path="/setting/schedule" component={SettingSchedule} />
          <Route exact path="/setting/vacation" component={SettingVacation} />
          <Route exact path="/setting/userinfo" component={SettingUser} />
          <Route exact path="/setting/userSalary" component={SettingUserSalary} />
          <Route exact path="/setting/userVacation" component={SettingUserVacation} />
          <Route exact path="/setting/trello" component={SettingTrello} />
          <Route exact path="/setting/salaryWithholdingTax" component={SettingSalaryWithholdingTax} />
          <Route exact path="/form/charge" component={FormCharge} />
          <Route exact path="/form/charge/search" component={FormChargeSearch} />
          <Route exact path="/form/vacation" component={FormVacation} />
          <Route exact path="/form/vacation/search" component={FormVacationSearch} />
          <Route exact path="/form/seal" component={FormSeal} />
          <Route exact path="/form/seal/search" component={FormSealSearch} />
          <Route exact path="/form/invoice" component={FormInvoice} />
          <Route exact path="/form/invoice/search" component={FormInvoiceSearch} />
          <Route component={PageNotFound} />
        </Switch>
      </Content>
    </InnerPage>
  );
};

const InnerPage = styled.div`
  min-width: 1366px;
  width: 100vw;
  display: flex;
`;
const Sider = styled.div`
  width: 240px;
  height: 100vh;
  overflow: hidden;
`;
const Content = styled.div`
  width: calc(100% - 240px);
  height: 100vh;
  padding: 10px 50px 10px 50px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export default AppInner;
