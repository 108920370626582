import React, { useEffect, useState } from 'react';
import { useHistory, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePickerInline from '../../components/DatePicker/inline';
import Divider from '../../components/Divider';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Title from '../../components/Title';
import InputInline from '../../components/Input/inline';
import { Button } from 'antd';
import { SelectOutlined, PlusOutlined } from '@ant-design/icons';
//
import { DateFormat, ListFormat, NumberFormat } from '../../modules/utils/format';
import { FormStatus } from '../../modules/utils/list';
import { SetKeyIntoArray, GenerateFormLink, UseDebounce } from '../../modules/utils/tool';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const MainColumns = [
  {
    title: '單號',
    dataIndex: 'formNumber',
    key: 'formNumber',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '申請日期',
    dataIndex: 'applyDate',
    key: 'applyDate',
    width: 50,
    fixed: 'left',
    render: (value) => DateFormat(value),
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '申請人',
    dataIndex: 'userName',
    key: 'userName',
    width: 50,
    fixed: 'left',
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '內容',
    dataIndex: 'content',
    key: 'content',
    width: 300,
    render: (value, record) => (
      <div>
        {record.chargeYearMon && (
          <div>
            <strong>月結月份：</strong>
            {record.chargeYearMon}
          </div>
        )}
        <div>
          <strong>收款項目：</strong>
          {record.chargeItem}
        </div>
        <div>
          <strong>收款對象：</strong>
          {record.chargeParty}
        </div>
        <div>
          <strong>金額總計：</strong>
          {NumberFormat(record.totalAmount, 'NT$')}
        </div>
      </div>
    ),
  },
  {
    title: '簽核狀態',
    dataIndex: 'signStatus',
    key: 'signStatus',
    width: 80,
    fixed: 'right',
    render: (value) => ListFormat(FormStatus, value),
  },
  {
    title: '目前簽核者',
    dataIndex: 'signerNames',
    key: 'signerNames',
    width: 80,
    fixed: 'right',
    render: (value) => {
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((v, vIdx) => (
              <div key={vIdx}>{v}</div>
            ))}
          </div>
        );
      } else {
        return value;
      }
    },
  },
  {
    title: '連結',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (value, record) => {
      return (
        <Link to={GenerateFormLink('charge', record.formChargeId)}>
          <Button icon={<SelectOutlined />} />
        </Link>
      );
    },
  },
];

const FormChargeSearch = () => {
  const { user } = useProvided(useAuth);
  const [redirectFlag, setRedirectFlag] = useState('');
  const [userList, setUserList] = useState([]);
  const formChargeCond = window.sessionStorage.getItem('formChargeCond_' + user.userId);
  const storeCond = formChargeCond ? JSON.parse(formChargeCond) : null;
  const [filterData, setFilterData] = useState({
    begin: storeCond ? moment(storeCond.begin, 'YYYY-MM-DD') : moment().startOf('month'),
    end: storeCond ? moment(storeCond.end, 'YYYY-MM-DD') : moment().endOf('month'),
    applyId: storeCond ? storeCond.applyId : user.userId,
    signStatus: storeCond ? storeCond.signStatus : '',
    formNumber: storeCond ? storeCond.formNumber : '',
  });
  const [chargeList, setChargeList] = useState([]);
  let history = useHistory();

  //=======================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'efCharge' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  useEffect(() => {
    let conditions = {
      begin: DateFormat(filterData.begin),
      end: DateFormat(filterData.end),
      applyId: filterData.applyId,
      signStatus: filterData.signStatus,
      formNumber: filterData.formNumber,
    };

    axios
      .post('/api/charge/search', conditions, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setChargeList(res.data.result.charges.map(SetKeyIntoArray));
          window.sessionStorage.setItem('formChargeCond_' + user.userId, JSON.stringify(conditions));
        } else {
          Notify.warn('查詢失敗：', res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user, filterData]);

  //load user list
  useEffect(() => {
    axios
      .get('/api/setting/user/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          //增加"全部"選項
          res.data.result.users.unshift({ userId: '', userName: '全部' });

          setUserList(
            res.data.result.users.map((u) => ({
              label: u.userName,
              value: u.userId,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  //=======================================================
  const handleFilterDataChange = (name) => (val) => {
    setFilterData((prev) => ({ ...prev, [name]: val }));
  };
  const handleRowClick = (record) => {
    history.push(GenerateFormLink('charge', record.formChargeId));
  };
  const handleOnChangeInput = (event) => {
    event.persist();
    setFilterData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const optimisedHandleChange = UseDebounce(handleOnChangeInput, 1000);

  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>收款單查詢</Title>
        </Col>
        <Col>
          <Link to="/form/charge">
            <Button type="primary" size="large" icon={<PlusOutlined />}>
              新增收款單
            </Button>
          </Link>
        </Col>
      </Row>
      <br />
      <Row justify="space-between" align="middle">
        <Col>
          <DatePickerInline
            label="申請開始時間"
            value={filterData.begin}
            onChange={handleFilterDataChange('begin')}
            style={{ minWidth: '120px' }}
          />
        </Col>
        <Col>
          <DatePickerInline
            label="申請結束時間"
            value={filterData.end}
            onChange={handleFilterDataChange('end')}
            style={{ minWidth: '120px' }}
          />
        </Col>
        <Col>
          <SelectInline
            canSearch
            label="申請人"
            options={userList}
            value={filterData.applyId}
            onChange={handleFilterDataChange('applyId')}
            style={{ minWidth: '120px' }}
            allowClear={false}
          />
        </Col>
        <Col>
          <SelectInline
            canSearch
            label="簽核狀態"
            options={FormStatus}
            value={filterData.signStatus}
            onChange={handleFilterDataChange('signStatus')}
            style={{ minWidth: '120px' }}
            allowClear={false}
          />
        </Col>
        <Col>
          <InputInline
            label="單號"
            name="formNumber"
            defaultValue={filterData.formNumber}
            onChange={optimisedHandleChange}
          />
        </Col>
      </Row>
      <Divider />
      <Table
        columns={MainColumns}
        dataSource={chargeList}
        scroll={{ x: 1600 }}
        doubleClick={true}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              handleRowClick(record);
            },
          };
        }}
      />
    </div>
  );
};

export default FormChargeSearch;
