import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
//
import { useProvided } from '../modules/stores/index';
import useAuth from '../modules/stores/useAuth';
//
import AppInner from './AppInner';
import Login from './Login';
import Register from './Register';
import TokenTimeout from './AppInner/tokenTimeout';

const PrivateRoute = ({ user, ...props }) => {
  if (user) {
    return <Route {...props} />;
  } else {
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
  }
};

const PublicRoute = ({ user, ...props }) => {
  const location = useLocation();
  const history = useHistory();
  const { from } =
    location.state && location.state.from.pathname !== '/' ? location.state : { from: { pathname: '/home' } };
  if (user) {
    history.push('/home');
    return <Redirect push to={from} />;
  } else {
    return <Route {...props} />;
  }
};

const App = () => {
  const { user } = useProvided(useAuth);

  return (
    <div>
      <Page>
        <BrowserRouter>
          <Switch>
            <PublicRoute exact path="/login" user={user} component={Login} />
            <PublicRoute exact path="/register" user={user} component={Register} />
            <PrivateRoute user={user} component={AppInner} />
          </Switch>
        </BrowserRouter>
        <TokenTimeout />
      </Page>
    </div>
  );
};

const Page = styled.div`
  min-width: 1366px;
  width: 100vw;
  height: 100vh;
  background-color: #e5e5e5;
`;

export default App;
