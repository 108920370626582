import React, { useEffect, useState, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import File from '../../components/File';
import Input from '../../components/Input';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SubTitle from '../../components/SubTitle';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  SaveOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
//
import keys from '../../configs/keys';
import { DateFormat, DateTimeFormat, ListFormat, NumberFormat } from '../../modules/utils/format';
import { SetKeyIntoArray, GetQueryString } from '../../modules/utils/tool';
import { VacationSalary } from '../../modules/utils/list';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

const SettingPayroll = () => {
  const { user } = useProvided(useAuth);
  const { payrollId } = GetQueryString();
  const SECOND_NHI_RATE = keys.secondNHIRate;
  const TAX_PREPAID_RATE = keys.taxPrepaidRate;
  const CustomType = [
    { label: '應領', value: 'A' },
    { label: '應扣', value: 'M' },
  ];
  //
  const history = useHistory();
  const [redirectFlag, setRedirectFlag] = useState(false);
  const [mainData, setMainData] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [vacationList, setVacationList] = useState([]);
  const [clockList, setClockList] = useState([]);
  const [customList, setCustomList] = useState([]);
  const [customData, setCustomData] = useState({});

  //
  const payrollEdit = useMemo(() => {
    if (mainData.confirmDate) return false;
    else return true;
  }, [mainData.confirmDate]);

  const isEmployee = useMemo(() => {
    return mainData.type === '0';
  }, [mainData.type]);
  const isEmployer = useMemo(() => {
    return mainData.type === '1';
  }, [mainData.type]);
  const isConsultant = useMemo(() => {
    return mainData.type === '2';
  }, [mainData.type]);
  const isPartTime = useMemo(() => {
    return mainData.type === '3';
  }, [mainData.type]);

  //=======================================================
  //load data
  useEffect(() => {
    if (payrollId) {
      axios
        .get(`/api/payroll/form/${payrollId}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { m, i, v, c, ct } = res.data.result;
            setMainData(m);
            setInvoiceList(i.map(SetKeyIntoArray));
            setVacationList(v.map(SetKeyIntoArray));
            setCustomList(ct.map(SetKeyIntoArray));
            setClockList(c.map(SetKeyIntoArray));
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    } else {
      setMainData({
        comName: user.comName,
        deptName: user.deptName,
        userId: user.userId,
        userName: user.userName,
      });
    }
  }, [user, payrollId]);

  //=======================================================
  const handleSave = (cbFn) => () => {
    //
    const saveData = {
      payrollId: mainData.payrollId,
      payment: mainData.payment,
      deduction: mainData.deduction,
      netPay: mainData.netPay,
      memo: mainData.memo,
      invoices: invoiceList,
      vacations: vacationList,
      customs: customList,
    };
    axios
      .post('/api/payroll/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存成功');
          cbFn && cbFn();
        } else {
          Notify.warn('儲存失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = () => {
    if (mainData.payrollId) {
      axios
        .post(
          '/api/payroll/delete',
          { payrollId: mainData.payrollId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            Notify.success('刪除完成');
            setRedirectFlag(true);
          } else {
            Notify.warn('刪除失敗:' + res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };
  const handleMemoChange = (e) => {
    setMainData((prev) => ({
      ...prev,
      memo: e && e.target ? e.target.value : e,
    }));
  };

  //=======================================================
  const handleInvoiceListDelete = (formInvoiceId) => () => {
    const delItem = invoiceList.find((p) => p.formInvoiceId === formInvoiceId);
    if (delItem) {
      setInvoiceList((prev) => prev.filter((p) => p.formInvoiceId !== formInvoiceId));
      const delAmount = delItem.totalAmount;
      //重新計算實領金額、應領款項
      setMainData((prev) => ({
        ...prev,
        payment: parseInt(mainData.payment) - delAmount,
        netPay: parseInt(mainData.netPay) - delAmount,
      }));
    }
  };

  const handleVacationListDelete = (formVacationId, seq) => () => {
    const delItem = vacationList.find((p) => p.formVacationId === formVacationId && p.seq === seq);
    if (delItem) {
      setVacationList((prev) => prev.filter((p) => !(p.formVacationId === formVacationId && p.seq === seq)));
      const delAmount = delItem.hours * delItem.salaryProduct * mainData.wage;
      //重新計算實領金額、應扣款項
      setMainData((prev) => ({
        ...prev,
        deduction: parseInt(mainData.deduction) - delAmount,
        netPay: parseInt(mainData.netPay) + delAmount,
      }));
    }
  };

  //=======================================================
  const handleDownloadPdf = () => {
    const pdfData = {
      userId: mainData.userId,
      payYearMon: mainData.payYearMon,
    };

    axios
      .post('/api/pdf/payroll/create', pdfData, {
        headers: { Authorization: user.token, Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
      .then((res) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${mainData.payYearMon}_${mainData.userName}.pdf`;
          link.click();
        } else {
          Notify.warn('下載PDF失敗');
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  const handleUpdateConfirmDate = () => {
    axios
      .post(
        '/api/payroll/updateConfirmDate',
        {
          payrollId: mainData.payrollId,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('薪資明細確認完成');
          setRedirectFlag(true);
        } else {
          Notify.warn('更新確認日期失敗: ' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  const handleCustomDataChange = (name) => (e) => {
    setCustomData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleCustomListAdd = () => {
    //確認欄位是否都有填寫
    const validResult = ValidFormData(customData, [
      { name: 'type', type: 'select', required: true },
      { name: 'desc', type: 'input', required: true },
      { name: 'amount', type: 'number', required: true },
    ]);
    if (!validResult.status) {
      setCustomData(validResult.data);
      return;
    }
    //確認明細金額不能為0
    if (Number(customData.amount) === 0) {
      setCustomData((prev) => ({
        ...prev,
        amount: '',
        amount_msg: '金額不可為0',
      }));
      return;
    }
    if (customData.desc.length > 50) {
      setCustomData((prev) => ({
        ...prev,
        desc: '',
        desc_msg: '說明過長，請縮減內容',
      }));
      return;
    }

    //將金額回填實領金額、應領款項、應扣款項
    if (customData.type === 'A') {
      //重新計算實領金額、應領款項
      setMainData((prev) => ({
        ...prev,
        payment: parseInt(mainData.payment) + parseInt(customData.amount),
        netPay: parseInt(mainData.netPay) + parseInt(customData.amount),
      }));
    } else {
      //重新計算實領金額、應扣款項
      setMainData((prev) => ({
        ...prev,
        deduction: parseInt(mainData.deduction) + parseInt(customData.amount),
        netPay: parseInt(mainData.netPay) - parseInt(customData.amount),
      }));
    }

    //
    const tmpList = customList.filter((df) => df.seq !== customData.seq);
    tmpList.push({
      seq: customData.seq || tmpList.length + 1,
      type: customData.type,
      desc: customData.desc,
      amount: customData.amount,
    });
    tmpList.sort((a, b) => {
      if (a.seq < b.seq) {
        return -1;
      } else {
        return 1;
      }
    });
    setCustomList(tmpList.map(SetKeyIntoArray));
    setCustomData({});
  };
  const handleCustomListSelected = (record) => () => {
    if (record) {
      setCustomData(record);
    } else {
      setCustomData({});
    }
  };
  const handleCustomListDelete = (seq) => () => {
    //將金額回填實領金額、應領款項、應扣款項
    const delItem = customList.find((p) => p.seq === seq);
    if (delItem) {
      const amount = delItem.amount;
      if (delItem.type === 'A') {
        //重新計算實領金額、應領款項
        setMainData((prev) => ({
          ...prev,
          payment: parseInt(mainData.payment) - parseInt(amount),
          netPay: parseInt(mainData.netPay) - parseInt(amount),
        }));
      } else {
        //重新計算實領金額、應扣款項
        setMainData((prev) => ({
          ...prev,
          deduction: parseInt(mainData.deduction) - parseInt(amount),
          netPay: parseInt(mainData.netPay) + parseInt(amount),
        }));
      }
    }

    const tmpList = customList
      .filter((df) => df.seq !== seq)
      .map((dm, dmIdx) => {
        dm.seq = dmIdx + 1;
        return dm;
      });

    setCustomList(tmpList.map(SetKeyIntoArray));
    setCustomData({});
  };

  //=======================================================
  if (redirectFlag) {
    return <Redirect to="/setting/payroll/search" />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>薪資明細</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              <Button
                size="large"
                icon={<LeftOutlined />}
                className="j-btn-back"
                onClick={() => history.goBack()}
              >
                回上頁
              </Button>
            </Col>
            {payrollEdit && (
              <Col>
                <Button size="large" icon={<SaveOutlined />} onClick={handleSave()} className="j-btn-save">
                  儲存
                </Button>
              </Col>
            )}
            {payrollEdit && (
              <Col>
                <Popconfirm
                  title="是否確認刪除此表單？"
                  okText="確認"
                  cancelText="取消"
                  onConfirm={handleDelete}
                >
                  <Button size="large" icon={<DeleteOutlined />} className="j-btn-delete">
                    刪除
                  </Button>
                </Popconfirm>
              </Col>
            )}
            {payrollEdit && (
              <Col>
                <Popconfirm
                  title="薪資明細經確認後無法再更改，是否確認？"
                  okText="確認"
                  cancelText="取消"
                  onConfirm={handleSave(handleUpdateConfirmDate)}
                >
                  <Button size="large" icon={<SolutionOutlined />} className="j-btn-submit">
                    薪資明細確認
                  </Button>
                </Popconfirm>
              </Col>
            )}
            {!payrollEdit && (
              <Col>
                <Button size="large" type="primary" onClick={handleDownloadPdf}>
                  下載PDF
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Display label="部門" value={mainData.deptName} />
        </Col>
        <Col span={8}>
          <Display label="職稱" value={mainData.jobTitle} />
        </Col>
        <Col span={8}>
          <Display label="姓名" value={mainData.userName} />
        </Col>
        <Col span={8}>
          <Display label="薪資月份" value={mainData.payYearMon} />
        </Col>
        <Col span={16}>
          <Display label="到(離)職日期" value={DateFormat(mainData.workOn)} />
        </Col>
        <Col span={8}>
          <Display label="實領金額" value={NumberFormat(mainData.netPay, 'NT$')} />
        </Col>
        <Col span={16}>
          {payrollEdit ? (
            <Input label="備註" value={mainData.memo} onChange={handleMemoChange} />
          ) : (
            <Display label="備註" value={mainData.memo} />
          )}
        </Col>
      </Row>
      <Divider dashed />
      {(isEmployee || isEmployer) && (
        <>
          <Row justify="space-between" align="middle">
            <Col span={24}>
              <SubTitle>應領款項： {NumberFormat(mainData.payment, 'NT$')}</SubTitle>
            </Col>
            <Col span={24}>
              <Row>
                {(!mainData.salaryAdjust || mainData.salaryAdjust > mainData.salary) && (
                  <Col span={8}>
                    <Display label="薪資" value={NumberFormat(mainData.salary)} />
                  </Col>
                )}
                {mainData.salaryAdjust && mainData.salaryAdjust > mainData.salary && (
                  <Col span={8}>
                    <Display
                      label="薪資"
                      value={NumberFormat(mainData.salaryAdjust - mainData.salary)}
                      msg={`${mainData.workOn}到職，共上班${mainData.salaryDay}天`}
                    />
                  </Col>
                )}
                {mainData.salaryAdjust && mainData.salaryAdjust < mainData.salary && (
                  <Col span={8}>
                    <Display
                      label="薪資"
                      value={NumberFormat(mainData.salaryAdjust)}
                      msg={`${mainData.workOff}離職，共上班${mainData.salaryDay}天`}
                    />
                  </Col>
                )}
              </Row>
              <br />
              <Row>
                <Table
                  columns={GenerateInvoiceColumns(handleInvoiceListDelete, payrollEdit)}
                  dataSource={invoiceList}
                />
              </Row>
            </Col>
          </Row>
          <Divider dashed />
          <Row justify="space-between" align="middle">
            <Col span={24}>
              <SubTitle>應扣款項： {NumberFormat(mainData.deduction, 'NT$')}</SubTitle>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  {isEmployee && <Display label="勞保費" value={NumberFormat(mainData.employeeLabor)} />}
                  {isEmployer && <Display label="勞保費" value={NumberFormat(mainData.laborInsurance)} />}
                </Col>
                <Col span={6}>
                  <Display label="健保費" value={NumberFormat(mainData.employeeHealth)} />
                </Col>
                <Col span={6}>
                  <Display label="健保眷屬" value={NumberFormat(mainData.dependentsFee)} />
                </Col>
                {mainData.salaryWHTaxAmount && (
                  <Col span={6}>
                    <Display label="薪資所得扣繳稅額" value={NumberFormat(mainData.salaryWHTaxAmount)} />
                  </Col>
                )}
              </Row>
              <Row>
                <Table
                  columns={GenerateVacationColumns(handleVacationListDelete, mainData.wage, payrollEdit)}
                  dataSource={vacationList}
                />
              </Row>
            </Col>
          </Row>
        </>
      )}
      {isConsultant && (
        <Row>
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col span={24}>
              <SubTitle>應領款項： {NumberFormat(mainData.payment, 'NT$')}</SubTitle>
            </Col>
            <Col span={8}>
              <Display label="薪資" value={NumberFormat(mainData.salary)} />
            </Col>
          </Row>
          <Divider dashed />
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col span={24}>
              <SubTitle>應扣款項： {NumberFormat(mainData.deduction, 'NT$')}</SubTitle>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Display
                    label={`二代健保補充保費 (${NumberFormat(SECOND_NHI_RATE * 100)}%)`}
                    value={NumberFormat(mainData.healthInsurance)}
                  />
                </Col>
                <Col span={8}>
                  <Display
                    label={`國稅局預繳稅款 (${NumberFormat(TAX_PREPAID_RATE * 100)}%)`}
                    value={NumberFormat(mainData.taxPrepaid)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      )}
      {isPartTime && (
        <Row>
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col span={24}>
              <SubTitle>應領款項： {NumberFormat(mainData.payment, 'NT$')}</SubTitle>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Display label="薪資" value={NumberFormat(mainData.salary * mainData.workHours)} />
                </Col>
                <Col span={16}>
                  <Row align="end">
                    <Col span={4}>
                      <Display label="時薪" value={NumberFormat(mainData.salary)} />
                    </Col>
                    <Col span={4}>
                      <Display label="總上班時數" value={NumberFormat(mainData.workHours)} />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Table
                    columns={GenerateInvoiceColumns(handleInvoiceListDelete, payrollEdit)}
                    dataSource={invoiceList}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider dashed />
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col span={24}>
              <SubTitle>應扣款項： {NumberFormat(mainData.deduction, 'NT$')}</SubTitle>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Display label="勞保費" value={NumberFormat(mainData.employeeLabor)} />
                </Col>
                <Col span={8}>
                  <Display label="健保費" value={NumberFormat(mainData.employeeHealth)} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SubTitle>打卡紀錄：</SubTitle>
            </Col>
            <Col span={24}>
              <Table columns={ClockColumns} dataSource={clockList} />
            </Col>
          </Row>
        </Row>
      )}
      <>
        <Divider dashed />
        <SubTitle>自訂項目：</SubTitle>
        {payrollEdit && (
          <Row justify="space-between" align="middle">
            <Col span={4}>
              <Select
                required
                canSearch
                label="類型"
                options={CustomType}
                value={customData.type}
                msg={customData.type_msg}
                onChange={handleCustomDataChange('type')}
              />
            </Col>
            <Col span={12}>
              <Input
                required
                label="說明"
                value={customData.desc}
                msg={customData.desc_msg}
                onChange={handleCustomDataChange('desc')}
              />
            </Col>
            <Col span={4}>
              <Input
                required
                label="金額"
                value={customData.amount}
                msg={customData.amount_msg}
                onChange={handleCustomDataChange('amount')}
              />
            </Col>
            <Col span={4}>
              <Button block type="primary" size="large" onClick={handleCustomListAdd}>
                {customData.seq ? '儲存' : '新增'}
              </Button>
            </Col>
          </Row>
        )}
        <br />
        <Table
          columns={GenerateCustomColumns(
            handleCustomListSelected,
            handleCustomListDelete,
            payrollEdit,
            CustomType
          )}
          dataSource={customList}
        />
      </>
    </div>
  );
};

const GenerateInvoiceColumns = (delFn, canEdit) => {
  const col = [
    {
      title: '申請日期',
      dataIndex: 'applyDate',
      key: 'applyDate',
      width: 110,
      fixed: 'left',
      render: (value) => DateFormat(value),
    },
    {
      title: '內容',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 150,
      render: (value, record) => (
        <div>
          <div>
            <strong>金額總計</strong>
          </div>
          <div>{NumberFormat(record.totalAmount, 'NT$')}</div>
          {record.projectName ? (
            <div>
              <div>
                <strong>專案名稱</strong>
              </div>
              <div>{record.projectName}</div>
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: '請款明細',
      dataIndex: 'details',
      key: 'details',
      className: 'j-table-col',
      width: 700,
      render: (value) => {
        if (Array.isArray(value) && value.length > 0) {
          return (
            <Table
              className="j-inner-table"
              columns={InvoiceDetailColumns}
              dataSource={value.map(SetKeyIntoArray)}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      title: '請款單文件',
      dataIndex: 'file',
      key: 'file',
      width: 120,
      render: (value) => <File disabled={true} value={value} />,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => {
        return (
          <Row justify="center">
            <Col>
              <Popconfirm
                title="是否確認刪除？"
                placement="topRight"
                okText="確定"
                cancelText="取消"
                onConfirm={delFn(record.formInvoiceId)}
              >
                <Tooltip title="刪除">
                  <Button icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  if (canEdit) {
    delete col[col.length - 2];
  }

  if (!canEdit) {
    delete col[col.length - 1];
  }

  return col;
};

const InvoiceDetailColumns = [
  {
    title: '請款項目',
    dataIndex: 'invoiceItemName',
    key: 'invoiceItemName',
    width: 150,
  },
  {
    title: '單據日期',
    dataIndex: 'documentDate',
    key: 'documentDate',
    width: 110,
    render: (value) => DateFormat(value),
  },
  {
    title: '事由或說明',
    dataIndex: 'reason',
    key: 'reason',
  },
  {
    title: '收款對象',
    dataIndex: 'payee',
    key: 'payee',
    width: 110,
  },
];

const GenerateVacationColumns = (delFn, wage, canEdit) => {
  const col = [
    {
      title: '假別',
      dataIndex: 'vacationName',
      key: 'vacationName',
      width: 110,
    },
    {
      title: '開始時間',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 150,
      render: (value) => DateTimeFormat(value),
    },
    {
      title: '結束時間',
      dataIndex: 'endTime',
      key: 'endTime',
      width: 150,
      render: (value) => DateTimeFormat(value),
    },
    {
      title: '時數',
      dataIndex: 'hours',
      key: 'hours',
      width: 50,
      render: (value) => NumberFormat(value),
    },
    {
      title: '請假原因',
      dataIndex: 'reason',
      key: 'reason',
      width: 200,
    },
    {
      title: '計薪',
      dataIndex: 'salaryFlag',
      key: 'salaryFlag',
      width: 50,
      render: (value) => ListFormat(VacationSalary, value),
    },
    {
      title: '扣款',
      dataIndex: 'salaryProduct',
      key: 'salaryProduct',
      width: 80,
      render: (value, record) => {
        const amount = record.hours * record.salaryProduct * wage;
        return NumberFormat(amount);
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => {
        return (
          <Row justify="center">
            <Col>
              <Popconfirm
                title="是否確認刪除？"
                placement="topRight"
                okText="確定"
                cancelText="取消"
                onConfirm={delFn(record.formVacationId, record.seq)}
              >
                <Tooltip title="刪除">
                  <Button icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  if (!canEdit) {
    delete col[col.length - 1];
  }

  return col;
};

const GenerateCustomColumns = (editFn, delFn, canEdit, customType) => {
  const col = [
    {
      title: '類型',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (value) => ListFormat(customType, value),
    },
    {
      title: '說明',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: '金額',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      render: (value) => NumberFormat(value, 'NT$'),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={editFn(record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定刪除此自訂明細？"
              placement="topRight"
              okText="確認刪除"
              cancelText="取消"
              onConfirm={delFn(record.seq)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  if (!canEdit) {
    delete col[col.length - 1];
  }

  return col;
};

const ClockColumns = [
  {
    title: '打卡日期',
    dataIndex: 'clockDate',
    key: 'clockDate',
    width: 100,
    render: (value) => DateFormat(value),
  },
  {
    title: '上班時間',
    dataIndex: 'clockIn',
    key: 'clockIn',
    width: 150,
    render: (value, record) => DateTimeFormat(record.clockDate + ' ' + value),
  },
  {
    title: '下班時間',
    dataIndex: 'clockOut',
    key: 'clockOut',
    width: 150,
    render: (value, record) => DateTimeFormat(record.clockDate + ' ' + value),
  },
  {
    title: '時數',
    dataIndex: 'hours',
    key: 'hours',
    width: 100,
    render: (value) => NumberFormat(value),
  },
];

export default SettingPayroll;
