import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
//
import Col from '../../components/Col';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import { Button, Divider, Input, Select } from 'antd';
import { MailOutlined, LockOutlined, HomeOutlined } from '@ant-design/icons';
import LogoIcon from '../../images/logo.svg';
//
import keys from '../../configs/keys';
import { IsEmail } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const { Password } = Input;

//
const Login = () => {
  const { AuthUser } = useProvided(useAuth);
  const [data, setData] = useState({});
  const [captchaToken, setCaptchaToken] = useState('');
  const [companies, setCompanies] = useState([]);
  const inputRef = useRef(null);
  const [toRegister, setToRegister] = useState(false);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  //
  const handleDataChange = (name) => (e) => {
    setData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));

    if (name === 'email') {
      const email = e.target.value;
      if (IsEmail(email)) {
        //判斷是否有多重公司
        axios
          .post('/api/outer/getCompanies', { email: email })
          .then((res) => {
            if (res && res.data && res.data.status) {
              if (res.data.result.companies) {
                const c = res.data.result.companies;
                setCompanies(
                  c.map((u) => ({
                    label: u.comName,
                    value: u.comId,
                  }))
                );
                setData((prev) => ({
                  ...prev,
                  comId: c[0].comId,
                }));
              } else {
                setCompanies([]);
              }
            } else {
              Notify.error(res.data.msg);
              window.grecaptcha.reset();
            }
          })
          .catch((err) => {
            console.error(err);
            Notify.error('登入異常');
            window.grecaptcha.reset();
          });
      }
    }
  };
  //
  const handleSignIn = () => {
    //valid columns.
    let validResult = true;
    const tmp = { ...data };
    if (!tmp.email) {
      tmp.email = '';
      tmp.email_msg = '請輸入帳號';
      validResult = false;
    } else {
      if (!IsEmail(tmp.email)) {
        tmp.email = '';
        tmp.email_msg = '電子信箱格式不正確';
        validResult = false;
      }
    }
    if (!tmp.pwd) {
      tmp.pwd = '';
      tmp.pwd_msg = '請輸入密碼';
      validResult = false;
    }
    if (companies && companies.length && !tmp.comId) {
      tmp.comId = '';
      tmp.comId_msg = '請選擇公司';
      validResult = false;
    }
    if (!captchaToken) {
      Notify.error('請勾選「我不是機器人」');
      return;
    }

    if (!validResult) {
      setData(tmp);
      return;
    }

    //call api.
    axios
      .post('/api/outer/login', {
        email: data.email,
        pwd: data.pwd,
        comId: data.comId,
        reCaptchaToken: captchaToken,
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          AuthUser(res.data.result.user, res.data.result.token);
        } else {
          Notify.error(res.data.msg);
          window.grecaptcha.reset();
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error('登入異常');
        window.grecaptcha.reset();
      });
  };

  if (toRegister) {
    return <Redirect to="/register" />;
  }
  return (
    <Div>
      <LoginDiv>
        <Row justify="center" gutter={[0, 10]}>
          <Col>
            <Logo alt="sola" src={LogoIcon} />
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 0]}>
          <Col>
            <Title>索拉內部系統</Title>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 0]}>
          <Col>
            SOLA<HiddenBtn onDoubleClick={() => setToRegister(true)}>&nbsp;</HiddenBtn>MIS
          </Col>
        </Row>
        <Divider style={{ borderColor: '#f7b100' }} />
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Input
              size="large"
              placeholder="請輸入帳號 (Email)"
              value={data.email}
              onChange={handleDataChange('email')}
              addonBefore={<MailOutlined />}
              style={data.email_msg ? { border: '1px solid #f00' } : null}
              ref={inputRef}
            />
            <InputMsg>{data.email_msg || '　'}</InputMsg>
          </Col>
          <Col span={24}>
            <Password
              size="large"
              placeholder="請輸入密碼"
              value={data.pwd}
              onChange={handleDataChange('pwd')}
              onPressEnter={handleSignIn}
              addonBefore={<LockOutlined />}
              style={data.pwd_msg ? { border: '1px solid #f00' } : null}
            />
            <InputMsg>{data.pwd_msg || '　'}</InputMsg>
          </Col>
        </Row>
        {companies && companies.length > 0 && (
          <Row gutter={[0, 15]} justify="center">
            <Col span={24}>
              <Select
                suffixIcon={<HomeOutlined />}
                placeholder="請選擇公司"
                options={companies}
                value={data.comId}
                onChange={handleDataChange('comId')}
                style={{ width: '100%', fontSize: '16px' }}
              />
              <InputMsg>{data.comId_msg || '　'}</InputMsg>
            </Col>
          </Row>
        )}
        <br />
        <Row justify="center">
          <Col>
            <ReCAPTCHA
              sitekey={keys.reCaptchaSiteKey}
              onChange={(token) => setCaptchaToken(token)}
              onExpired={(e) => setCaptchaToken('')}
            />
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Button block type="primary" size="large" onClick={handleSignIn}>
              登入
            </Button>
          </Col>
        </Row>
        <br />
        <Row justify="end">
          <Col></Col>
        </Row>
      </LoginDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const LoginDiv = styled.div`
  width: 500px;
  margin-top: 50px;
  padding: 30px 30px 20px;
  background-color: #fff;
  border-radius: 2px;
`;
const Title = styled.div`
  font-size: 30px;
`;
const Logo = styled.img`
  height: 40px;
`;
const InputMsg = styled.div`
  width: 100%;
  font-size: 12px;
  color: #f00;
  text-align: right;
`;

const HiddenBtn = styled.button`
  background: transparent;
  border: none !important;
  padding: 0px;
`;

export default Login;
