import React from 'react';
import Col from '../Col';
import Row from '../Row';
import Table from '../Table';
import Subtitle from '../SubTitle';
import { DateTimeFormat, ListFormat } from '../../modules/utils/format';
import { FormStatus } from '../../modules/utils/list';

const tableColumns = [
  {
    title: '關卡',
    dataIndex: 'stage',
    key: 'stage',
    width: 200,
  },
  {
    title: '動作',
    dataIndex: 'action',
    key: 'action',
    width: 150,
  },
  {
    title: '人員',
    dataIndex: 'signUserName',
    key: 'signUserName',
    width: 150,
  },
  {
    title: '時間',
    dataIndex: 'crDate',
    key: 'crDate',
    width: 200,
    render: (value) => DateTimeFormat(value),
  },
  {
    title: '意見',
    dataIndex: 'comment',
    key: 'comment',
  },
];

const SignHistory = ({ data, signStatus, signerNames }) => {
  if (data && Array.isArray(data) && data.length > 0) {
    return (
      <div>
        <Row justify="space-between" align="middle">
          <Col>
            <Subtitle>簽核歷程</Subtitle>
          </Col>
          <Col>
            <Row>
              <Col>
                <span>表單狀態：</span>
                <strong>{ListFormat(FormStatus, signStatus) || '無'}</strong>
              </Col>
              <Col>
                <span>目前簽核者：</span>
                <strong>{Array.isArray(signerNames) ? signerNames.join(', ') : signerNames || '無'}</strong>
              </Col>
            </Row>
          </Col>
        </Row>

        <Table columns={tableColumns} dataSource={data} pagination={false} />
      </div>
    );
  } else {
    return null;
  }
};

export default SignHistory;
