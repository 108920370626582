import React from 'react';
import ReactDOM from 'react-dom';
//
import { Provider } from './modules/stores';
import useAuth from './modules/stores/useAuth';
//
import App from './pages/App';
//import reportWebVitals from './reportWebVitals';
//
import 'antd/dist/antd.css';
import './css/app.css';
import './css/table.css';
import './css/button.css';
import './css/upload.css';
import './css/message.css';
import './css/fullcalendar.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider inject={[useAuth]}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
