import React, { useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import CheckboxGroup from '../../components/CheckboxGroup';
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import File from '../../components/File';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SignControlBar from '../../components/Sign/controlBar';
import SignHistory from '../../components/Sign/history';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import { DateFormat, ListFormat } from '../../modules/utils/format';
import { SealKind, SealType } from '../../modules/utils/list';
import { SetKeyIntoArray, GetFormFlowString, GetQueryString, GenerateFormMode } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

//
const HOME_PAGE = '/home';
const SEARCH_PAGE = '/form/seal/search';
const GenerateColumns = (editFn, delFn, canEdit) => {
  const col = [
    {
      title: '用途',
      dataIndex: 'sealType',
      key: 'sealType',
      width: 150,
      render: (value) => ListFormat(SealType, value),
    },
    {
      title: '簽約對象',
      dataIndex: 'party',
      key: 'party',
    },
    {
      title: '文件名稱',
      dataIndex: 'doc',
      key: 'doc',
    },
    {
      title: '一式幾份',
      dataIndex: 'copies',
      key: 'copies',
    },
    {
      title: '附檔',
      dataIndex: 'file',
      key: 'file',
      render: (value) => <File disabled={true} value={value} />,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={editFn(record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定刪除此用印明細？"
              placement="topRight"
              okText="確認刪除"
              cancelText="取消"
              onConfirm={delFn(record.seq)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  if (!canEdit) {
    delete col[col.length - 1];
  }

  return col;
};

const FormSeal = () => {
  const { user } = useProvided(useAuth);
  const { formId } = GetQueryString();
  const [redirectFlag, setRedirectFlag] = useState('');
  const [mainData, setMainData] = useState({});
  const [detailData, setDetailData] = useState({});
  const [detailList, setDetailList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [formFlow, setFormFlow] = useState([]);
  //
  const [modalOpen, setModalOpen] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [sealDate, setSealDate] = useState({ value: moment(), value_msg: '' });
  const [sealFiles, setSealFiles] = useState({ value: [], value_msg: '' });
  //
  const formMode = useMemo(() => {
    return GenerateFormMode(
      mainData.formSealId,
      mainData.userId,
      mainData.signStatus,
      mainData.signUser,
      user.userId
    );
  }, [user, mainData]);
  //
  const showUpdateSealDateBtn = useMemo(() => {
    if (formMode.isCurrentSigner && mainData.canUpdateSealDate) {
      return true;
    }
    return false;
  }, [formMode.isCurrentSigner, mainData.canUpdateSealDate]);

  const showUpdateSealFileBtn = useMemo(() => {
    if (formMode.isCurrentSigner && mainData.canUpdateSealFile) {
      return true;
    }
    return false;
  }, [formMode.isCurrentSigner, mainData.canUpdateSealFile]);

  //=======================================================
  //load vacation from data.
  useEffect(() => {
    if (formId) {
      axios
        .post(`/api/setting/verifyForm`,
          { formCode: 'seal', formId: formId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            if (res.data.result === false) {
              Notify.warn(res.data.msg);
              setRedirectFlag('home');
            }
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });

      axios
        .get(`/api/seal/form/${formId}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { m, d, h, f } = res.data.result;
            setMainData(m);
            setDetailList(d.map(SetKeyIntoArray));
            setHistoryList(h.map(SetKeyIntoArray));
            setFormFlow(f);
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    } else {
      setMainData({
        comName: user.comName,
        deptName: user.deptName,
        userId: user.userId,
        userName: user.userName,
      });

      axios
        .get(`/api/sign/formFlow/seal`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const r = res.data.result;
            setFormFlow(r.flow);
          } else {
            setFormFlow([]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user, formId]);

  //=======================================================
  const handleMainDataChange = (name) => (e) => {
    setMainData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleDetailDataChange = (name) => (e) => {
    setDetailData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleDetailListSelected = (record) => () => {
    if (record) {
      setDetailData(record);
    } else {
      setDetailData({});
    }
  };
  const handleDetailListAdd = () => {
    //確認欄位是否都有填寫
    const validResult = ValidFormData(detailData, [
      { name: 'sealType', type: 'select', required: true },
      { name: 'party', type: 'input', required: true },
      { name: 'doc', type: 'input', required: true },
      { name: 'copies', type: 'number', required: true },
      { name: 'file', type: 'select', required: true },
    ]);
    if (!validResult.status) {
      setDetailData(validResult.data);
      return;
    }

    //
    const tmpList = detailList.filter((df) => df.seq !== detailData.seq);
    tmpList.push({
      seq: detailData.seq || tmpList.length + 1,
      sealType: detailData.sealType,
      party: detailData.party,
      doc: detailData.doc,
      copies: detailData.copies,
      file: detailData.file,
    });
    tmpList.sort((a, b) => {
      if (a.seq < b.seq) {
        return -1;
      } else {
        return 1;
      }
    });
    setDetailList(tmpList.map(SetKeyIntoArray));

    //將對象、件數回填
    setMainData((prev) => ({
      ...prev,
      contractTarget: Array.from(new Set(tmpList.map(obj => obj.party))),
      amount: tmpList.reduce((a, v) => a + parseInt(v.copies), 0),
    }));
    setDetailData({});
  };
  const handleDetailListDelete = (seq) => () => {
    const tmpList = detailList.filter((df) => df.seq !== seq)
      .map((dm, dmIdx) => { dm.seq = dmIdx + 1; return dm; });

    setDetailList(tmpList.map(SetKeyIntoArray));

    //將對象、件數回填
    setMainData((prev) => ({
      ...prev,
      contractTarget: Array.from(new Set(tmpList.map(obj => obj.party))).join('\n'),
      amount: tmpList.reduce((a, v) => a + parseInt(v.copies), 0),
    }));
    setDetailData({});
  };

  //=======================================================
  const handleSave = (cbFn) => () => {
    //
    const validResult = ValidFormData(mainData, [
      // { name: 'contractTarget', type: 'input', required: true },
      { name: 'sealKind', type: 'checkboxgroup', required: true },
      // { name: 'amount', type: 'number', required: true },
    ]);
    if (!validResult.status) {
      setMainData(validResult.data);
      return;
    }
    //
    if (!Array.isArray(detailList) || detailList.length < 1) {
      Notify.warn('請填寫用印明細');
      return;
    }

    //
    const saveData = {
      formSealId: mainData.formSealId,
      contractTarget: mainData.contractTarget,
      sealKind: mainData.sealKind,
      amount: mainData.amount,
      projectName: mainData.projectName,
      details: detailList,
    };
    axios
      .post('/api/seal/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result.formSealId) {
            setMainData((prev) => ({
              ...prev,
              formSealId: res.data.result.formSealId,
            }));
          }
          Notify.success('儲存成功');
          cbFn && cbFn();
        } else {
          Notify.warn('儲存失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = () => {
    if (mainData.formSealId) {
      axios
        .post(
          '/api/seal/delete',
          { formSealId: mainData.formSealId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            Notify.success('刪除完成');
            setRedirectFlag('search');
          } else {
            Notify.warn('刪除失敗:' + res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //=======================================================
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setSealDate({ value: moment(), value_msg: '' });
  };
  const handleSealDateChange = (val) => {
    setSealDate({ value: val, value_msg: '' });
  };
  const handleUpdateSealDate = () => {
    if (!sealDate.value) {
      setSealDate({ value: moment(), value_msg: '請輸入用印日期' });
      return;
    }

    axios
      .post(
        '/api/seal/updateSealDate',
        {
          formSealId: mainData.formSealId,
          date: sealDate.value.format('YYYY-MM-DD'),
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('更新用印日期完成');
          setMainData((prev) => ({
            ...prev,
            useSealDate: sealDate.value.format('YYYY-MM-DD'),
          }));
          handleModalClose();
        } else {
          Notify.warn('更新用印日期失敗: ' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  const handleFileModalOpen = () => {
    setFileModalOpen(true);
  };
  const handleFileModalClose = () => {
    setFileModalOpen(false);
    setSealFiles({ value: [], value_msg: '' });
  };
  const handleSealFilesChange = (val) => {
    setSealFiles({ value: val, value_msg: '' });
  };
  const handleUpdateSealFile = () => {
    if (!Array.isArray(sealFiles.value) || sealFiles.value.length < 1) {
      setSealFiles({ value: [], value_msg: '請上傳已用印檔案' });
      return;
    }
    if (sealFiles.value.length !== detailList.length) {
      setSealFiles((prev) => ({ ...prev, value_msg: '上傳的檔案數量與申請之數量不符' }));
      return;
    }

    axios
      .post(
        '/api/seal/updateSealFile',
        {
          formSealId: mainData.formSealId,
          files: sealFiles.value,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          setMainData((prev) => ({
            ...prev,
            files: sealFiles.value,
          }));
          Notify.success('上傳已用印檔案完成');
          handleFileModalClose();
        } else {
          Notify.warn('上傳已用印檔案失敗: ' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  const handleSubmit = (comment) => {
    axios
      .post(
        '/api/sign/submit',
        { formCode: 'seal', formId: mainData.formSealId, comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('呈送完成');
          setRedirectFlag('search');
        } else {
          Notify.warn('呈送失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleApprove = (comment) => {
    axios
      .post(
        '/api/sign/approve',
        { formId: mainData.formSealId, formCode: 'seal', comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('審核完成');
          setRedirectFlag('home');
        } else {
          Notify.warn('審核失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleReturn = (comment) => {
    axios
      .post(
        '/api/sign/return',
        { formId: mainData.formSealId, formCode: 'seal', comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('退回完成');
          setRedirectFlag('home');
        } else {
          Notify.warn('退回失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleVoid = (comment) => {
    axios
      .post(
        '/api/sign/void',
        { formId: mainData.formSealId, comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('作廢完成');
          setRedirectFlag('search');
        } else {
          Notify.warn('作廢失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================


  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  if (redirectFlag === 'search') {
    return <Redirect to={SEARCH_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>用印申請單</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              {showUpdateSealDateBtn && (
                <Button size="large" onClick={handleModalOpen}>
                  更新用印日期
                </Button>
              )}
              {showUpdateSealFileBtn && (
                <Button size="large" onClick={handleFileModalOpen}>
                  上傳已用印文件
                </Button>
              )}
            </Col>
            <Col>
              <SignControlBar
                backUrl={SEARCH_PAGE}
                btnCtrl={formMode.button}
                saveFn={handleSave}
                deleteFn={handleDelete}
                submitFn={handleSubmit}
                approveFn={handleApprove}
                returnFn={handleReturn}
                voidFn={handleVoid}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={6}>
          <Display label="表單單號" value={mainData.formNumber || '自動產生'} />
        </Col>
        <Col span={18}>
          {formFlow && Array.isArray(formFlow) && formFlow.length > 0 ?
            <Display label="表單流程" value={GetFormFlowString(formFlow)} />
            :
            <Display label="表單流程" value="申請人 ➡️ 部門主管 ➡️ 總經理 ➡️ 董事長 ➡️ 承辦 ➡️ 申請人" />
          }
        </Col>
        <Col span={6}>
          <Display label="申請日期" value={DateFormat(mainData.crDate || moment())} />
        </Col>
        <Col span={6}>
          <Display label="用印日期" value={DateFormat(mainData.useSealDate)} />
        </Col>
        <Col span={6}>
          <Display label="部門" value={mainData.deptName} />
        </Col>
        <Col span={6}>
          <Display label="申請人員" value={mainData.userName} />
        </Col>
        <Col span={6}>
          <Display label="簽約(提供)對象" value={mainData.contractTarget} />
        </Col>
        <Col span={16}>
          {formMode.canEdit ? (
            <CheckboxGroup
              required
              label="印鑑種類"
              options={SealKind}
              value={mainData.sealKind}
              msg={mainData.sealKind_msg}
              onChange={handleMainDataChange('sealKind')}
            />
          ) : (
            <Display label="印鑑種類" value={ListFormat(SealKind, mainData.sealKind)} />
          )}
        </Col>
        <Col span={2}>
          <Display label="合約數量" value={mainData.amount} />
        </Col>
        {(mainData.files || []).length > 0 && (
          <Col span={8}>
            <File label="已用印檔案" disabled={true} value={mainData.files} />
          </Col>
        )}
      </Row>
      <Divider dashed />
      {formMode.canEdit && (
        <Row justify="space-between" align="middle">
          <Col span={8}>
            <Select
              required
              label="用途"
              options={SealType}
              value={detailData.sealType}
              msg={detailData.sealType_msg}
              onChange={handleDetailDataChange('sealType')}
            />
          </Col>
          <Col span={8}>
            <Input
              required
              label="簽約對象"
              value={detailData.party}
              msg={detailData.party_msg}
              onChange={handleDetailDataChange('party')}
            />
          </Col>
          <Col span={8}>
            <File
              required
              label="附件"
              maxCount={1}
              value={detailData.file}
              msg={detailData.file_msg}
              onChange={handleDetailDataChange('file')}
            />
          </Col>
          <Col span={8}>
            <Input
              required
              label="一式幾份"
              value={detailData.copies}
              msg={detailData.copies_msg}
              onChange={handleDetailDataChange('copies')}
            />
          </Col>
          <Col span={16}>
            <Input
              required
              label="文件名稱"
              value={detailData.doc}
              msg={detailData.doc_msg}
              onChange={handleDetailDataChange('doc')}
            />
          </Col>
          <Col span={4}>
            <Button block type="primary" size="large" onClick={handleDetailListAdd}>
              {detailData.seq ? '儲存' : '新增'}
            </Button>
          </Col>
        </Row>
      )}
      <br />
      <Table
        columns={GenerateColumns(handleDetailListSelected, handleDetailListDelete, formMode.canEdit)}
        dataSource={detailList}
      />
      <Divider dashed />
      <SignHistory data={historyList} signStatus={mainData.signStatus} signerNames={mainData.signerNames} />
      <Modal width="600px" visible={modalOpen}>
        <Title>更新用印日期</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <DatePicker
              required
              label="用印日期"
              value={sealDate.value}
              msg={sealDate.value_msg}
              onChange={handleSealDateChange}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleUpdateSealDate}>
              更新
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="600px" visible={fileModalOpen}>
        <Title>上傳已用印文件</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <File
              required
              label="已用印文件"
              value={sealFiles.value}
              msg={sealFiles.value_msg}
              onChange={handleSealFilesChange}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleUpdateSealFile}>
              上傳
            </Button>
          </Col>
          <Col>
            <Button onClick={handleFileModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default FormSeal;
