import React from 'react';
import styled from 'styled-components';
import { Table } from 'antd';

const JTable = (props) => {
  let className = 'j-table';
  if (props.dense) {
    className = 'j-table j-table-expend';
  }
  else if (props.doubleClick) {
    className = 'j-table j-table-doubleclick';
  }

  return (
    <TableContainer>
      <Table className={className} bordered tableLayout="fixed" pagination={false} {...props} />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 100%;
`;

export default JTable;
