import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button, Tooltip, Popconfirm, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons';
//
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const GenerateColumns = (openFn, delFn) => {
  return [
    {
      title: '角色名稱',
      dataIndex: 'roleName',
      key: 'roleName',
      width: 250,
    },
    {
      title: '備註',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button onClick={openFn('edit', record)} icon={<EditOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定刪除此角色？ (會連帶刪除相關設定)"
              placement="topRight"
              okText="確定"
              cancelText="關閉"
              onConfirm={delFn(record.roleId)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

const SettingRole = () => {
  const { user } = useProvided(useAuth);
  const [redirectFlag, setRedirectFlag] = useState('');
  const [keyword, setKeyword] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [roleData, setRoleData] = useState({});
  const [roleMenuData, setRoleMenuData] = useState([]);
  const [roleFeatureData, setRoleFeatureData] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [featureList, setFeatureList] = useState([]);

  //
  const viewList = useMemo(() => {
    if (keyword) {
      return roleList.filter((m) => m.roleName.indexOf(keyword) > -1 || m.roleCode.indexOf(keyword) > -1);
    } else {
      return roleList;
    }
  }, [roleList, keyword]);

  //========================================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrRole' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false)
            setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  const loadRoles = useCallback(() => {
    axios
      .get('/api/setting/role/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setRoleList(res.data.result.roles.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);
  const loadMenus = useCallback(() => {
    axios
      .get('/api/setting/menu/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setMenuList(res.data.result.menus.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);
  const loadFeatures = useCallback(() => {
    axios
      .get('/api/setting/feature/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setFeatureList(res.data.result.features.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);
  useEffect(() => {
    loadRoles();
    loadMenus();
    loadFeatures();
  }, [loadRoles, loadMenus, loadFeatures]);

  const loadRoleMenus = (roleCode) => {
    if (roleCode) {
      axios
        .get(`/api/setting/menu/byRole?code=${roleCode}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            setRoleMenuData(res.data.result.menus);
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });
    }
  };
  const loadRoleFeatures = (roleCode) => {
    if (roleCode) {
      axios
        .get(`/api/setting/feature/byRole?code=${roleCode}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            setRoleFeatureData(res.data.result.features);
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });
    }
  };

  //========================================================================
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };
  const handleRoleDataChange = (name) => (e) => {
    setRoleData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleModalOpen = (type, record) => () => {
    if (record && record.roleId) {
      loadRoleMenus(record.roleId);
      loadRoleFeatures(record.roleId);
    }
    setRoleData(record || {});
    setModalProps({ open: true, type: type });
  };
  const handleModalClose = () => {
    setRoleData({});
    setRoleMenuData([]);
    setRoleFeatureData([]);
    setModalProps({ open: false, type: '' });
  };
  const handleMenuSelected = (checkedValues) => {
    setRoleMenuData(checkedValues);
  };
  const handleMenuAllSelect = () => {
    setRoleMenuData(menuList.map((m) => m.menuId));
  };
  const handleMenuAllUnselect = () => {
    setRoleMenuData([]);
  };
  const handleFeatureSelected = (checkedValues) => {
    setRoleFeatureData(checkedValues);
  };

  const handleSave = () => {
    //valid
    const validResult = ValidFormData(roleData, [{ name: 'roleName', type: 'input', required: true }]);
    if (!validResult.status) {
      setRoleData(validResult.data);
      return;
    }

    //
    const postData = {
      roleId: roleData.roleId,
      roleName: roleData.roleName,
      roleMenus: roleMenuData || [],
      roleFeatures: roleFeatureData || [],
      memo: roleData.memo,
    };
    //
    axios
      .post('/api/setting/role/save', postData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          loadRoles();
          handleModalClose();
          Notify.success('儲存成功');
        } else {
          Notify.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };
  const handleDelete = (roleId) => () => {
    if (roleId) {
      axios
        .post('/api/setting/role/delete', { roleId }, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            loadRoles();
            Notify.success('刪除成功');
          } else {
            Notify.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });
    }
  };

  //========================================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>角色管理</Title>
            </Col>
            <Col>
              <InputInline
                label="關鍵字"
                placeholder="請輸入角色代碼或名稱"
                value={keyword}
                onChange={handleKeywordChange}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen('add', null)} icon={<PlusOutlined />}>
            新增角色
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />

      <Modal visible={modalProps.open} width="800px">
        <Title>{modalProps.type === 'add' ? '新增' : '編輯'}角色</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <Display label="角色識別碼" value={roleData.roleId} />
          </Col>
          <Col span={12}>
            <Input
              label="角色名稱"
              value={roleData.roleName}
              msg={roleData.roleName_msg}
              onChange={handleRoleDataChange('roleName')}
            />
          </Col>
          <Col span={24}>
            <Textarea label="備註" value={roleData.memo} onChange={handleRoleDataChange('memo')} />
          </Col>
        </Row>
        <Divider dashed />
        <Row>
          <Col>
            <strong style={{ fontSize: '16px' }}>對應選單</strong>
          </Col>
          <Col>
            <Button size="small" onClick={handleMenuAllSelect}>
              全選
            </Button>
          </Col>
          <Col>
            <Button type="dashed" size="small" onClick={handleMenuAllUnselect}>
              全不選
            </Button>
          </Col>
        </Row>
        <br />
        <Checkbox.Group value={roleMenuData} onChange={handleMenuSelected} style={{ width: '100%' }}>
          <Row gutter={[8, 8]}>
            {menuList.map((m, mIdx) => (
              <Col key={`menuItem-${mIdx}`} span={6}>
                <Checkbox value={m.menuId}>{m.menuName}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        <Divider dashed />
        <div>
          <strong style={{ fontSize: '16px' }}>對應功能</strong>
        </div>
        <br />
        <Checkbox.Group value={roleFeatureData} onChange={handleFeatureSelected} style={{ width: '100%' }}>
          <Row gutter={[8, 8]}>
            {featureList.map((f, fIdx) => (
              <Col key={`featureItem-${fIdx}`} span={6}>
                <Tooltip title={f.memo}>
                  <Checkbox value={f.featureId}>{f.featureName}</Checkbox>
                </Tooltip>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SettingRole;
