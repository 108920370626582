import React, { useEffect, useState } from 'react';
import { useHistory, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import DatePickerInline from '../../components/DatePicker/inline';
import Divider from '../../components/Divider';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Title from '../../components/Title';
import InputInline from '../../components/Input/inline';
import { Button } from 'antd';
import { SelectOutlined, PlusOutlined } from '@ant-design/icons';
//
import { DateFormat, ListFormat, NumberFormat } from '../../modules/utils/format';
import { FormStatus } from '../../modules/utils/list';
import { SetKeyIntoArray, GenerateFormLink, UseDebounce } from '../../modules/utils/tool';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const DetailColumns = [
  {
    title: '請款項目',
    dataIndex: 'invoiceItemName',
    key: 'invoiceItemName',
    width: 110,
  },
  {
    title: '單據日期',
    dataIndex: 'documentDate',
    key: 'documentDate',
    width: 110,
    render: (value) => DateFormat(value),
  },
  {
    title: '事由或說明',
    dataIndex: 'reason',
    key: 'reason',
  },
];

const MainColumns = [
  {
    title: '單號',
    dataIndex: 'formNumber',
    key: 'formNumber',
    width: 150,
    fixed: 'left',
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '申請日期',
    dataIndex: 'applyDate',
    key: 'applyDate',
    width: 110,
    fixed: 'left',
    render: (value) => DateFormat(value),
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '申請人',
    dataIndex: 'userName',
    key: 'userName',
    width: 110,
    fixed: 'left',
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
  },
  {
    title: '內容',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    width: 150,
    render: (value, record) => (
      <div>
        <div>
          <strong>金額總計</strong>
        </div>
        <div>{NumberFormat(record.totalAmount, 'NT$')}</div>
        {record.projectName ?
          <div>
            <div>
              <strong>專案名稱</strong>
            </div>
            <div>{record.projectName}</div>
          </div> : null}
      </div>
    ),
  },
  {
    title: '請款明細',
    dataIndex: 'details',
    key: 'details',
    className: 'j-table-col',
    width: 700,
    render: (value) => {
      if (Array.isArray(value) && value.length > 0) {
        return (
          <Table className="j-inner-table" columns={DetailColumns} dataSource={value.map(SetKeyIntoArray)} />
        );
      } else {
        return null;
      }
    },
  },
  {
    title: '簽核狀態',
    dataIndex: 'signStatus',
    key: 'signStatus',
    width: 110,
    fixed: 'right',
    render: (value) => ListFormat(FormStatus, value),
  },
  {
    title: '目前簽核者',
    dataIndex: 'signerNames',
    key: 'signerNames',
    width: 110,
    fixed: 'right',
    render: (value) => {
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((v, vIdx) => (
              <div key={vIdx}>{v}</div>
            ))}
          </div>
        );
      } else {
        return value;
      }
    },
  },
  {
    title: '連結',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 80,
    fixed: 'right',
    render: (value, record) => {
      return (
        <Link to={GenerateFormLink('invoice', record.formInvoiceId)}>
          <Button icon={<SelectOutlined />} />
        </Link>
      );
    },
  },
];

const FormInvoiceSearch = () => {
  const { user } = useProvided(useAuth);
  const [redirectFlag, setRedirectFlag] = useState('');
  const [userList, setUserList] = useState([]);
  const formInvoiceCond = window.sessionStorage.getItem('formInvoiceCond_' + user.userId);
  const storeCond = formInvoiceCond ? JSON.parse(formInvoiceCond) : null;
  const [filterData, setFilterData] = useState({
    begin: (storeCond) ? moment(storeCond.begin, 'YYYY-MM-DD') : moment().startOf('month'),
    end: (storeCond) ? moment(storeCond.end, 'YYYY-MM-DD') : moment().endOf('month'),
    applyId: (storeCond) ? storeCond.applyId : user.userId,
    signStatus: (storeCond) ? storeCond.signStatus : '',
    formNumber: (storeCond) ? storeCond.formNumber : '',
  });
  const [invoiceList, setInvoiceList] = useState([]);
  const [exportShow, setExportShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [exportData, setExportData] = useState({
    begin: moment().add(-1, 'month'), end: moment(), type: ''
  });
  let history = useHistory();

  //=======================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`,
        { menuId: 'efInvoice' },
        { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false)
            setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });

    axios
      .post(`/api/setting/verifyFeature`,
        { featureId: 'isInvoiceWindow' },
        { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setExportShow(res.data.result);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });

  }, [user]);

  useEffect(() => {
    let conditions = {
      begin: DateFormat(filterData.begin),
      end: DateFormat(filterData.end),
      applyId: filterData.applyId,
      signStatus: filterData.signStatus,
      formNumber: filterData.formNumber
    };

    axios
      .post(
        '/api/invoice/search', conditions,
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          setInvoiceList(res.data.result.invoices.map(SetKeyIntoArray));
          window.sessionStorage.setItem('formInvoiceCond_' + user.userId, JSON.stringify(conditions));
        } else {
          Notify.warn('查詢失敗：', res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user, filterData]);

  //load user list
  useEffect(() => {
    axios
      .get('/api/setting/user/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          //增加"全部"選項
          res.data.result.users.unshift({ userId: '', userName: '全部' });

          setUserList(
            res.data.result.users.map((u) => ({
              label: u.userName,
              value: u.userId,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  //=======================================================
  const handleFilterDataChange = (name) => (val) => {
    setFilterData((prev) => ({ ...prev, [name]: val }));
  };
  const handleRowClick = (record) => {
    history.push(GenerateFormLink('invoice', record.formInvoiceId));
  };
  const handleOnChangeInput = (event) => {
    event.persist();
    setFilterData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const optimisedHandleChange = UseDebounce(handleOnChangeInput, 1000);

  //=======================================================
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleExportDataChange = (name) => (val) => {
    setExportData((prev) => ({ ...prev, [name]: val }));
  };
  const handleExportClick = () => {
    const title = `${user.comName}_${exportData.begin.format('YYYYMMDD')}_${exportData.end.format('YYYYMMDD')}請款資料`;
    axios
      .post('/api/invoice/export',
        {
          title: title,
          begin: DateFormat(exportData.begin),
          end: DateFormat(exportData.end),
          type: exportData.type
        },
        { headers: { Authorization: user.token }, responseType: 'blob', }
      )
      .then((res) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${title}.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          Notify.warn('下載請款資料失敗');
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>請款單查詢</Title>
        </Col>
        <Col>
          <Row>
            {exportShow &&
              <Col>
                <Button size="large" onClick={handleModalOpen}>
                  請款資料匯出
              </Button>
              </Col>
            }
            <Col>
              <Link to="/form/invoice">
                <Button type="primary" size="large" icon={<PlusOutlined />}>
                  新增請款單
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Row justify="space-between" align="middle">
        <Col>
          <DatePickerInline
            label="申請開始時間"
            value={filterData.begin}
            onChange={handleFilterDataChange('begin')}
            style={{ minWidth: '120px' }}
            allowClear={false}
          />
        </Col>
        <Col>
          <DatePickerInline
            label="申請結束時間"
            value={filterData.end}
            onChange={handleFilterDataChange('end')}
            style={{ minWidth: '120px' }}
            allowClear={false}
          />
        </Col>
        <Col>
          <SelectInline
            canSearch
            label="申請人"
            options={userList}
            value={filterData.applyId}
            onChange={handleFilterDataChange('applyId')}
            style={{ minWidth: '120px' }}
          />
        </Col>
        <Col>
          <SelectInline
            canSearch
            label="簽核狀態"
            options={FormStatus}
            value={filterData.signStatus}
            onChange={handleFilterDataChange('signStatus')}
            style={{ minWidth: '120px' }}
          />
        </Col>
        <Col>
          <InputInline
            label="單號"
            name="formNumber"
            defaultValue={filterData.formNumber}
            onChange={optimisedHandleChange}
          />
        </Col>
      </Row>
      <Divider />
      <Table columns={MainColumns} dataSource={invoiceList} scroll={{ x: 1600 }} doubleClick={true}
        onRow={(record) => {
          return { onDoubleClick: () => { handleRowClick(record) } }
        }}
      />
      <Modal width="800px" visible={modalOpen}>
        <Title>請款資料匯出</Title>
        <Divider />
        <Row>
          <Col span={8}>
            <Select
              required
              label="匯出種類"
              options={[
                { label: '不限', value: '' },
                { label: '未匯款', value: '1' },
                { label: '已匯款', value: '2' },
              ]}
              value={exportData.type}
              onChange={handleExportDataChange('type')}
            />
          </Col>
          <Col span={8}>
            <DatePicker
              required
              label="申請開始時間"
              value={exportData.begin}
              onChange={handleExportDataChange('begin')}
              allowClear={false}
            />
          </Col>
          <Col span={8}>
            <DatePicker
              required
              label="申請結束時間"
              value={exportData.end}
              onChange={handleExportDataChange('end')}
              allowClear={false}
            />
          </Col>
        </Row>
        <Row gutter={[20, 10]}>
          <Col span={24}>
            <strong style={{ color: '#D73141' }}>
              ※ 僅匯出已簽核完成的請款資料
            </strong>
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleExportClick}>
              匯出
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default FormInvoiceSearch;