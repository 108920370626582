import React, { useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import File from '../../components/File';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SignControlBar from '../../components/Sign/controlBar';
import SignHistory from '../../components/Sign/history';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import TimePicker from '../../components/TimePicker';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip, Switch } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import keys from '../../configs/keys';
import {
  DateFormat,
  DateTimeFormat,
  DateTimeWithZoneFormat,
  ListFormat,
  NumberFormat,
} from '../../modules/utils/format';
import {
  SetKeyIntoArray,
  GetFormFlowString,
  GetQueryString,
  GenerateFormMode,
} from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

//
const HOME_PAGE = '/home';
const SEARCH_PAGE = '/form/vacation/search';
const GenerateColumns = (editFn, delFn, vacationList, canEdit) => {
  const col = [
    {
      title: '假別',
      dataIndex: 'vacationId',
      key: 'vacationId',
      render: (value) => ListFormat(vacationList, value),
    },
    {
      title: '發生日期',
      dataIndex: 'occuredDate',
      key: 'occuredDate',
      width: 110,
      render: (value) => DateFormat(value),
    },
    {
      title: '請假區間',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 210,
      render: (value, record) => (
        <div>
          <div>
            <strong>開始：</strong>
            <span>{DateTimeFormat(record.startTime)}</span>
          </div>
          <div>
            <strong>結束：</strong>
            <span>{DateTimeFormat(record.endTime)}</span>
          </div>
        </div>
      ),
    },
    {
      title: '請假時數',
      dataIndex: 'hours',
      key: 'hours',
      width: 90,
      render: (value) => NumberFormat(value),
    },
    {
      title: '證明文件',
      dataIndex: 'proofFile',
      key: 'proofFile',
      width: 210,
      render: (value) => <File disabled={true} value={value} />,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 110,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={editFn(record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定刪除此請假明細？"
              placement="topRight"
              okText="確認刪除"
              cancelText="取消"
              onConfirm={delFn(record.seq)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  if (!canEdit) {
    delete col[col.length - 1];
  }

  return col;
};

const FormVacation = () => {
  moment.tz.setDefault(keys.timeZone);
  const { user } = useProvided(useAuth);
  const { formId } = GetQueryString();
  const [redirectFlag, setRedirectFlag] = useState('');
  const [allDay, setAllDay] = useState(false);
  const [mainData, setMainData] = useState({});
  const [detailData, setDetailData] = useState({});
  const [detailList, setDetailList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [formFlow, setFormFlow] = useState([]);
  const [userList, setUserList] = useState([]);
  const [vacationList, setVacationList] = useState([]);
  //
  const formMode = useMemo(() => {
    return GenerateFormMode(
      mainData.formVacationId,
      mainData.userId,
      mainData.signStatus,
      mainData.signUser,
      user.userId
    );
  }, [user, mainData]);
  //
  const needProof = useMemo(() => {
    if (detailData.vacationId) {
      const item = vacationList.find((v) => v.vacationId === detailData.vacationId);
      if (item) {
        return item.proofFlag === 'Y';
      }
    }
    return false;
  }, [detailData.vacationId, vacationList]);
  //
  const needOccuredDate = useMemo(() => {
    if (detailData.vacationId) {
      const item = vacationList.find((v) => v.vacationId === detailData.vacationId);
      if (item) {
        return item.needOccuredDate === 'Y';
      }
    }
    return false;
  }, [detailData.vacationId, vacationList]);

  //=======================================================
  //load vacation from data.
  useEffect(() => {
    if (formId) {
      axios
        .post(
          `/api/setting/verifyForm`,
          { formCode: 'vacation', formId: formId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            if (res.data.result === false) {
              Notify.warn(res.data.msg);
              setRedirectFlag('home');
            }
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });

      axios
        .get(`/api/vacation/form/${formId}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { m, d, h, f } = res.data.result;
            setMainData(m);
            setDetailList(d.map(SetKeyIntoArray));
            setHistoryList(h.map(SetKeyIntoArray));
            setFormFlow(f);
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    } else {
      setMainData({
        comName: user.comName,
        deptName: user.deptName,
        userId: user.userId,
        userName: user.userName,
        tmpId: uuidv4,
      });

      axios
        .get(`/api/sign/formFlow/vocation`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const r = res.data.result;
            setFormFlow(r.flow);
          } else {
            setFormFlow([]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user, formId]);

  //load user list & vacation type list
  useEffect(() => {
    axios
      .get('/api/setting/user/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList(
            res.data.result.users.map((u) => ({
              label: u.userName,
              value: u.userId,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
    axios
      .get('/api/setting/vacation/listByUser', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setVacationList(
            res.data.result.vacations.map((u) => ({
              ...u,
              label: u.vacationName,
              value: u.vacationId,
            }))
          );
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  //=======================================================
  const handleMainDataChange = (name) => (e) => {
    setMainData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleDetailDataChange = (name) => (e) => {
    setDetailData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleDetailListSelected = (record) => () => {
    if (record) {
      record.occuredDate = record.occuredDate && moment(record.occuredDate);
      setDetailData(record);
    } else {
      setDetailData({});
    }
  };
  const handleDetailListValid = () => {
    //確認欄位是否都有填寫
    const schema = [
      { name: 'vacationId', type: 'select', required: true },
      { name: 'startTime', type: 'date', required: true },
      { name: 'endTime', type: 'date', required: true },
    ];
    if (needOccuredDate) {
      schema.push({ name: 'occuredDate', type: 'date', required: true });
    }
    if (needProof) {
      schema.push({ name: 'proofFile', type: 'select', required: true });
    }
    const validResult = ValidFormData(detailData, schema);
    if (!validResult.status) {
      setDetailData(validResult.data);
      return;
    }

    //
    const postData = {
      formVacationId: mainData.formVacationId || '',
      detail: {
        seq: detailData.seq,
        vacationId: detailData.vacationId,
        startTime: DateTimeWithZoneFormat(detailData.startTime),
        endTime: DateTimeWithZoneFormat(detailData.endTime),
        occuredDate: needOccuredDate ? DateFormat(detailData.occuredDate) : '',
        proofFile: detailData.proofFile,
      },
      detailList: detailList,
    };
    axios
      .post('/api/vacation/valid', postData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setDetailList(res.data.result.details.map(SetKeyIntoArray));
          setDetailData({});
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDetailListDelete = (seq) => () => {
    const delItem = detailList.find((p) => p.seq === seq);
    const targetItem = detailList
      .filter((p) => p.seq !== seq)
      .find(
        (p) =>
          (p.mapping || []).filter((m) =>
            (delItem.mapping || []).map((dm) => dm.userVacationId).includes(m.userVacationId)
          ).length > 0
      );

    if (targetItem) {
      //直接清空
      setDetailData({});
      setDetailList((prev) => prev.filter((p) => p.seq !== seq));
    } else {
      axios
        .post(
          '/api/vacation/delete/userVacation',
          {
            formVacationId: mainData.formVacationId,
            userVacationIds: (delItem.mapping || []).map((m) => m.userVacationId),
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            setDetailData({});
            setDetailList((prev) => prev.filter((p) => p.seq !== seq));
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };
  const handleAllDayChange = (checked) => {
    if (checked === true && detailData.startTime) {
      const end = moment(detailData.startTime).hours(18).minutes(0).seconds(0);
      setDetailData((prev) => ({
        ...prev,
        endTime: end,
      }));
    }
    setAllDay(checked);
  };
  //=======================================================
  const handleSave = (cbFn) => () => {
    //
    const validResult = ValidFormData(mainData, [
      { name: 'agentId', type: 'select', required: true },
      { name: 'reason', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setMainData(validResult.data);
      return;
    }
    //
    if (!Array.isArray(detailList) || detailList.length < 1) {
      Notify.warn('請填寫請假明細');
      return;
    }

    //
    const saveData = {
      formVacationId: mainData.formVacationId,
      agentId: mainData.agentId,
      reason: mainData.reason,
      details: detailList.map((dm) => {
        dm.startTime = DateTimeWithZoneFormat(dm.startTime);
        dm.endTime = DateTimeWithZoneFormat(dm.endTime);
        dm.occuredDate = DateFormat(dm.occuredDate);
        return dm;
      }),
    };
    axios
      .post('/api/vacation/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result.formVacationId) {
            setMainData((prev) => ({
              ...prev,
              formVacationId: res.data.result.formVacationId,
            }));
          }
          Notify.success('儲存成功');
          cbFn && cbFn();
        } else {
          Notify.warn('儲存失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = () => {
    if (mainData.formVacationId) {
      axios
        .post(
          '/api/vacation/delete',
          { formVacationId: mainData.formVacationId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            Notify.success('刪除完成');
            setRedirectFlag('search');
          } else {
            Notify.warn('刪除失敗:' + res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //=======================================================
  const handleSubmit = (comment) => {
    axios
      .post(
        '/api/sign/submit',
        {
          formCode: 'vacation',
          formId: mainData.formVacationId,
          formData: { agentId: mainData.agentId },
          comment,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('呈送完成');
          setRedirectFlag('search');
        } else {
          Notify.warn('呈送失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleApprove = (comment) => {
    axios
      .post(
        '/api/sign/approve',
        { formId: mainData.formVacationId, formCode: 'vacation', comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('審核完成');
          setRedirectFlag('home');
        } else {
          Notify.warn('審核失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleReturn = (comment) => {
    axios
      .post(
        '/api/sign/return',
        { formId: mainData.formVacationId, formCode: 'vacation', comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('退回完成');
          setRedirectFlag('home');
        } else {
          Notify.warn('退回失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleVoid = (comment) => {
    axios
      .post(
        '/api/sign/void',
        { formId: mainData.formVacationId, comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('作廢完成');
          setRedirectFlag('search');
        } else {
          Notify.warn('作廢失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  if (redirectFlag === 'search') {
    return <Redirect to={SEARCH_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>請假單</Title>
        </Col>
        <Col>
          <SignControlBar
            backUrl="/form/vacation/search"
            btnCtrl={formMode.button}
            saveFn={handleSave}
            deleteFn={handleDelete}
            submitFn={handleSubmit}
            approveFn={handleApprove}
            returnFn={handleReturn}
            voidFn={handleVoid}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={6}>
          <Display label="表單單號" value={mainData.formNumber || '自動產生'} />
        </Col>
        <Col span={12}>
          {formFlow && Array.isArray(formFlow) && formFlow.length > 0 ? (
            <Display label="表單流程" value={GetFormFlowString(formFlow)} />
          ) : (
            <Display label="表單流程" value="申請人 ➡️ 代理人 ➡️ 部門主管 ➡️ 總經理 ➡️ 承辦" />
          )}
        </Col>
        <Col span={6}>
          <Display label="申請日期" value={DateFormat(mainData.crDate || moment())} />
        </Col>
        <Col span={8}>
          <Display label="部門" value={mainData.deptName} />
        </Col>
        <Col span={8}>
          <Display label="請假人員" value={mainData.userName} />
        </Col>
        <Col span={8}>
          {formMode.canEdit ? (
            <Select
              required
              canSearch
              label="代理人"
              options={userList}
              value={mainData.agentId}
              msg={mainData.agentId_msg}
              onChange={handleMainDataChange('agentId')}
            />
          ) : (
            <Display label="代理人" value={mainData.agentName} />
          )}
        </Col>
        <Col span={24}>
          {formMode.canEdit ? (
            <Textarea
              required
              label="請假理由"
              rows={3}
              value={mainData.reason}
              msg={mainData.reason_msg}
              onChange={handleMainDataChange('reason')}
            />
          ) : (
            <Display label="請假理由" value={mainData.reason} />
          )}
        </Col>
      </Row>
      <Divider dashed />
      {formMode.canEdit && (
        <Row justify="space-between" align="middle">
          <Col span={8}>
            {detailData.seq ? (
              <Display label="假別" value={ListFormat(vacationList, detailData.vacationId)} />
            ) : (
              <Select
                required
                label="假別"
                options={vacationList}
                value={detailData.vacationId}
                msg={detailData.vacationId_msg}
                onChange={handleDetailDataChange('vacationId')}
              />
            )}
          </Col>
          {needOccuredDate && (
            <Col span={8}>
              <DatePicker
                label="發生日期"
                required={needOccuredDate}
                value={detailData.occuredDate}
                msg={detailData.occuredDate_msg}
                onChange={handleDetailDataChange('occuredDate')}
              />
            </Col>
          )}
          <Col span={8} />
          <Col span={16}>
            <TimePicker
              required
              dateOnly={allDay}
              dateOnlyType="begin"
              label="請假開始時間"
              value={detailData.startTime}
              msg={detailData.startTime_msg}
              onChange={handleDetailDataChange('startTime')}
            />
          </Col>
          <Col span={8}>
            <div>
              <strong>全天</strong>
            </div>
            <Switch checked={allDay} onChange={handleAllDayChange} />
          </Col>
          <Col span={16}>
            <TimePicker
              required
              dateOnly={allDay}
              dateOnlyType="end"
              label="請假結束時間"
              value={detailData.endTime}
              msg={detailData.endTime_msg}
              onChange={handleDetailDataChange('endTime')}
            />
          </Col>
          <Col span={8}>
            <File
              label="證明文件"
              maxCount={1}
              required={needProof}
              value={detailData.proofFile}
              msg={detailData.proofFile_msg}
              onChange={handleDetailDataChange('proofFile')}
            />
          </Col>
          <Col span={4}>
            <Button block type="primary" size="large" onClick={handleDetailListValid}>
              {detailData.seq ? '儲存' : '新增'}
            </Button>
          </Col>
        </Row>
      )}
      <br />
      <Table
        columns={GenerateColumns(
          handleDetailListSelected,
          handleDetailListDelete,
          vacationList,
          formMode.canEdit
        )}
        dataSource={detailList}
        scroll={{ x: 1050 }}
      />
      <Divider dashed />
      <SignHistory data={historyList} signStatus={mainData.signStatus} signerNames={mainData.signerNames} />
    </div>
  );
};

export default FormVacation;
