import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Divider from '../../components/Divider';
import InputInline from '../../components/Input/inline';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { Button } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
//
import { DateTimeFormat } from '../../modules/utils/format';
import { SetKeyIntoArray, GenerateFormLink, UseDebounce } from '../../modules/utils/tool';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

const Columns = [
  {
    title: '表單',
    dataIndex: 'subject',
    key: 'subject',
    sorter: (a, b) => (a.levelSeq >= b.levelSeq ? -1 : 1),
    render: (value, record) => <Link to={GenerateFormLink(record.formKind, record.formId)}>{value}</Link>,
  },
  {
    title: '最後簽核日期',
    dataIndex: 'lastSignTime',
    key: 'lastSignTime',
    width: 200,
    render: (value) => DateTimeFormat(value),
  },
  {
    title: '連結',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 80,
    render: (value, record) => {
      return (
        <Link to={GenerateFormLink(record.formKind, record.formId)}>
          <Button icon={<SelectOutlined />} />
        </Link>
      );
    },
  },
];

const Todo = () => {
  const { user } = useProvided(useAuth);
  const [todoList, setTodoList] = useState([]);
  const [offlineList, setOfflineList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filterDataTodo, setFilterDataTodo] = useState({
    applyId: '', formNumber: '',
  });
  const [filterDataOffline, setFilterDataOffline] = useState({ formNumber: '', });

  //load user list
  useEffect(() => {
    axios
      .get('/api/setting/user/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          //增加"全部"選項
          res.data.result.users.unshift({ userId: '', userName: '全部' });

          setUserList(
            res.data.result.users.map((u) => ({
              label: u.userName,
              value: u.userId,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  //load todo list
  useEffect(() => {
    axios
      .post('/api/sign/todo',
        {
          applyId: filterDataTodo.applyId,
          formNumber: filterDataTodo.formNumber
        },
        { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setTodoList(res.data.result.todo.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user, filterDataTodo]);

  //load offline list
  useEffect(() => {
    axios
      .post('/api/sign/offline',
        {
          formNumber: filterDataOffline.formNumber
        },
        { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setOfflineList(res.data.result.todo.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user, filterDataOffline]);

  //=======================================================
  const handleFilterDataChange = (name) => (val) => {
    if (name.includes('todo_')) {
      setFilterDataTodo((prev) => ({ ...prev, [name.replace('todo_', '')]: val }));
    }
    else {
      setFilterDataOffline((prev) => ({ ...prev, [name.replace('offline_', '')]: val }));
    }
  };

  const handleOnChangeInput = (event) => {
    event.persist();
    if (event.target.name.includes('todo_')) {
      setFilterDataTodo((prev) => ({ ...prev, [event.target.name.replace('todo_', '')]: event.target.value }));
    }
    else {
      setFilterDataOffline((prev) => ({ ...prev, [event.target.name.replace('offline_', '')]: event.target.value }));
    }
  };

  const optimisedHandleChange = UseDebounce(handleOnChangeInput, 1000);

  //=======================================================
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>簽核列表</Title>
        </Col>
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <SelectInline
                canSearch
                label="申請人"
                options={userList}
                value={filterDataTodo.applyId}
                onChange={handleFilterDataChange('todo_applyId')}
                style={{ minWidth: '120px' }}
              />
            </Col>
            <Col>
              <InputInline
                label="單號"
                name="todo_formNumber"
                defaultValue={filterDataTodo.formNumber}
                onChange={optimisedHandleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Table columns={Columns} dataSource={todoList} scroll={{ y: 'calc(100vh - 195px)' }} />
      <br />
      <Row justify="space-between" align="middle">
        <Col>
          <Title>線下作業區</Title>
        </Col>
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <InputInline
                label="單號"
                name="offline_formNumber"
                defaultValue={filterDataOffline.formNumber}
                onChange={optimisedHandleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Table columns={Columns} dataSource={offlineList} scroll={{ y: 'calc(100vh - 195px)' }} />
    </div>
  );
};

export default Todo;
