import React, { useMemo } from 'react';
import axios from 'axios';
import mime from 'mime';
import styled from 'styled-components';
//
import Notify from '../../components/Notify';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import JLayout from '../layout';
//
import { UploadFormat } from '../../modules/utils/format';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

// #預期傳入的 value 格式: [{ annexId, annexName }, {annexId, annexName}, ... ]
const JFile = ({ required, label, msg, value, onChange, disabled = false, ...props }) => {
  const { user } = useProvided(useAuth);

  const innerValue = useMemo(() => {
    return UploadFormat(value);
  }, [value]);

  const handleUpload = (file) => {
    const data = new FormData();
    data.append('file', file); //must be the last item.
    axios
      .post('/api/file/upload', data, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const anx = res.data.result.annex;
          onChange && onChange([...innerValue, anx]);
        } else {
          Notify.warn('上傳檔案失敗：' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
    return false;
  };
  const handleDownload = (file) => {
    if (file && file.uid) {
      axios
        .post(
          '/api/file/download',
          { annexId: file.uid },
          { headers: { Authorization: user.token }, responseType: 'blob' }
        )
        .then((res) => {
          if (res && res.data && res.data.type === 'application/json') {
            let reader = new FileReader();
            reader.onload = function (event) {
              let content = reader.result;
              let message = JSON.parse(content).msg;
              Notify.error('檔案下載失敗：' + message);
            };
            reader.readAsText(res.data);
          } else {
            const mimeType = mime.getType(file.name);
            const url = window.URL.createObjectURL(new Blob([res.data], { type: mimeType }));
            const newWin = window.open(url, '_blank');
            newWin.addEventListener('load', () => {
              newWin.document.title = file.name;
            });
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };
  const handleDelete = (file) => {
    if (file && file.uid) {
      axios
        .post('/api/file/delete', { annexId: file.uid }, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.status && res.data.status) {
            //刪除成功時，直接把此項從 value 中移除
            onChange && onChange(innerValue.filter((v) => v.annexId !== file.uid));
          } else {
            Notify.warn('刪除檔案失敗：' + res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  if (label) {
    return (
      <JLayout label={label} msg={msg} required={required}>
        <Div>
          <Upload
            className={props.maxCount === 1 ? 'j-upload-inline' : ''}
            fileList={innerValue}
            beforeUpload={handleUpload}
            onPreview={handleDownload}
            onRemove={handleDelete}
            disabled={disabled}
          >
            {disabled ? null : (
              <Button icon={<UploadOutlined />} disabled={props.maxCount <= innerValue.length}>
                上傳
              </Button>
            )}
          </Upload>
        </Div>
      </JLayout>
    );
  } else {
    return (
      <Upload
        className={props.maxCount === 1 ? 'j-upload-inline' : ''}
        fileList={innerValue}
        onPreview={handleDownload}
        onRemove={handleDelete}
        disabled={disabled}
      >
        {disabled ? null : (
          <Button icon={<UploadOutlined />} disabled={props.maxCount <= innerValue.length}>
            上傳
          </Button>
        )}
      </Upload>
    );
  }
};

const Div = styled.div`
  width: 100%;
`;

export default JFile;
