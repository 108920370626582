import React from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
//
import Col from '../../components/Col';
import Row from '../../components/Row';
import Select from '../../components/Select';
import { Button, Popconfirm } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import LogoIcon from '../../images/logo.svg';
//
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
import Notify from '../../components/Notify';

const Avatar = () => {
  const { user, AuthUser, UnAuthUser } = useProvided(useAuth);

  const handleLogout = () => {
    UnAuthUser();
  };
  //=======================================================
  const handleClockIn = () => {
    //
    const postData = {
      type: 'clockIn',
      userId: user.userId,
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:mm:ss'),
    };
    axios
      .post('/api/setting/clock/generate', postData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('上班打卡成功');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleClockOut = () => {
    //
    const postData = {
      type: 'clockOut',
      userId: user.userId,
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:mm:ss'),
    };
    axios
      .post('/api/setting/clock/generate', postData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('下班打卡成功');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  //=======================================================
  const handleCompanyChange = (e) => {
    const comId = e && e.target ? e.target.value : e;
    //call api.
    axios
      .post('/api/setting/verifyComLogin', { comId: comId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          UnAuthUser();
          AuthUser(res.data.result.user, res.data.result.token);
        } else {
          Notify.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error('登入異常');
      });
  };

  //=======================================================
  return (
    <div>
      <LogoDiv>
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col>
            <Logo alt="sola-logo" src={LogoIcon} />
          </Col>
        </Row>
      </LogoDiv>
      <InfoDiv>
        <Row justify="space-between" align="middle">
          <Col>
            <UserName>{user.userName || '未知人員'}</UserName>
          </Col>
          <Col>
            <Button danger type="primary" size="small" icon={<LoginOutlined />} onClick={handleLogout}>
              登出
            </Button>
          </Col>
          <Col span={24}>
            {user.otherCompanies && user.otherCompanies.length > 1 ? (
              <Select
                options={user.otherCompanies.map((u) => ({
                  label: u.comName,
                  value: u.comId,
                }))}
                value={user.comId}
                style={{ minWidth: '120px' }}
                onChange={handleCompanyChange}
              />
            ) : (
              <ComName>{user.comName || '未知公司'}</ComName>
            )}
          </Col>
        </Row>
        <Row align="middle">
          <Col>
            <Popconfirm
              title="進行上班打卡？"
              placement="topRight"
              okText="確認"
              cancelText="取消"
              onConfirm={handleClockIn}
            >
              <Button size="small">上班打卡</Button>
            </Popconfirm>
          </Col>
          <Col>
            <Popconfirm
              title="進行下班打卡？"
              placement="topRight"
              okText="確認"
              cancelText="取消"
              onConfirm={handleClockOut}
            >
              <Button size="small">下班打卡</Button>
            </Popconfirm>
          </Col>
        </Row>
      </InfoDiv>
    </div>
  );
};

const LogoDiv = styled.div`
  height: 60px;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
`;
const Logo = styled.img`
  height: 35px;
`;

const InfoDiv = styled.div`
  padding: 10px 15px 10px 15px;
  background-color: #fff;
`;

const UserName = styled.div`
  font-size: 1.8em;
  font-weight: bold;
`;
const ComName = styled.div`
  font-size: 1em;
  color: #ae0001;
`;

export default Avatar;
