import React from 'react';
import { Input } from 'antd';
import JLayout from '../layoutInline';

const JInput = ({ label, msg, required, ...props }) => {
  let inputStyle = { width: '100%' };
  if (msg) {
    inputStyle.border = '1px solid #f00';
    inputStyle.borderRadius = '4px';
  }

  let hint = '';
  if (label) {
    hint = '請填寫' + label;
  }

  return (
    <JLayout label={label} msg={msg} required={required}>
      <Input placeholder={hint} style={inputStyle} {...props} />
    </JLayout>
  );
};

export default JInput;
