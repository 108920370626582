import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import Switch from '../../components/Switch';
import Table from '../../components/Table';
import Title from '../../components/Title';
import CSVReader from 'react-csv-reader';
import { Button, Popconfirm, Tooltip } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ImportOutlined,
  PlusOutlined,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons';
//
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
import csvSample from './sample.csv';
//
const HOME_PAGE = '/home';

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '項目名稱',
      dataIndex: 'invoiceItemName',
      key: 'invoiceItemName',
      width: 300,
    },
    {
      title: '所屬會計科目',
      dataIndex: 'accountTitleName',
      key: 'accountTitleName',
      render: (value, record) => `${record.code} - ${record.accountTitleName}`,
    },
    {
      title: '單獨匯出',
      dataIndex: 'independent',
      key: 'independent',
      render: (value) => (value === true ? '是' : '否'),
      width: 100,
    },
    {
      title: '項目代碼',
      dataIndex: 'invoiceItemCode',
      key: 'invoiceItemCode',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={openFn('edit', record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確認刪除此請款項目？"
              placement="topRight"
              okText="確定刪除"
              cancelText="取消"
              onConfirm={deleteFn(record.invoiceItemId)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

const GenerateImportColumns = [
  {
    title: '科目代碼',
    dataIndex: 'code',
    key: 'code',
    width: 150,
  },
  {
    title: '科目名稱',
    dataIndex: 'name',
    key: 'name',
  },
];

const SettingInvoiceItem = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [keyword, setKeyword] = useState('');
  const [invoiceItemList, setInvoiceItemList] = useState([]);
  const [invoiceItemData, setInvoiceItemData] = useState({});
  const [accountTitleList, setAccountTitleList] = useState([]);
  const [accountModelOpen, setAccountModalOpen] = useState(false);
  const [accountData, setAccountData] = useState({});
  //
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importList, setImportList] = useState([]);
  //
  const viewList = useMemo(() => {
    return invoiceItemList.filter(
      (v) =>
        v.invoiceItemName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        v.accountTitleName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        (v.invoiceItemCode || '').toLowerCase().indexOf(keyword.toLowerCase()) > -1
    );
  }, [invoiceItemList, keyword]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrInvoice' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load accountTitle
  const loadAccountTitle = useCallback(() => {
    axios
      .get('/api/setting/accountTitle/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setAccountTitleList(
            res.data.result.accountTitles.map((u) => ({
              ...u,
              label: `${u.code} - ${u.accountTitleName}`,
              value: u.accountTitleId,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  //load data
  const loadInvoiceItemList = useCallback(() => {
    axios
      .get('/api/setting/invoiceItem/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setInvoiceItemList(res.data.result.invoiceItems.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  useEffect(() => {
    loadInvoiceItemList();
    loadAccountTitle();
  }, [loadInvoiceItemList, loadAccountTitle]);

  //============================================================
  const handleModalOpen = (type, record) => () => {
    if (record) {
      setInvoiceItemData(record);
    }
    setModalProps({ open: true, type: type });
  };
  const handleModalClose = () => {
    setInvoiceItemData({});
    setModalProps({ open: false, type: '' });
  };
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };
  const handleDataChange = (name) => (e) => {
    setInvoiceItemData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleSave = () => {
    //valid
    const validResult = ValidFormData(invoiceItemData, [
      { name: 'invoiceItemName', type: 'input', required: true },
      { name: 'accountTitleId', type: 'select', required: true },
    ]);
    if (!validResult.status) {
      setInvoiceItemData(validResult.data);
      return;
    }

    axios
      .post('/api/setting/invoiceItem/save', invoiceItemData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadInvoiceItemList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = (invoiceItemId) => () => {
    axios
      .post('/api/setting/invoiceItem/delete', { invoiceItemId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除成功');
          loadInvoiceItemList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleAccountModalOpen = () => {
    setAccountModalOpen(true);
  };
  const handleAccountModalClose = () => {
    setAccountData({});
    setAccountModalOpen(false);
  };
  const handleAccountDataChange = (name) => (e) => {
    setAccountData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };

  const handleAccountSave = () => {
    //valid
    const validResult = ValidFormData(accountData, [
      { name: 'code', type: 'input', required: true },
      { name: 'name', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setAccountData(validResult.data);
      return;
    }
    const exist = accountTitleList.find((a) => a.code === accountData.code);
    if (exist) {
      Notify.warn('會計科目已存在');
      return;
    }

    axios
      .post('/api/setting/accountTitle/save', accountData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success(
            <div>
              <strong>新增會計科目完成</strong>
            </div>
          );
          setAccountData({});
          loadAccountTitle();
          handleAccountModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  //============================================================
  const handleImportModalOpen = () => {
    setImportModalOpen(true);
  };
  const handleImportModalClose = () => {
    setImportList([]);
    setImportModalOpen(false);
  };
  const handleCsvUploadPreAction = () => {
    document.getElementById('j-csv-reader').click();
  };
  const handleCsvUpload = (data) => {
    setImportList(data || []);
  };
  const handleImport = () => {
    if (!Array.isArray(importList) || importList.length < 1) {
      Notify.warn('請先上傳檔案');
      return;
    }
    axios
      .post('/api/setting/accountTitle/import', importList, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success(
            <div>
              <strong>更新會計科目完成</strong>
            </div>
          );
          loadAccountTitle();
          handleImportModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>請款項目管理</Title>
            </Col>
            <Col>
              <InputInline
                label="關鍵字"
                placeholder="請輸入項目名稱、代碼或會計科目名稱"
                value={keyword}
                onChange={handleKeywordChange}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle">
            <Col>
              <Button size="large" icon={<ImportOutlined />} onClick={handleImportModalOpen}>
                匯入會計科目
              </Button>
            </Col>
            <Col>
              <Button size="large" onClick={handleAccountModalOpen} icon={<PlusOutlined />}>
                新增會計科目
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                onClick={handleModalOpen('add', null)}
                icon={<PlusOutlined />}
              >
                新增請款項目
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal width="600px" visible={modalProps.open}>
        <Title>請款項目</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <Display label="項目識別碼" value={invoiceItemData.invoiceItemId || '自動產生'} />
          </Col>
          <Col span={24}>
            <Input
              required
              label="項目名稱"
              value={invoiceItemData.invoiceItemName}
              msg={invoiceItemData.invoiceItemName_msg}
              onChange={handleDataChange('invoiceItemName')}
            />
          </Col>
          <Col span={24}>
            <Select
              required
              canSearch
              label="所屬會計科目"
              options={accountTitleList}
              value={invoiceItemData.accountTitleId}
              msg={invoiceItemData.accountTitleId_msg}
              onChange={handleDataChange('accountTitleId')}
            />
          </Col>
          <Col span={24}>
            <Input
              label="項目代碼 (配合外部呼叫API使用)"
              placeholder="請填寫項目代碼"
              value={invoiceItemData.invoiceItemCode}
              onChange={handleDataChange('invoiceItemCode')}
            />
          </Col>
          <Col span={8}>
            <Switch
              label="單獨匯出"
              style={{ minWidth: '50px' }}
              checkedChildren="是"
              unCheckedChildren="否"
              checked={invoiceItemData.independent}
              onChange={handleDataChange('independent')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="600px" visible={accountModelOpen}>
        <Title>新增會計科目</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <Input
              required
              label="科目代碼"
              value={accountData.code}
              msg={accountData.code_msg}
              onChange={handleAccountDataChange('code')}
            />
          </Col>
          <Col span={12}>
            <Input
              required
              label="科目名稱"
              value={accountData.name}
              msg={accountData.name_msg}
              onChange={handleAccountDataChange('name')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleAccountSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleAccountModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="800px" visible={importModalOpen}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>匯入會計科目</Title>
          </Col>
          <Col>
            <Row align="middle">
              <Col>
                <Button
                  size="large"
                  icon={<DownOutlined />}
                  type="link"
                  href={csvSample}
                  download="會計科目對照表.csv"
                >
                  下載匯入範例
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  icon={<UploadOutlined />}
                  onClick={handleCsvUploadPreAction}
                >
                  上傳
                </Button>
                <CSVReader
                  inputId="j-csv-reader"
                  onFileLoaded={handleCsvUpload}
                  parserOptions={{ header: true, skipEmptyLines: true }}
                  inputStyle={{ display: 'none' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Table columns={GenerateImportColumns} dataSource={importList} scroll={{ y: '500px' }} />
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" icon={<ImportOutlined />} onClick={handleImport}>
              確定匯入
            </Button>
          </Col>
          <Col>
            <Button onClick={handleImportModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SettingInvoiceItem;
