import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Title from '../../components/Title';
import CSVReader from 'react-csv-reader';
import { Button, Popconfirm, Tooltip } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ImportOutlined,
  SaveOutlined,
  SyncOutlined,
  UploadOutlined,
} from '@ant-design/icons';
//
import { DateFormat, NumberFormat } from '../../modules/utils/format';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
import csvSample from './sample.csv';
const HOME_PAGE = '/home';

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '啟用日期',
      dataIndex: 'activeDate',
      key: 'activeDate',
      width: 120,
      render: (value) => DateFormat(value),
    },
    {
      title: '月薪總額',
      dataIndex: 'startSalary',
      key: 'startSalary',
      width: 150,
      render: (value, record) => `${NumberFormat(record.startSalary)} - ${NumberFormat(record.endSalary)}`,
    },
    {
      title: '扣繳稅額(本人)',
      dataIndex: 'dependent0',
      key: 'dependent0',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(1人)',
      dataIndex: 'dependent1',
      key: 'dependent1',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(2人)',
      dataIndex: 'dependent2',
      key: 'dependent2',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(3人)',
      dataIndex: 'dependent3',
      key: 'dependent3',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(4人)',
      dataIndex: 'dependent4',
      key: 'dependent4',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(5人)',
      dataIndex: 'dependent5',
      key: 'dependent5',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(6人)',
      dataIndex: 'dependent6',
      key: 'dependent6',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(7人)',
      dataIndex: 'dependent7',
      key: 'dependent7',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(8人)',
      dataIndex: 'dependent8',
      key: 'dependent8',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(9人)',
      dataIndex: 'dependent9',
      key: 'dependent9',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(10人)',
      dataIndex: 'dependent10',
      key: 'dependent10',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '扣繳稅額(11人)',
      dataIndex: 'dependent11',
      key: 'dependent11',
      width: 100,
      render: (value) => NumberFormat(value),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={openFn('edit', record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確認刪除此級距設定？"
              placement="topRight"
              okText="確定刪除"
              cancelText="取消"
              onConfirm={deleteFn(record.taxId)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

const GenerateImportColumns = [
  {
    title: '月薪總額(起)',
    dataIndex: 'startSalary',
    key: 'startSalary',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '月薪總額(迄)',
    dataIndex: 'endSalary',
    key: 'endSalary',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(本人)',
    dataIndex: 'dependent0',
    key: 'dependent0',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(1人)',
    dataIndex: 'dependent1',
    key: 'dependent1',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(2人)',
    dataIndex: 'dependent2',
    key: 'dependent2',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(3人)',
    dataIndex: 'dependent3',
    key: 'dependent3',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(4人)',
    dataIndex: 'dependent4',
    key: 'dependent4',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(5人)',
    dataIndex: 'dependent5',
    key: 'dependent5',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(6人)',
    dataIndex: 'dependent6',
    key: 'dependent6',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(7人)',
    dataIndex: 'dependent7',
    key: 'dependent7',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(8人)',
    dataIndex: 'dependent8',
    key: 'dependent8',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(9人)',
    dataIndex: 'dependent9',
    key: 'dependent9',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(10人)',
    dataIndex: 'dependent10',
    key: 'dependent10',
    width: 100,
    render: (value) => NumberFormat(value),
  },
  {
    title: '扣繳稅額(11人)',
    dataIndex: 'dependent11',
    key: 'dependent11',
    width: 100,
    render: (value) => NumberFormat(value),
  },
];

const SettingSalaryWithholdingTax = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [taxList, setTaxList] = useState([]);
  const [taxData, setTaxData] = useState({});
  const [activeList, setActiveList] = useState([]);
  const [searchActivDate, setSearchActivDate] = useState('');
  const [filterData, setFilterData] = useState({ keyword: '' });
  const [minSalary, setMinSalary] = useState(0);
  //
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importList, setImportList] = useState([]);
  const [importDate, setImportDate] = useState(moment());
  //.
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  //
  const viewList = useMemo(() => {
    if (filterData.keyword && Number(filterData.keyword) >= minSalary) {
      return taxList.filter(
        (v) => v.startSalary <= Number(filterData.keyword) && v.endSalary >= Number(filterData.keyword)
      );
    } else return taxList;
  }, [taxList, filterData, minSalary]);

  //============================================================
  useEffect(() => {
    axios
      .post(
        `/api/setting/verifyMenu`,
        { menuId: 'hrSalaryWHTax' },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  const loadFilterList = useCallback(() => {
    axios
      .get('/api/setting/salaryWithholdingTax/filter', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setActiveList(res.data.result.filters.map((u) => ({ label: u.activeDate, value: u.activeDate })));
          if (res.data.result.filters.length > 0) {
            setSearchActivDate(res.data.result.filters[0].activeDate);
          }
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  //load data
  const loadTaxList = useCallback(() => {
    axios
      .get(`/api/setting/salaryWithholdingTax/list?activeDate=${searchActivDate}`, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setTaxList(res.data.result.taxes.map(SetKeyIntoArray));
          setMinSalary(res.data.result.taxes[0].startSalary);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user, searchActivDate]);

  useEffect(() => {
    loadFilterList();
    loadTaxList();
  }, [loadFilterList, loadTaxList]);

  //============================================================
  const handleModalOpen = (type, record) => () => {
    if (record) {
      setTaxData(record);
    }
    setModalProps({ open: true, type: type });
  };
  const handleModalClose = () => {
    setTaxData({});
    setModalProps({ open: false, type: '' });
  };
  const handleSearchActivDateChange = (date) => {
    setSearchActivDate(date);
  };
  const handleFilterDataChange = (name) => (e) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
    }));
  };
  const handleDataChange = (name) => (e) => {
    setTaxData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleSave = () => {
    //valid
    const validResult = ValidFormData(taxData, [
      { name: 'dependent0', type: 'number', required: false },
      { name: 'dependent1', type: 'number', required: false },
      { name: 'dependent2', type: 'number', required: false },
      { name: 'dependent3', type: 'number', required: false },
      { name: 'dependent4', type: 'number', required: false },
      { name: 'dependent5', type: 'number', required: false },
      { name: 'dependent6', type: 'number', required: false },
      { name: 'dependent7', type: 'number', required: false },
      { name: 'dependent8', type: 'number', required: false },
      { name: 'dependent9', type: 'number', required: false },
      { name: 'dependent10', type: 'number', required: false },
      { name: 'dependent11', type: 'number', required: false },
    ]);
    if (!validResult.status) {
      setTaxData(validResult.data);
      return;
    }

    axios
      .post('/api/setting/salaryWithholdingTax/save', taxData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadTaxList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = (taxId) => () => {
    axios
      .post('/api/setting/salaryWithholdingTax/delete', { taxId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除成功');
          loadTaxList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleImportModalOpen = () => {
    setImportModalOpen(true);
  };
  const handleImportModalClose = () => {
    setImportList([]);
    setImportModalOpen(false);
  };
  const handleImportDateChange = (date) => {
    setImportDate(date);
  };
  const handleCsvUploadPreAction = () => {
    document.getElementById('j-csv-reader').click();
  };
  const handleCsvUpload = (data) => {
    setImportList(
      data.map((u, Idx) => ({
        key: Idx,
        startSalary: u.月薪總額_起,
        endSalary: u.月薪總額_迄,
        dependent0: u.扣繳稅額_本人,
        dependent1: u.扣繳稅額_1人,
        dependent2: u.扣繳稅額_2人,
        dependent3: u.扣繳稅額_3人,
        dependent4: u.扣繳稅額_4人,
        dependent5: u.扣繳稅額_5人,
        dependent6: u.扣繳稅額_6人,
        dependent7: u.扣繳稅額_7人,
        dependent8: u.扣繳稅額_8人,
        dependent9: u.扣繳稅額_9人,
        dependent10: u.扣繳稅額_10人,
        dependent11: u.扣繳稅額_11人,
      })) || []
    );
  };
  const handleImport = () => {
    if (!Array.isArray(importList) || importList.length < 1) {
      Notify.warn('請先上傳檔案');
      return;
    }
    axios
      .post(
        '/api/setting/salaryWithholdingTax/import',
        { activeDate: importDate, importList: importList },
        {
          headers: { Authorization: user.token },
        }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('匯入稅額完成');
          handleImportModalClose();
          loadFilterList();
          loadTaxList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  //============================================================
  const handleUpdateModalOpen = () => {
    setUpdateModalOpen(true);
  };
  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
  };
  const handleUserSalaryUpdate = () => {
    axios
      .get('/api/setting/user/salary/updateTax', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('更新人員薪資完成');
          handleUpdateModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>薪資所得扣繳稅額設定</Title>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Button size="large" icon={<SyncOutlined />} type="primary" onClick={handleUpdateModalOpen}>
                更新人員薪資
              </Button>
            </Col>
            <Col>
              <Button size="large" icon={<ImportOutlined />} onClick={handleImportModalOpen}>
                匯入稅額設定
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row align="middle">
        <Col>
          <SelectInline
            label="啟用日期"
            options={activeList}
            value={searchActivDate}
            onChange={handleSearchActivDateChange}
            style={{ minWidth: '150px' }}
          />
        </Col>
        <Col>
          <InputInline
            label="查詢月薪"
            placeholder="請輸入查詢月薪"
            value={filterData.keyword}
            onChange={handleFilterDataChange('keyword')}
            style={{ minWidth: '200px' }}
          />
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal width="600px" visible={modalProps.open}>
        <Title>薪資所得扣繳稅額</Title>
        <Divider />
        <Row>
          <Col span={8}>
            <Display label="月薪總額(起)" value={taxData.startSalary} />
          </Col>
          <Col span={8}>
            <Display label="月薪總額(迄)" value={taxData.endSalary} />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Input
              label="扣繳稅額(本人)"
              value={taxData.dependent0}
              msg={taxData.dependent0_msg}
              onChange={handleDataChange('dependent0')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(1人)"
              value={taxData.dependent1}
              msg={taxData.dependent1_msg}
              onChange={handleDataChange('dependent1')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(2人)"
              value={taxData.dependent2}
              msg={taxData.dependent2_msg}
              onChange={handleDataChange('dependent2')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(3人)"
              value={taxData.dependent3}
              msg={taxData.dependent3_msg}
              onChange={handleDataChange('dependent3')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(4人)"
              value={taxData.dependent4}
              msg={taxData.dependent4_msg}
              onChange={handleDataChange('dependent4')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(5人)"
              value={taxData.dependent5}
              msg={taxData.dependent5_msg}
              onChange={handleDataChange('dependent5')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(6人)"
              value={taxData.dependent6}
              msg={taxData.dependent6_msg}
              onChange={handleDataChange('dependent6')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(7人)"
              value={taxData.dependent7}
              msg={taxData.dependent7_msg}
              onChange={handleDataChange('dependent7')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(8人)"
              value={taxData.dependent8}
              msg={taxData.dependent8_msg}
              onChange={handleDataChange('dependent8')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(9人)"
              value={taxData.dependent9}
              msg={taxData.dependent9_msg}
              onChange={handleDataChange('dependent9')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(10人)"
              value={taxData.dependent10}
              msg={taxData.dependent10_msg}
              onChange={handleDataChange('dependent10')}
            />
          </Col>
          <Col span={6}>
            <Input
              label="扣繳稅額(11人)"
              value={taxData.dependent11}
              msg={taxData.dependent11_msg}
              onChange={handleDataChange('dependent11')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="1200px" visible={importModalOpen}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>匯入勞健保級距</Title>
          </Col>
          <Col>
            <Row align="middle">
              <Col>
                <Button
                  size="large"
                  icon={<DownOutlined />}
                  type="link"
                  href={csvSample}
                  download="薪資所得扣繳稅額設定.csv"
                >
                  下載匯入範例
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  icon={<UploadOutlined />}
                  onClick={handleCsvUploadPreAction}
                >
                  上傳
                </Button>
                <CSVReader
                  inputId="j-csv-reader"
                  onFileLoaded={handleCsvUpload}
                  parserOptions={{ header: true, skipEmptyLines: true }}
                  inputStyle={{ display: 'none' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <DatePicker
              required
              label="啟用日期"
              picker="date"
              allowClear={false}
              value={importDate}
              onChange={handleImportDateChange}
              style={{ minWidth: '120px' }}
            />
          </Col>
        </Row>
        <Divider />
        <Table columns={GenerateImportColumns} dataSource={importList} scroll={{ y: '500px' }} />
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" icon={<ImportOutlined />} onClick={handleImport}>
              確定匯入
            </Button>
          </Col>
          <Col>
            <Button onClick={handleImportModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="800px" visible={updateModalOpen}>
        <Title>更新人員薪資</Title>
        <Divider />
        <Row gutter={[20, 10]}>
          <Col span={24}>
            <strong style={{ color: '#D73141' }}>
              ※ 更新動作會直接以最新啟用日期的資料異動個人薪資內的薪資所得扣繳稅額對應，請注意！
            </strong>
          </Col>
        </Row>
        <br />
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleUserSalaryUpdate}>
              更新設定
            </Button>
          </Col>
          <Col>
            <Button onClick={handleUpdateModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SettingSalaryWithholdingTax;
