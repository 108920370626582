import React, { useMemo, useState, useEffect } from 'react';
import { Select, Divider, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import JLayout from '../layout';

const { Option } = Select;

const JSelect = ({ canSearch, label, options, msg, required, ...props }) => {
  const [custom, setCustom] = useState('');
  const [optionList, setOptionList] = useState([]);

  let inputStyle = { width: '100%' };
  if (msg) {
    inputStyle.border = '1px solid #f00';
    inputStyle.borderRadius = '4px';
  }

  let hint = '';
  if (label) {
    hint = '請選擇' + label;
  }

  useEffect(() => {
    if (Array.isArray(options)) {
      setOptionList(options);
    }
  }, [options]);

  const searchProps = useMemo(() => {
    if (canSearch === true) {
      return {
        showSearch: true,
        optionFilterProp: 'children',
        filterOption: (input, option) => {
          return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
      };
    } else {
      return {};
    }
  }, [canSearch]);

  const handleCustomChange = (e) => {
    setCustom(e && e.target ? e.target.value : e);
  };

  const handleAddItem = () => {
    if (custom && !optionList.find((v) => v.value === custom)) {
      setOptionList((prev) => [...prev, { label: custom, value: custom }]);
    }
    setCustom('');
  };

  return (
    <JLayout label={label} msg={msg} required={required}>
      <Select
        {...searchProps}
        placeholder={hint}
        style={inputStyle}
        {...props}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input style={{ flex: 'auto' }} value={custom} onChange={handleCustomChange} />
              <Button onClick={handleAddItem}>
                <PlusOutlined /> Add item
              </Button>
            </div>
          </div>
        )}
      >
        {Array.isArray(optionList)
          ? optionList.map((o, i) => (
              <Option key={i} value={o.value}>
                {o.label}
              </Option>
            ))
          : null}
      </Select>
    </JLayout>
  );
};

export default JSelect;
