module.exports = {
  reCaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_HTML_KEY,
  secondNHIRate: process.env.REACT_APP_SECOND_NHI_RATE,
  taxPrepaidRate: process.env.REACT_APP_TAX_PREPAID_RATE,
  fiveMoreOwnerNHIGrade: process.env.REACT_APP_FIVE_MORE_OWNER_NHI_GRADE,
  fiveLessOwnerNHIGrade: process.env.REACT_APP_FIVE_LESS_OWNER_NHI_GRADE,
  timeZone: process.env.REACT_APP_TIME_ZONE,
  minSalaryWHTax: process.env.REACT_APP_MIM_SALARY_WH_TAX,
  jwtExpiresIn: process.env.REACT_APP_JWT_EXPIRES_IN,
};
