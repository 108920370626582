import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import DatePickerInline from '../../components/DatePicker/inline';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Title from '../../components/Title';
import CSVReader from 'react-csv-reader';
import { Button } from 'antd';
import { ImportOutlined, UploadOutlined, SyncOutlined } from '@ant-design/icons';
//
import { DateFormat, ListFormat } from '../../modules/utils/format';
import { ScheduleType } from '../../modules/utils/list';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

const SEARCH_DATE_COUNT = 8; //搜尋的天數
const HOME_PAGE = '/home';

const GetButtonBgColor = (scheduleType) => {
  let bgColor = '#fff';
  switch (scheduleType) {
    case '3':
      bgColor = '#C6E09C';
      break;
    case '2':
      bgColor = '#F6AFAA';
      break;
    case '1':
      bgColor = '#FACCC9';
      break;
    case '0':
      bgColor = '#EE817B';
      break;
    default:
      break;
  }
  return bgColor;
};
const GenerateColumns = (begin, end, clickFn) => {
  const base = [
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 100,
    },
  ];

  let currentDate = begin.clone();
  while (currentDate.isSameOrBefore(end)) {
    const tmpDateText = DateFormat(currentDate);
    base.push({
      title: tmpDateText,
      dataIndex: tmpDateText,
      key: tmpDateText,
      render: (value, record) => {
        if (value) {
          return (
            <ColumnButton bg={GetButtonBgColor(value.type)} onClick={clickFn(record, tmpDateText)}>
              {ListFormat(ScheduleType, value.type)}
            </ColumnButton>
          );
        } else {
          return null;
        }
      },
    });

    currentDate.add(1, 'day');
  }

  return base;
};
const GenerateGovColumns = [
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date',
    width: 120,
  },
  {
    title: '名稱',
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: '是否放假',
    dataIndex: 'isHoliday',
    key: 'isHoliday',
    width: 100,
  },
  {
    title: '假日類型',
    dataIndex: 'holidayCategory',
    key: 'holidayCategory',
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
  },
];

const SettingSchedule = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [searchBeginDate, setSearchBeginDate] = useState(moment());
  const [searchUserId, setSearchUserId] = useState('');
  const [scheduleData, setScheduleData] = useState({});
  const [scheduleList, setScheduleList] = useState([]);
  const [userList, setUserList] = useState([]);
  //
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importList, setImportList] = useState([]);
  //
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [syncData, setSyncData] = useState({});
  //
  const searchEndDate = useMemo(() => {
    if (searchBeginDate) {
      return searchBeginDate.clone().add(SEARCH_DATE_COUNT, 'day');
    } else {
      return null;
    }
  }, [searchBeginDate]);
  //
  const viewList = useMemo(() => {
    if (searchUserId) {
      return scheduleList.filter((s) => s.memId === searchUserId);
    } else {
      return scheduleList;
    }
  }, [searchUserId, scheduleList]);

  //===============================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrSchedule' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false)
            setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load users
  useEffect(() => {
    axios
      .get(`/api/setting/user/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList([
            { label: '不限', value: '' },
            ...res.data.result.users.map((m) => {
              return {
                label: m.userName,
                value: m.userId,
              };
            }),
          ]);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load all users' schedules
  const loadUserSchedules = useCallback(() => {
    const b = DateFormat(searchBeginDate);
    const e = DateFormat(searchEndDate);
    axios
      .get(`/api/setting/user/schedule/all?begin=${b}&end=${e}`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setScheduleList(res.data.result.schedules.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user, searchBeginDate, searchEndDate]);
  useEffect(() => {
    loadUserSchedules();
  }, [loadUserSchedules]);

  //==============================================
  const handleSearchUserIdChange = (val) => {
    setSearchUserId(val);
  };
  const handleSearchBeginDateChange = (date) => {
    setSearchBeginDate(date);
  };
  const handleScheduleDataTypeChange = (val) => {
    setScheduleData((prev) => ({
      ...prev,
      type: val,
      type_msg: '',
    }));
  };
  const handleSyncDataChange = (name) => (date) => {
    setSyncData((prev) => ({
      ...prev,
      [name]: date,
      [name + '_msg']: '',
    }));
  };

  //================================================
  const handleModalOpen = (record, dateString) => () => {
    if (record) {
      setScheduleData({
        userId: record.userId,
        userName: record.userName,
        date: record[dateString].date,
        typeOld: record[dateString].type,
        type: null,
      });
      setModalOpen(true);
    }
  };
  const handleModalClose = () => {
    setScheduleData({});
    setModalOpen(false);
  };

  const handleImportModalOpen = () => {
    setImportModalOpen(true);
  };
  const handleImportModalClose = () => {
    setImportList([]);
    setImportModalOpen(false);
  };

  const handleSyncModalOpen = () => {
    setSyncModalOpen(true);
  };
  const handleSyncModalClose = () => {
    setSyncModalOpen(false);
    setSyncData({});
  };

  //================================================
  const handleSave = () => {
    //
    const validResult = ValidFormData(scheduleData, [{ name: 'type', type: 'select', required: true }]);
    if (!validResult.status) {
      setScheduleData(validResult.data);
      return;
    }

    //
    if (scheduleData.typeOld === scheduleData.type) {
      handleModalClose();
      return;
    }

    //
    const saveData = {
      userId: scheduleData.userId,
      date: scheduleData.date,
      type: scheduleData.type,
    };

    //呼叫
    axios
      .post('/api/setting/user/schedule/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          loadUserSchedules();
          handleModalClose();
          Notify.success('更新排班完成');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  const handleCsvUploadPreAction = () => {
    document.getElementById('j-csv-reader').click();
  };
  const handleCsvUpload = (data) => {
    setImportList(data || []);
  };
  const handleImport = () => {
    if (!Array.isArray(importList) || importList.length < 1) {
      Notify.warn('請先上傳檔案');
      return;
    }
    axios
      .post('/api/setting/schedule/updateGovCalendar', importList, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success(
            <div>
              <strong>更新政府行事曆完成</strong>
              <div>若需更新班表，</div>
              <div>
                請再點擊<span style={{ color: '#f00' }}>「轉入班表」</span>按鈕
              </div>
            </div>
          ); //'更新政府行事曆完成，若需更新班表，請再點擊「轉入班表」按鈕'
          handleImportModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  const handleSync = () => {
    //
    const validResult = ValidFormData(syncData, [
      { name: 'begin', type: 'date', required: true },
      { name: 'end', type: 'date', required: true },
    ]);
    if (!validResult.status) {
      setSyncData(validResult.data);
      return;
    }

    //
    const postData = {
      begin: DateFormat(syncData.begin),
      end: DateFormat(syncData.end),
    };
    axios
      .post('/api/setting/user/schedule/regenerate', postData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          loadUserSchedules();
          handleSyncModalClose();
          Notify.success('轉入班表完成');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  //================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>排班管理</Title>
            </Col>
            <Col>
              <DatePickerInline
                label="排班日期"
                allowClear={false}
                value={searchBeginDate}
                onChange={handleSearchBeginDateChange}
              />
            </Col>
            <Col>
              <SelectInline
                canSearch
                label="人員"
                options={userList}
                value={searchUserId}
                onChange={handleSearchUserIdChange}
                style={{ minWidth: '200px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle">
            <Col>
              <Button size="large" type="primary" icon={<SyncOutlined />} onClick={handleSyncModalOpen}>
                轉入班表
              </Button>
            </Col>
            <Col>
              <Button size="large" icon={<ImportOutlined />} onClick={handleImportModalOpen}>
                匯入政府行事曆
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(searchBeginDate, searchEndDate, handleModalOpen)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal width="600px" visible={modalOpen}>
        <Title>變更排班</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <Display label="員工名稱" value={scheduleData.userName} />
          </Col>
          <Col span={12}>
            <Display label="排班日期" value={scheduleData.date} />
          </Col>
          <Col span={12}>
            <Display label="原排班" value={ListFormat(ScheduleType, scheduleData.typeOld)} />
          </Col>
          <Col span={12}>
            <Select
              required
              label="新排班"
              options={ScheduleType}
              value={scheduleData.type}
              msg={scheduleData.type_msg}
              onChange={handleScheduleDataTypeChange}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleSave}>
              確認調整
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="1200px" visible={importModalOpen}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>匯入政府行事曆</Title>
          </Col>
          <Col>
            <Button size="large" icon={<UploadOutlined />} onClick={handleCsvUploadPreAction}>
              上傳
            </Button>
            <CSVReader
              inputId="j-csv-reader"
              onFileLoaded={handleCsvUpload}
              parserOptions={{ header: true, skipEmptyLines: true }}
              inputStyle={{ display: 'none' }}
            />
          </Col>
        </Row>
        <Divider />
        <Table columns={GenerateGovColumns} dataSource={importList} scroll={{ y: '500px' }} />
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" icon={<ImportOutlined />} onClick={handleImport}>
              確定匯入
            </Button>
          </Col>
          <Col>
            <Button onClick={handleImportModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="600px" visible={syncModalOpen}>
        <Title>轉入班表</Title>
        <Divider />
        <Row gutter={[20, 10]}>
          <Col span={24}>
            <strong style={{ color: '#D73141' }}>
              ※ 轉入動作僅會直接更新個人班表，不會檢核相關請假、加班等申請之合理性！
            </strong>
          </Col>
          <Col span={12}>
            <DatePicker
              required
              label="轉入開始日期"
              value={syncData.begin}
              msg={syncData.begin_msg}
              onChange={handleSyncDataChange('begin')}
            />
          </Col>
          <Col span={12}>
            <DatePicker
              required
              label="轉入結束日期"
              value={syncData.end}
              msg={syncData.end_msg}
              onChange={handleSyncDataChange('end')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" icon={<SyncOutlined />} onClick={handleSync}>
              確定轉入
            </Button>
          </Col>
          <Col>
            <Button onClick={handleSyncModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const ColumnButton = styled.div`
  width: 100%;
  height: 45px;
  color: #343a40;
  background-color: ${(p) => p.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 30px;

  :hover {
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
`;

export default SettingSchedule;
