import React from 'react';
import { Switch } from 'antd';
import JLayout from '../layout';

const JSwitch = ({ label, msg, required, ...props }) => {
    let inputStyle = { width: '100%' };
    if (msg) {
        inputStyle.border = '1px solid #f00';
        inputStyle.borderRadius = '4px';
    }

    return (
        <JLayout label={label} msg={msg} required={required}>
            <Switch style={inputStyle} {...props} />
        </JLayout>
    );
};

export default JSwitch;