import React, { useState, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import zhTwLocale from '@fullcalendar/core/locales/zh-tw';
//
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Button, Radio, Tooltip, Divider } from 'antd';
import { DateTimeFormat } from '../../modules/utils/format';

const JCalendar = ({ data, reloadFn }) => {
    const calRef = useRef(null);
    const [viewProps, setViewProps] = useState({
        type: 'month',
        title: moment().format('YYYY年MM月'),
    });
    const [tipProps, setTipProps] = useState({ visibleId: '', data: {} });

    //======================================================
    const handleTooltipOpen = (e) => {
        const obj = data.find((d) => d.id === e.event.id);
        if (obj) {
            setTipProps({ visibleId: obj.id, data: obj });
        }
    };
    const handleTooltipClose = () => {
        setTipProps({ visibleId: '', data: {} });
    };
    const handleResetViewProps = (type) => {
        if (calRef && calRef.current && calRef.current.getApi()) {
            const calendar = calRef.current.getApi();
            setViewProps({
                type: type || viewProps.type,
                title: calendar.view.title,
            });

            reloadFn &&
                reloadFn(
                    moment(calendar.view.currentStart).format('YYYY-MM-DD'),
                    moment(calendar.view.currentEnd).format('YYYY-MM-DD'),
                );
        }
    };

    //======================================================
    const handlePrev = () => {
        if (calRef && calRef.current && calRef.current.getApi()) {
            const calendar = calRef.current.getApi();
            calendar.prev();
            handleResetViewProps();
        }
    };
    const handleNext = () => {
        if (calRef && calRef.current && calRef.current.getApi()) {
            const calendar = calRef.current.getApi();
            calendar.next();
            handleResetViewProps();
        }
    };
    const handleViewTypeChange = (e) => {
        if (calRef && calRef.current && calRef.current.getApi()) {
            const calendar = calRef.current.getApi();
            switch (e.target.value) {
                case 'month':
                    calendar.changeView('dayGridMonth');
                    break;
                case 'week':
                    calendar.changeView('timeGridWeek');
                    break;
                case 'day':
                    calendar.changeView('timeGridDay');
                    break;
                default:
                    break;
            }
            handleResetViewProps(e.target.value);
        }
    };
    const handleJumpToToday = () => {
        if (calRef && calRef.current && calRef.current.getApi()) {
            const calendar = calRef.current.getApi();
            calendar.today();
            handleResetViewProps();
        }
    };

    //======================================================
    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col span={12}>
                    <Row gutter={[20, 0]}>
                        <Col>
                            <Button onClick={handleJumpToToday}>今天</Button>
                        </Col>
                        <Col>
                            <Button icon={<LeftOutlined />} onClick={handlePrev} style={{ marginRight: '8px' }} />
                            <Button icon={<RightOutlined />} onClick={handleNext} />
                        </Col>
                        <Col>
                            <div style={{ fontSize: '1.5em' }}>{viewProps.title}</div>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={[
                            { label: '月', value: 'month' },
                            { label: '週', value: 'week' },
                            { label: '天', value: 'day' },
                        ]}
                        value={viewProps.type}
                        onChange={handleViewTypeChange}
                    />
                </Col>
            </Row>
            <br />
            <FullCalendar
                ref={calRef}
                locale={zhTwLocale}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={false}
                height="calc(100vh - 100px)"
                eventTimeFormat={{
                    // like '14:30:00'
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                    meridiem: false,
                }}
                events={data || []}
                eventContent={(arg) => {
                    return (
                        <Tooltip
                            visible={tipProps.visibleId === arg.event.id}
                            placement="topRight"
                            title={
                                <TooltipDiv>
                                    <Row>
                                        <Col span={8}>請假人員</Col>
                                        <Col span={15}>{tipProps.data.userName}</Col>
                                    </Row>
                                    <ThinDivider />
                                    <Row>
                                        <Col span={8}>假別</Col>
                                        <Col span={15}>{tipProps.data.vacationName}</Col>
                                    </Row>
                                    <ThinDivider />
                                    <Row>
                                        <Col span={8}>請假時段</Col>
                                        <Col span={15}>
                                            {DateTimeFormat(tipProps.data.start)} ~ {DateTimeFormat(tipProps.data.end)}
                                        </Col>
                                    </Row>
                                    <ThinDivider />
                                    <Row>
                                        <Col span={8}>請假事由</Col>
                                        <Col span={15}>{tipProps.data.reason}</Col>
                                    </Row>
                                    <ThinDivider />
                                    <Row>
                                        <Col span={8}>代理人</Col>
                                        <Col span={15}>{tipProps.data.agentName}</Col>
                                    </Row>
                                </TooltipDiv>
                            }
                        >
                            <div style={{ padding: '0px 5px' }}>{arg.event.title}</div>
                        </Tooltip>
                    );
                }}
                eventClick={handleTooltipOpen}
                eventMouseEnter={handleTooltipOpen}
                eventMouseLeave={handleTooltipClose}
            />
        </div>
    );
};

const TooltipDiv = styled.div`
  width: 100%;
`;

const ThinDivider = styled(Divider)`
  border-color: #fff;
  opacity: 0.2;
  margin: 6px 0px;
`;

export default JCalendar;
