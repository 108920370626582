import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
//
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const SettingFeature = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [featureData, setFeatureData] = useState({});
  const [featureList, setFeatureList] = useState([]);
  //
  const viewList = useMemo(() => {
    return featureList.filter((m) => !keyword || m.featureName.match(keyword) || m.featureId.match(keyword));
  }, [featureList, keyword]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'sysFeature' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false)
            setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load features
  const loadFeatureList = useCallback(() => {
    axios
      .get('/api/setting/feature/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setFeatureList(res.data.result.features.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadFeatureList();
  }, [loadFeatureList]);

  //============================================================
  const handleModalOpen = (record) => () => {
    if (record) {
      setFeatureData(record);
    } else {
      setFeatureData({ ...record, isNew: true });
    }
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setFeatureData({});
    setModalOpen(false);
  };
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };
  const handleFeatureDataChange = (name) => (e) => {
    setFeatureData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleSave = () => {
    //valid
    const validResult = ValidFormData(featureData, [
      { name: 'featureId', type: 'input', required: true },
      { name: 'featureName', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setFeatureData(validResult.data);
      return;
    }

    //
    if (featureData.isNew) {
      if (featureList.find((m) => m.featureId === featureData.featureId)) {
        setFeatureData((prev) => ({
          ...prev,
          featureId: '',
          featureId_msg: '此代碼已被使用，請重新輸入',
        }));
        return;
      }
    }

    axios
      .post('/api/setting/feature/save', featureData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadFeatureList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = (featureId) => () => {
    axios
      .post('/api/setting/feature/delete', { featureId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除成功');
          loadFeatureList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>功能管理</Title>
            </Col>
            <Col>
              <InputInline
                label="關鍵字"
                placeholder="請輸入功能代碼或名稱"
                value={keyword}
                onChange={handleKeywordChange}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen(null)} icon={<PlusOutlined />}>
            新增功能
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal width="800px" visible={modalOpen}>
        <Row>
          <Col span={12}>
            {!featureData.isNew ? (
              <Display label="功能代碼" value={featureData.featureId} />
            ) : (
              <Input
                required
                label="功能代碼"
                value={featureData.featureId}
                msg={featureData.featureId_msg}
                onChange={handleFeatureDataChange('featureId')}
              />
            )}
          </Col>
          <Col span={12}>
            <Input
              required
              label="功能名稱"
              value={featureData.featureName}
              msg={featureData.featureName_msg}
              onChange={handleFeatureDataChange('featureName')}
            />
          </Col>
          <Col span={24}>
            <Textarea
              rows={3}
              label="備註"
              value={featureData.memo}
              onChange={handleFeatureDataChange('memo')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '功能代碼',
      dataIndex: 'featureId',
      key: 'featureId',
      width: 200,
    },
    {
      title: '功能名稱',
      dataIndex: 'featureName',
      key: 'featureName',
      width: 250,
    },
    {
      title: '備註',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={openFn(record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確認刪除此選單？"
              placement="topRight"
              okText="確定刪除"
              cancelText="取消"
              onConfirm={deleteFn(record.featureId)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

export default SettingFeature;
