import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
//
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import Calendar from './calendar';
import Col from '../../components/Col';
import Divider from '../../components/Divider';
import SelectInline from '../../components/Select/inline';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Title from '../../components/Title';
//
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

const LeaveCalendar = () => {
    const { user } = useProvided(useAuth);
    const [userList, setUserList] = useState([]);
    const [dateRangeData, setDateRangeData] = useState({
        begin: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD'),
        beginActive: moment().startOf('month').format('YYYY-MM-DD'),
        endActive: moment().endOf('month').format('YYYY-MM-DD'),
    });
    const [filterData, setFilterData] = useState({ userId: '', });
    const [vacationList, setVacationList] = useState([]);

    //load user list
    useEffect(() => {
        axios
            .get('/api/setting/user/list', { headers: { Authorization: user.token } })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    //增加"全部"選項
                    res.data.result.users.unshift({ userId: '', userName: '全部' });

                    setUserList(
                        res.data.result.users.map((u) => ({
                            label: u.userName,
                            value: u.userId,
                        }))
                    );
                } else {
                    Notify.warn(res.data.msg);
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.error(err);
            });
    }, [user]);

    //load vacation
    const loadVacationList = useCallback(() => {
        axios
            .post('/api/setting/calendar/search',
                {
                    userId: filterData.userId,
                    begin: dateRangeData.begin,
                    end: dateRangeData.end,
                },
                { headers: { Authorization: user.token } })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    setVacationList(res.data.result.vacations);
                }
            })
            .catch((err) => {
                console.error('get reserve list error:', err);
            });
    }, [user, filterData, dateRangeData]);
    useEffect(() => {
        loadVacationList();
    }, [loadVacationList]);

    //=======================================================
    const handleFilterDataChange = (name) => (val) => {
        setFilterData((prev) => ({ ...prev, [name]: val }));
    };
    const handleDateRangeDataChange = (begin, end) => {
        setDateRangeData({ begin, end });
    };

    //=======================================================
    const handleExportCsv = () => {
        let title = `${user.comName}股份有限公司_${dateRangeData.begin.substring(0, 4)}年${dateRangeData.begin.substring(5, 7)}月份請假紀錄`;
        axios
            .post('/api/setting/calendar/export',
                {
                    title: title,
                    begin: dateRangeData.begin, end: dateRangeData.end
                },
                { headers: { Authorization: user.token }, responseType: 'blob', }
            )
            .then((res) => {
                if (res && res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `${title}.xlsx`;
                    link.click();
                    window.URL.revokeObjectURL(url);
                } else {
                    Notify.warn('下載請假紀錄失敗');
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.error(err);
            });
    }

    //=======================================================
    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col>
                    <Title>行事曆</Title>
                </Col>
                <Col>
                    <Row align="middle" gutter={[40, 0]}>
                        <Col>
                            <SelectInline
                                canSearch
                                label="請假人"
                                options={userList}
                                value={filterData.userId}
                                onChange={handleFilterDataChange('userId')}
                                style={{ minWidth: '120px' }}
                            />
                        </Col>
                        <Col>
                            <Button size="large" icon={<SyncOutlined />} onClick={handleExportCsv}>
                                轉出該月請假紀錄
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider />
            <CardDiv>
                <CardInnerDiv>
                    <CardContent>
                        <Calendar data={vacationList} reloadFn={handleDateRangeDataChange} />
                    </CardContent>
                </CardInnerDiv>
            </CardDiv>
        </div>
    );
}

const CardDiv = styled.div`
  margin-top: 15px;
`;
const CardInnerDiv = styled.div`
  border-radius: 2px;
  background-color: rgba(210, 210, 210, 0.3);
  padding: 15px;
`;
const CardContent = styled.div`
  border-radius: 2px;
  background-color: #fff;
  padding: ${(p) => (p.dense ? '' : '15px')};
`;

export default LeaveCalendar;