import React from 'react';
import { DatePicker } from 'antd';
import JLayout from '../layout';

const JDatePicker = ({ label, msg, required, ...props }) => {
  let inputStyle = { width: '100%' };
  if (msg) {
    inputStyle.border = '1px solid #f00';
    inputStyle.borderRadius = '4px';
  }

  let hint = '';
  if (label) {
    hint = '請選擇' + label;
  }

  return (
    <JLayout label={label} msg={msg} required={required}>
      <DatePicker placeholder={hint} style={inputStyle} {...props} />
    </JLayout>
  );
};

export default JDatePicker;
