import React, { useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import useAuth from '../../modules/stores/useAuth';
import { useProvided } from '../../modules/stores';
import { ParseJwt } from '../../modules/utils/tool';
import axios from 'axios';

//
import Col from '../../components/Col';
import Divider from '../../components/Divider';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Title from '../../components/Title';
import { Button } from 'antd';
import keys from '../../configs/keys';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const TokenTimeout = () => {
  const [second, setSecond] = useState(0); //remaining seconds left before the user will be logout.
  const [modalOpen, setModalOpen] = useState(false);
  const [relogin, setRelogin] = useState(false);
  const { user, AuthUser, UnAuthUser } = useProvided(useAuth);
  const warningInactiveInterval = useRef(null);
  const tokenTimerInterval = useRef(null);

  const refreshToken = useCallback(() => {
    //自動換發token
    clearInterval(tokenTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    axios
      .post(`/api/setting/refreshToken`, null, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const newToken = res.data.result.token;
          if (newToken) {
            AuthUser(user, newToken);
            const decodedJwt = ParseJwt(newToken);
            if (decodedJwt && decodedJwt.exp) {
              window.sessionStorage.setItem(
                'tokenTimeStamp',
                moment(new Date(decodedJwt.exp * 1000)).format(TIME_FORMAT)
              );
            }
            console.log(`newToken get!`);
          }
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user, AuthUser]);

  const warningInactive = useCallback(
    (tokenTime) => {
      clearInterval(tokenTimerInterval.current);
      warningInactiveInterval.current = setInterval(() => {
        const diff = moment(tokenTime).diff(moment(), 'seconds');
        if (diff <= 0) {
          clearInterval(warningInactiveInterval.current);
          clearInterval(tokenTimerInterval.current);
          UnAuthUser();
          setModalOpen(false);
        } else if (diff <= 120) {
          //剩餘2分鐘時跳出提示
          setSecond(diff);
          setModalOpen(true);
        }
      }, 1000);
    },
    [UnAuthUser]
  );

  const setLastTimeStamp = useCallback(() => {
    let timeStamp = moment().format(TIME_FORMAT);
    sessionStorage.setItem('lastTimeStamp', timeStamp);
  }, []);

  const tokenTimeChecker = useCallback(() => {
    clearInterval(tokenTimerInterval.current);

    //每10秒檢查token時間是否到期
    tokenTimerInterval.current = setInterval(() => {
      const tokenTimeStamp = sessionStorage.getItem('tokenTimeStamp');
      const token = sessionStorage.getItem('token');
      if (token) {
        let unformatToken = token.replace('Bearer ', '');
        const decodedJwt = ParseJwt(unformatToken);
        if (decodedJwt) {
          const times = decodedJwt.refreshTimes;
          if (times === 2 && !relogin) {
            //第三次要重新登入
            setRelogin(true);
            return;
          }
        }
      } else {
        clearInterval(warningInactiveInterval.current);
        clearInterval(tokenTimerInterval.current);
        events.forEach((event) => {
          window.removeEventListener(event, setLastTimeStamp);
        });
        return;
      }

      if (tokenTimeStamp) {
        const maxTime = 5;
        const tokenDiff = moment(tokenTimeStamp).diff(moment(), 'seconds');
        //剩下5分鐘時進行檢核
        if (tokenDiff <= maxTime * 60) {
          const storedTimeStamp = sessionStorage.getItem('lastTimeStamp');

          const lastTime = moment(storedTimeStamp).add(keys.jwtExpiresIn, 'minutes').format(TIME_FORMAT);
          const logoutDiff = moment(lastTime).diff(moment(tokenTimeStamp), 'seconds');

          if (logoutDiff < 10 * 60 || relogin) {
            clearInterval(warningInactiveInterval.current);
            warningInactive(tokenTimeStamp);
          } else {
            refreshToken();
            tokenTimeChecker();
          }
        }
      }
    }, 10000);
  }, [relogin, refreshToken, warningInactive, setLastTimeStamp]);

  const handleModalClose = () => {
    clearInterval(warningInactiveInterval.current);
    clearInterval(tokenTimerInterval.current);
    if (relogin) {
      events.forEach((event) => {
        window.removeEventListener(event, setLastTimeStamp);
      });
      UnAuthUser();
    } else {
      refreshToken();
      tokenTimeChecker();
    }
    setModalOpen(false);
  };

  //============================================================
  useEffect(() => {
    if (user && user.token) {
      events.forEach((event) => {
        window.addEventListener(event, setLastTimeStamp);
      });
      if (!modalOpen) {
        tokenTimeChecker();
      }
    } else {
      clearInterval(warningInactiveInterval.current);
      clearInterval(tokenTimerInterval.current);
      events.forEach((event) => {
        window.removeEventListener(event, setLastTimeStamp);
      });
    }
  }, [user, modalOpen, setLastTimeStamp, tokenTimeChecker]);

  //============================================================
  return (
    <div>
      <Modal width="600px" visible={modalOpen}>
        <Title>自動登出提醒</Title>
        <Divider />
        <Row>
          <Col>※ 閒置時間過長，系統將自動登出</Col>
        </Row>
        <Row justify="end" align="middle">
          <Col>
            <strong style={{ color: '#D73141', fontSize: '30px' }}>剩餘時間： {second}秒</strong>
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type={relogin ? 'danger' : 'primary'} onClick={handleModalClose}>
              {relogin ? '重新登入' : '保持登入'}
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default TokenTimeout;
