import React from 'react';
//
import Col from '../../components/Col';
import Row from '../../components/Row';
//
import Todo from './todo';

const Dashboard = () => {
  return (
    <Row>
      <Col span={24}>
        <Todo />
      </Col>
    </Row>
  );
};

export default Dashboard;
