import React, { useEffect, useState } from 'react';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Password from '../../components/Password';
import Row from '../../components/Row';
import Title from '../../components/Title';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
//
import { DateFormat, ListFormat } from '../../modules/utils/format';
import { UserGender } from '../../modules/utils/list';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

const SettingUser = () => {
  const { user } = useProvided(useAuth);
  //
  const [modifyPwdModalOpen, setModifyPwdModalOpen] = useState(false);
  const [modifyPwdData, setModifyPwdData] = useState({});
  //
  const [userData, setUserData] = useState({});

  //============================================================
  //load user data.
  useEffect(() => {
    axios
      .get(`/api/setting/user/info?id=${user.userId}`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserData(res.data.result.userInfo);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //============================================================
  const handleUserDataChange = (name) => (e) => {
    setUserData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleUserInfoUpdate = () => {
    //
    const saveData = {
      phone: userData.phone,
    };
    //
    axios
      .post('/api/setting/user/updateInfo', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('更新成功');
        } else {
          Notify.warn('更新失敗：' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };
  const handleModifyPwdModalOpen = () => {
    setModifyPwdModalOpen(true);
  };
  const handleModifyPwdModalClose = () => {
    setModifyPwdData({});
    setModifyPwdModalOpen(false);
  };
  const handleModifyPwdDataChange = (name) => (e) => {
    setModifyPwdData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleModifyPwd = () => {
    //
    const validResult = ValidFormData(modifyPwdData, [
      { name: 'oldPwd', type: 'input', required: true },
      { name: 'newPwd', type: 'input', required: true },
      { name: 'confirmPwd', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setModifyPwdData(validResult.data);
      return;
    }
    //
    if (modifyPwdData.newPwd !== modifyPwdData.confirmPwd) {
      setModifyPwdData((prev) => ({
        ...prev,
        newPwd: null,
        confirmPwd: null,
        confirmPwd_msg: '輸入密碼與新密碼不符',
      }));
      return;
    }

    //
    axios
      .post(
        '/api/setting/user/modifyPwd',
        { oldPwd: modifyPwdData.oldPwd, newPwd: modifyPwdData.newPwd },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('變更成功，下次登入時請使用新密碼登入');
          handleModifyPwdModalClose();
        } else {
          Notify.warn('變更失敗：' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  //============================================================
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>個人資料管理</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              <Button size="large" onClick={handleModifyPwdModalOpen}>
                修改密碼
              </Button>
            </Col>
            <Col>
              <Button size="large" type="primary" onClick={handleUserInfoUpdate}>
                更新資料
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Display label="員工識別碼" value={userData.userId || '自動產生'} />
        </Col>
        <Col span={16}>
          <Display label="備註" value={userData.memo} />
        </Col>
        <Col span={8}>
          <Display label="帳號" value={userData.email} />
        </Col>
        <Col span={8}>
          <Display label="員工姓名" value={userData.userName} />
        </Col>
        <Col span={8}>
          <Display label="員工姓名(英文)" value={userData.userNameEn} />
        </Col>
        <Col span={8}>
          <Display label="部門" value={userData.deptName} />
        </Col>
        <Col span={8}>
          <Display label="職稱" value={userData.jobTitle} />
        </Col>
        <Col span={8}>
          <Display label="職稱(英文)" value={userData.jobTitleEn} />
        </Col>
        <Col span={8}>
          <Display label="到職日" value={DateFormat(userData.workOn)} />
        </Col>
        <Col span={8}>
          <Display label="離職日" value={DateFormat(userData.workOff)} />
        </Col>
        <Col span={8}>
          <Display label="年資調整" value={userData.workAdjust} />
        </Col>
        <Col span={8}>
          <Display label="性別" value={ListFormat(UserGender, userData.gender)} />
        </Col>
        <Col span={8}>
          <Display label="身分證字號" value={userData.ssn} />
        </Col>
        <Col span={8} />
        <Col span={8}>
          <Input label="聯絡電話" value={userData.phone} onChange={handleUserDataChange('phone')} />
        </Col>
      </Row>
      <Modal width="600px" visible={modifyPwdModalOpen}>
        <Title>變更密碼</Title>
        <Divider />
        <Row>
          <Col span={24}>
            <Password
              required
              label="舊密碼"
              value={modifyPwdData.oldPwd}
              msg={modifyPwdData.oldPwd_msg}
              onChange={handleModifyPwdDataChange('oldPwd')}
            />
          </Col>
          <Col span={24}>
            <Password
              required
              label="新密碼"
              value={modifyPwdData.newPwd}
              msg={modifyPwdData.newPwd_msg}
              onChange={handleModifyPwdDataChange('newPwd')}
            />
          </Col>
          <Col span={24}>
            <Password
              required
              label="再次輸入新密碼"
              value={modifyPwdData.confirmPwd}
              msg={modifyPwdData.confirmPwd_msg}
              onChange={handleModifyPwdDataChange('confirmPwd')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleModifyPwd} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModifyPwdModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SettingUser;
