import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import JLayout from '../layout';

const JCheckboxGroup = ({ label, options, msg, required, colSpan = 8, ...props }) => {
  let inputStyle = { width: '100%' };
  if (msg) {
    inputStyle.border = '1px solid #f00';
    inputStyle.borderRadius = '4px';
  }

  return (
    <JLayout label={label} msg={msg} required={required}>
      <Checkbox.Group style={inputStyle} {...props}>
        <Row>
          {Array.isArray(options)
            ? options.map((o, i) => (
                <Col key={i} span={colSpan}>
                  <Checkbox value={o.value}>{o.label}</Checkbox>
                </Col>
              ))
            : null}
        </Row>
      </Checkbox.Group>
    </JLayout>
  );
};

export default JCheckboxGroup;
