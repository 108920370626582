import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
//
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const SettingMenu = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [menuData, setMenuData] = useState({});
  const [menuList, setMenuList] = useState([]);
  //
  const viewList = useMemo(() => {
    return menuList.filter((m) => !keyword || m.menuName.match(keyword) || m.menuId.match(keyword));
  }, [menuList, keyword]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'sysMenu' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false)
            setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load menus
  const loadMenuList = useCallback(() => {
    axios
      .get('/api/setting/menu/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setMenuList(res.data.result.menus.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadMenuList();
  }, [loadMenuList]);

  //============================================================
  const handleModalOpen = (record) => () => {
    if (record) {
      setMenuData(record);
    } else {
      setMenuData({ ...record, isNew: true });
    }

    setModalOpen(true);
  };
  const handleModalClose = () => {
    setMenuData({});
    setModalOpen(false);
  };
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };
  const handleMenuDataChange = (name) => (e) => {
    setMenuData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleSave = () => {
    //valid
    const validResult = ValidFormData(menuData, [
      { name: 'menuId', type: 'input', required: true },
      { name: 'menuName', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setMenuData(validResult.data);
      return;
    }

    //
    if (menuData.isNew) {
      if (menuList.find((m) => m.menuId === menuData.menuId)) {
        setMenuData((prev) => ({
          ...prev,
          menuId: '',
          menuId_msg: '此代碼已被使用，請重新輸入',
        }));
        return;
      }
    }

    axios
      .post('/api/setting/menu/save', menuData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadMenuList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = (menuId) => () => {
    axios
      .post('/api/setting/menu/delete', { menuId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除成功');
          loadMenuList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>選單管理</Title>
            </Col>
            <Col>
              <InputInline
                label="關鍵字"
                placeholder="請輸入選單代碼或名稱"
                value={keyword}
                onChange={handleKeywordChange}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen(null)} icon={<PlusOutlined />}>
            新增選單
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal width="800px" visible={modalOpen}>
        <Row>
          <Col span={12}>
            {!menuData.isNew ? (
              <Display label="選單代碼" value={menuData.menuId} />
            ) : (
              <Input
                required
                label="選單代碼"
                value={menuData.menuId}
                msg={menuData.menuId_msg}
                onChange={handleMenuDataChange('menuId')}
              />
            )}
          </Col>
          <Col span={12}>
            <Input
              required
              label="選單名稱"
              value={menuData.menuName}
              msg={menuData.menuName_msg}
              onChange={handleMenuDataChange('menuName')}
            />
          </Col>
          <Col span={24}>
            <Textarea rows={3} label="備註" value={menuData.memo} onChange={handleMenuDataChange('memo')} />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '選單代碼',
      dataIndex: 'menuId',
      key: 'menuId',
      width: 200,
    },
    {
      title: '選單名稱',
      dataIndex: 'menuName',
      key: 'menuName',
      width: 250,
    },
    {
      title: '備註',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={openFn(record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確認刪除此選單？"
              placement="topRight"
              okText="確定刪除"
              cancelText="取消"
              onConfirm={deleteFn(record.menuId)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

export default SettingMenu;
