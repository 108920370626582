import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Switch from '../../components/Switch';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
//
import { DateFormat } from '../../modules/utils/format';
import { UserStatus, UserGender } from '../../modules/utils/list';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const SettingEmployee = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [filterData, setFilterData] = useState({ status: 'Y', keyword: '' });
  const [userData, setUserData] = useState({ attendance: true });
  const [userList, setUserList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  //
  const viewList = useMemo(() => {
    return userList.filter(
      (u) =>
        (filterData.status === '' || u.status === filterData.status) &&
        (filterData.keyword === '' || u.userName.match(filterData.keyword))
    );
  }, [userList, filterData]);
  const isNewData = useMemo(() => {
    return modalProps.type === 'add';
  }, [modalProps.type]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrEmployee' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load depts and roles list.
  useEffect(() => {
    //departments
    axios
      .get(`/api/setting/dept/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setDeptList(
            res.data.result.depts.map((c) => {
              return {
                label: c.deptName,
                value: c.deptId,
              };
            })
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });

    //roles
    axios
      .get(`/api/setting/role/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setRoleList(
            res.data.result.roles.map((c) => {
              return {
                label: c.roleName,
                value: c.roleId,
              };
            })
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load users
  const loadUserList = useCallback(() => {
    axios
      .get('/api/setting/user/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList(res.data.result.users.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadUserList();
  }, [loadUserList]);

  //load one user data
  const loadUser = (userId) => {
    if (userId) {
      axios
        .get(`/api/setting/user/info?id=${userId}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const info = res.data.result.userInfo;
            info.workOn = info.workOn && moment(info.workOn);
            info.workOff = info.workOff && moment(info.workOff);
            setUserData(info);
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //============================================================
  const handleModalOpen = (type, userId) => () => {
    if (userId) {
      loadUser(userId);
    }
    setModalProps({ open: true, type: type });
  };
  const handleModalClose = () => {
    setUserData({ attendance: true });
    setModalProps({ open: false, type: '' });
  };
  const handleFilterDataChange = (name) => (e) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
    }));
  };
  const handleUserDataChange = (name) => (e) => {
    setUserData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };

  const handleSave = () => {
    //valid
    const validResult = ValidFormData(userData, [
      { name: 'email', type: 'input', required: true },
      { name: 'deptId', type: 'select', required: true },
      { name: 'roleId', type: 'select', required: userData.attendance },
      { name: 'gender', type: 'select', required: true },
      { name: 'userName', type: 'input', required: true },
      { name: 'workOn', type: 'date', required: userData.attendance },
      { name: 'workAdjust', type: 'number', required: false },
    ]);

    if (!validResult.status) {
      setUserData(validResult.data);
      return;
    }

    //
    const saveData = { ...userData };
    saveData.workOn = DateFormat(saveData.workOn);
    saveData.workOff = DateFormat(saveData.workOff);
    saveData.workAdjust = saveData.workAdjust || 0;

    axios
      .post('/api/setting/user/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadUserList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = (userId) => () => {
    if (userId === user.userId) {
      Notify.warn('您不可作廢自己');
      return;
    }
    axios
      .post('/api/setting/user/delete', { userId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('作廢完成');
          loadUserList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col lg={20}>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>員工管理</Title>
            </Col>
            <Col>
              <SelectInline
                label="人員狀態"
                options={UserStatus}
                value={filterData.status}
                onChange={handleFilterDataChange('status')}
                style={{ minWidth: '200px' }}
              />
            </Col>
            <Col lg={12}>
              <InputInline
                label="關鍵字"
                placeholder="請輸入人員姓名"
                value={filterData.keyword}
                onChange={handleFilterDataChange('keyword')}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen('add', null)} icon={<PlusOutlined />}>
            新增員工
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ x: 800, y: 'calc(100vh - 135px)' }}
      />
      <Modal width="800px" visible={modalProps.open}>
        <Row>
          <Col span={12}>
            <Display label="員工識別碼" value={userData.userId || '自動產生'} />
          </Col>
          <Col span={12}>
            {isNewData ? (
              <Input
                required
                label="帳號"
                placeholder="請輸入帳號 (信箱)"
                value={userData.email}
                msg={userData.email_msg}
                onChange={handleUserDataChange('email')}
              />
            ) : (
              <Display label="帳號" value={userData.email} />
            )}
          </Col>
          <Col span={12}>
            <Select
              required
              canSearch
              label="部門"
              options={deptList}
              value={userData.deptId}
              msg={userData.deptId_msg}
              onChange={handleUserDataChange('deptId')}
            />
          </Col>
          <Col span={12}>
            <Select
              required={userData.attendance}
              label="角色"
              mode="multiple"
              options={roleList}
              value={userData.roleId || []}
              msg={userData.roleId_msg}
              onChange={handleUserDataChange('roleId')}
            />
          </Col>
          <Col span={12}>
            <Input
              required
              label="員工姓名"
              value={userData.userName}
              msg={userData.userName_msg}
              onChange={handleUserDataChange('userName')}
            />
          </Col>
          <Col span={12}>
            <Input
              label="員工姓名(英文)"
              value={userData.userNameEn}
              onChange={handleUserDataChange('userNameEn')}
            />
          </Col>
          <Col span={8}>
            <DatePicker
              required={userData.attendance}
              label="到職日"
              value={userData.workOn}
              msg={userData.workOn_msg}
              onChange={handleUserDataChange('workOn')}
            />
          </Col>
          <Col span={8}>
            <DatePicker label="離職日" value={userData.workOff} onChange={handleUserDataChange('workOff')} />
          </Col>
          <Col span={8}>
            <Input
              label="年資調整"
              value={userData.workAdjust}
              msg={userData.workAdjust_msg}
              onChange={handleUserDataChange('workAdjust')}
            />
          </Col>
          <Col span={8}>
            <Select
              required
              label="性別"
              options={UserGender}
              value={userData.gender}
              msg={userData.gender_msg}
              onChange={handleUserDataChange('gender')}
            />
          </Col>
          <Col span={8}>
            <Input label="身分證字號" value={userData.ssn} onChange={handleUserDataChange('ssn')} />
          </Col>
          <Col span={8}>
            <Input label="聯絡電話" value={userData.phone} onChange={handleUserDataChange('phone')} />
          </Col>
          <Col span={8}>
            <Input label="職稱" value={userData.jobTitle} onChange={handleUserDataChange('jobTitle')} />
          </Col>
          <Col span={8}>
            <Input
              label="職稱(英文)"
              value={userData.jobTitleEn}
              onChange={handleUserDataChange('jobTitleEn')}
            />
          </Col>
          <Col span={8}>
            <Switch
              label="產生差勤資料"
              style={{ minWidth: '50px' }}
              checkedChildren="是"
              unCheckedChildren="否"
              checked={userData.attendance}
              onChange={handleUserDataChange('attendance')}
            />
          </Col>
          <Col span={24}>
            <Textarea label="備註" rows={5} value={userData.memo} onChange={handleUserDataChange('memo')} />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '員工姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 200,
      render: (value, record) => {
        return (
          <div>
            <div style={{ fontSize: '12px' }}>{record.jobTitle}</div>
            <div style={{ fontSize: '16px' }}>
              <strong>{record.userName}</strong>
            </div>
            <div>{record.userNameEn}</div>
          </div>
        );
      },
    },
    {
      title: '帳號',
      dataIndex: 'email',
      key: 'email',
      width: 250,
    },
    {
      title: '電話',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
    {
      title: '到職日',
      dataIndex: 'workOn',
      key: 'workOn',
      width: 200,
      render: (value) => DateFormat(value),
    },
    {
      title: '備註',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => {
        if (record.status === 'Y') {
          return (
            <Row justify="center">
              <Col>
                <Tooltip title="編輯">
                  <Button icon={<EditOutlined />} onClick={openFn('edit', record.userId)} />
                </Tooltip>
              </Col>
              <Col>
                <Popconfirm
                  title="是否確認作廢此使用者？"
                  placement="topRight"
                  okText="確定"
                  cancelText="取消"
                  onConfirm={deleteFn(record.userId)}
                >
                  <Tooltip title="作廢">
                    <Button icon={<DeleteOutlined />} />
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
          );
        } else {
          return null;
        }
      },
    },
  ];
};

export default SettingEmployee;
