import React from 'react';
import { Modal } from 'antd';

const JModal = (props) => {
  if (props.visible) {
    return (
      <Modal footer={false} closable={false} style={{ top: '20px' }} {...props}>
        {props.children}
      </Modal>
    );
  } else {
    return null;
  }
};

export default JModal;
