import JLayout from '../layout';

const JDisplay = ({ label, value, msg }) => {
  return (
    <JLayout label={label} msg={msg} showBg={true}>
      {Array.isArray(value) ?
        (
          <div style={{ marginLeft: '8px' }}>
            {value.map((v, vIdx) => {
              return <div key={vIdx}>{v}</div>;
            })}
          </div>
        ) :
        (
          <div style={{ marginLeft: '8px' }}>{value}</div>
        )
      }
    </JLayout>);
};

export default JDisplay;
