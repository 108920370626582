import moment from 'moment-timezone';
import keys from '../../configs/keys';

export const IsNullOrUndefined = (val) => {
  if (val === null) return true;
  if (val === undefined) return true;
  return false;
};

export const IsNumber = (val) => {
  if (IsNullOrUndefined(val)) return false;
  if (val === '') return false;
  if (isNaN(Number(val))) return false;
  return true;
};

export const IsEmail = (email) => {
  if (IsNullOrUndefined(email)) return false;
  if (email === '') return false;

  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return re.test(String(email).toLowerCase());
};

const MSG_EMPTY = '欄位未填寫';
const MSG_NOT_NUMBER = '欄位需輸入數字';
const MSG_DATE_FORMAT = '日期格式不正確';
// const MSG_DATE_AFTER_NOW = '日期不可超過今日';
// const MSG_DATETIME_BEFORE_NOW = '時間不可早於現在';
export const ValidFormData = (data, checkSchema) => {
  const result = { status: true, msg: '', data: null };

  if (data) {
    result.data = { ...data };
    if (checkSchema) {
      if (Array.isArray(checkSchema)) {
        let c = null;
        let value = null;
        for (let i = 0; i < checkSchema.length; i++) {
          c = checkSchema[i];
          value = result.data[c.name];

          switch (c.type) {
            case 'input':
              if (c.required === true) {
                if (IsNullOrUndefined(value) || value === '') {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_EMPTY;
                }
              }
              break;
            case 'select':
            case 'radio':
            case 'checkboxgroup':
              if (c.required === true) {
                if (IsNullOrUndefined(value) || value === '' || (Array.isArray(value) && value.length <= 0)) {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_EMPTY;
                }
              }
              break;
            case 'number':
              if (c.required === true) {
                if (IsNullOrUndefined(value) || value === '') {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_EMPTY;
                }
              }

              if (value && !IsNumber(value)) {
                result.status = false;
                result.data[c.name] = null;
                result.data[c.name + '_msg'] = MSG_NOT_NUMBER;
              }
              break;
            case 'date':
            case 'datetime':
              if (c.required === true) {
                if (IsNullOrUndefined(value)) {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_EMPTY;
                }
              }

              if (!IsNullOrUndefined(value)) {
                moment.tz.setDefault(keys.timeZone);
                const m = moment.isMoment(value) ? value : moment(value);
                if (!m.isValid()) {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_DATE_FORMAT;
                }
              }
              break;
            default:
              break;
          }
        }
      } else {
        result.status = false;
        result.msg = '傳入的驗證綱要格式不正確';
      }
    }
  } else {
    result.data = {};
  }

  return result;
};
