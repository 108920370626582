import styled from 'styled-components';
import Col from './Col';
import Row from './Row';

const JLayout = ({ label, msg, required, ...props }) => {
  return (
    <Div>
      <Row align="middle">
        <Col>
          <LabelDiv required={required}>{label}</LabelDiv>
        </Col>
        <Col>
          <ValueDiv showBg={props.showBg}>{props.children}</ValueDiv>
        </Col>
      </Row>
      <MsgDiv>{msg || ''}</MsgDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
`;
const LabelDiv = styled.div`
  font-size: 16px;

  ${(p) =>
    p.required
      ? `
        font-weight: bold;
        ::after {
            content: "*";
            color: #FF9F9F;
            margin-left: 8px;
        }
        `
      : ''};
`;
const ValueDiv = styled.div`
  width: 100%;
  min-height: 30px;
  ${(p) => (p.showBg ? 'background-color: #F7F7F7' : '')};
`;
const MsgDiv = styled.div`
  text-align: right;
  font-size: 12px;
  color: #f00;
`;

export default JLayout;
