import React, { useMemo, forwardRef } from 'react';
import { Select } from 'antd';
import JLayout from '../layout';

const { Option } = Select;
const JSelect = forwardRef(({ canSearch, label, options, msg, required, ...props }, ref) => {
  let inputStyle = { width: '100%' };
  if (msg) {
    inputStyle.border = '1px solid #f00';
    inputStyle.borderRadius = '4px';
  }

  let hint = '';
  if (label) {
    hint = '請選擇' + label;
  }

  const searchProps = useMemo(() => {
    if (canSearch === true) {
      return {
        showSearch: true,
        optionFilterProp: 'children',
        filterOption: (input, option) => {
          return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
      };
    } else {
      return {};
    }
  }, [canSearch]);

  return (
    <JLayout label={label} msg={msg} required={required}>
      <Select {...searchProps} placeholder={hint} style={inputStyle} {...props} ref={ref}>
        {Array.isArray(options)
          ? options.map((o, i) => (
              <Option key={i} value={o.value}>
                {o.label}
              </Option>
            ))
          : null}
      </Select>
    </JLayout>
  );
});

export default JSelect;
