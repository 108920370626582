export const FormStatus = [
  { label: '不限', value: '' },
  { label: '審核中', value: 'S' },
  { label: '退回', value: 'R' },
  { label: '審核完成', value: 'Z' },
  { label: '作廢', value: 'X' },
];
export const UserGender = [
  { label: '男', value: 'M' },
  { label: '女', value: 'F' },
];
export const UserStatus = [
  { label: '不限', value: '' },
  { label: '正常', value: 'Y' },
  { label: '停用', value: 'N' },
];
export const UserVacationRefType = [
  { label: '排程轉入', value: '0' },
  { label: '加班單轉入', value: '1' },
  { label: '請假單轉入', value: '2' },
  { label: '手動增修', value: '3' },
];
export const SalaryType = [
  { label: '一般員工', value: '0' },
  { label: '雇主', value: '1' },
  { label: '顧問', value: '2' },
  { label: '部分工時', value: '3' },
];
export const ScheduleType = [
  { label: '正常班', value: '3' },
  { label: '國定假日', value: '2' },
  { label: '休假日', value: '1' },
  { label: '例假日', value: '0' },
  { label: '颱風假', value: '4' },
  { label: '其他假日', value: '5' },
];
export const SealKind = [
  { label: '公司公章(大章)', value: '1' },
  { label: '法定代表人章(小章)', value: '2' },
  { label: '銀行專用章', value: '3' },
  { label: '發票章', value: '4' },
  { label: '其他', value: 'O' },
];
export const SealType = [
  { label: '合約用印', value: '1' },
  { label: '其他', value: 'O' },
];
export const VacationGender = [
  { label: '通用', value: 'A' },
  { label: '男', value: 'M' },
  { label: '女', value: 'F' },
];
export const VacationSalary = [
  { label: '全薪', value: 'F' },
  { label: '半薪', value: 'H' },
  { label: '無薪', value: 'Z' },
];
export const VacationProof = [
  { label: '需要證明', value: 'Y' },
  { label: '不需要證明', value: 'N' },
];
export const TrelloStatus = [
  { label: '不限', value: '' },
  { label: '完成', value: 'F' },
  { label: '未完成', value: 'U' },
];
