import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
//
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import Col from '../../components/Col';
import DatePickerInline from '../../components/DatePicker/inline';
import Divider from '../../components/Divider';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import RadioInline from '../../components/Radio/inline';
import Row from '../../components/Row';
import SubTitle from '../../components/SubTitle';
import Switch from '../../components/Switch';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { Popconfirm } from 'antd';
//
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { DateFormat } from '../../modules/utils/format';
import { TrelloStatus } from '../../modules/utils/list';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

const SettingTrello = () => {
  const { user } = useProvided(useAuth);
  const [modalOpen, setModalOpen] = useState(false);
  const [listEdit, SetListEdit] = useState(false);
  const [labelEdit, SetLabelEdit] = useState(false);
  const [customFieldEdit, SetCustomFieldEdit] = useState(false);
  const [listList, setListList] = useState([]);
  const [labelList, setLabelList] = useState([]);
  const [customFieldList, setCustomFieldList] = useState([]);
  const [filterData, setFilterData] = useState({ completeness: '' });
  const [loading, setLoading] = useState(false);

  //============================================================
  //load list
  const loadListList = useCallback(() => {
    axios
      .get('/api/trello/trelloList', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setListList(res.data.result.list.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadListList();
  }, [loadListList]);

  //load labels
  const loadLabelList = useCallback(() => {
    axios
      .get('/api/trello/trelloLabel', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setLabelList(res.data.result.labels.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadLabelList();
  }, [loadLabelList]);

  //load customField
  const loadCustomFieldList = useCallback(() => {
    axios
      .get('/api/trello/trelloCustomField', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setCustomFieldList(res.data.result.customFields.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadCustomFieldList();
  }, [loadCustomFieldList]);

  //============================================================
  const handleListEdit = () => {
    if (!listEdit) {
      SetListEdit(true);
    } else {
      axios
        .post('/api/trello/trelloList/update', { list: listList }, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            loadListList();
            SetListEdit(false);
            Notify.success('儲存成功');
          } else {
            Notify.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });
    }
  };
  const handleLabelEdit = () => {
    if (!labelEdit) {
      SetLabelEdit(true);
    } else {
      axios
        .post(
          '/api/trello/trelloLabel/update',
          { labels: labelList },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            loadLabelList();
            SetLabelEdit(false);
            Notify.success('儲存成功');
          } else {
            Notify.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });
    }
  };
  const handleCustomFieldEdit = () => {
    if (!customFieldEdit) {
      SetCustomFieldEdit(true);
    } else {
      axios
        .post(
          '/api/trello/trelloCustomField/update',
          { customFields: customFieldList },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            loadCustomFieldList();
            SetCustomFieldEdit(false);
            Notify.success('儲存成功');
          } else {
            Notify.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });
    }
  };
  const handleListDataChange = (listId) => (e) => {
    const list = listList.find((l) => l.listId === listId);

    const tmpList = listList.filter((l) => l.listId !== listId);
    tmpList.push({
      ...list,
      export: e && e.target ? e.target.value : e,
    });
    tmpList.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      } else {
        return 1;
      }
    });
    setListList(tmpList.map(SetKeyIntoArray));
  };
  const handleLabelDataChange = (labelId) => (e) => {
    const label = labelList.find((l) => l.labelId === labelId);

    const tmpList = labelList.filter((l) => l.labelId !== labelId);
    tmpList.push({
      ...label,
      export: e && e.target ? e.target.value : e,
    });
    tmpList.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      } else {
        return 1;
      }
    });
    setLabelList(tmpList.map(SetKeyIntoArray));
  };
  const handleCustomFieldDataChange = (customFieldId) => (e) => {
    const customField = customFieldList.find((cf) => cf.customFieldId === customFieldId);
    const tmpList = customFieldList.filter((cf) => cf.customFieldId !== customFieldId);
    tmpList.push({
      ...customField,
      export: e && e.target ? e.target.value : e,
    });
    tmpList.sort((a, b) => {
      if (a.seq < b.seq) {
        return -1;
      } else {
        return 1;
      }
    });
    setCustomFieldList(tmpList.map(SetKeyIntoArray));
  };

  //=======================================================
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setFilterData({});
    setModalOpen(false);
  };
  const handleFilterDataChange = (name) => (e) => {
    setFilterData((prev) => ({ ...prev, [name]: e && e.target ? e.target.value : e, [name + '_msg']: '' }));
  };
  const handleExportCsv = () => {
    let title = `${moment().format('YYYY')}${user.comName}技術委託服務_專案開發進度表`;
    axios
      .post(
        '/api/trello/export',
        {
          estimatedSdate_begin: DateFormat(filterData.estimatedSdate_begin),
          estimatedSdate_end: DateFormat(filterData.estimatedSdate_end),
          estimatedEdate_begin: DateFormat(filterData.estimatedEdate_begin),
          estimatedEdate_end: DateFormat(filterData.estimatedEdate_end),
          actualSdate_begin: DateFormat(filterData.actualSdate_begin),
          actualSdate_end: DateFormat(filterData.actualSdate_end),
          actualEdate_begin: DateFormat(filterData.actualEdate_begin),
          actualEdate_end: DateFormat(filterData.actualEdate_end),
          completeness: filterData.completeness,
        },
        {
          headers: { Authorization: user.token },
          responseType: 'blob',
        }
      )
      .then((res) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${title}.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          Notify.warn('下載專案進度失敗');
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleSync = () => {
    setLoading(true);
    axios
      .get('/api/trello/sync', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('同步完成');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      })
      .then(() => {
        setLoading(false);
      });
  };

  //=======================================================
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>Trello管理</Title>
        </Col>
        <Col>
          <Row align="middle">
            <Col>
              <Button
                size="large"
                type="primary"
                icon={<SyncOutlined />}
                disabled={listEdit || labelEdit || customFieldEdit || loading}
                onClick={handleModalOpen}
              >
                下載專案進度
              </Button>
            </Col>
            <Col>
              <Popconfirm
                title="是否確定同步？"
                placement="topRight"
                okText="確認"
                cancelText="取消"
                onConfirm={handleSync}
              >
                <Button
                  danger
                  size="large"
                  icon={<SyncOutlined />}
                  disabled={listEdit || labelEdit || customFieldEdit}
                  loading={loading}
                >
                  即時同步
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              <Button
                size="large"
                onClick={handleListEdit}
                disabled={labelEdit || customFieldEdit || loading}
              >
                {listEdit ? '儲存列表' : '編輯列表'}
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                onClick={handleLabelEdit}
                disabled={listEdit || customFieldEdit || loading}
              >
                {labelEdit ? '儲存標籤' : '編輯標籤'}
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                onClick={handleCustomFieldEdit}
                disabled={listEdit || labelEdit || loading}
              >
                {customFieldEdit ? '儲存自訂欄位' : '編輯自訂欄位'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <SubTitle>Trello列表</SubTitle>
        </Col>
        <Col span={8}>
          <SubTitle>Trello標籤</SubTitle>
        </Col>
        <Col span={8}>
          <SubTitle>Trello自訂欄位</SubTitle>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Table
            columns={GenerateListColumns(handleListDataChange, listEdit)}
            dataSource={listList}
            scroll={{ y: 'calc(100vh - 135px)' }}
          />
        </Col>
        <Col span={8}>
          <Table
            columns={GenerateLabelColumns(handleLabelDataChange, labelEdit)}
            dataSource={labelList}
            scroll={{ y: 'calc(100vh - 135px)' }}
          />
        </Col>
        <Col span={8}>
          <Table
            columns={GenerateCustomFieldColumns(handleCustomFieldDataChange, customFieldEdit)}
            dataSource={customFieldList}
            scroll={{ y: 'calc(100vh - 135px)' }}
          />
        </Col>
      </Row>
      <Modal width="800px" visible={modalOpen}>
        <Title>下載專案進度</Title>
        <Divider />
        <Row align="middle" gutter={[16, 16]}>
          <Col span={12}>
            <DatePickerInline
              label="預計開工日(起)"
              value={filterData.estimatedSdate_begin}
              msg={filterData.estimatedSdate_begin_msg}
              onChange={handleFilterDataChange('estimatedSdate_begin')}
            />
          </Col>
          <Col span={12}>
            <DatePickerInline
              label="預計開工日(迄)"
              value={filterData.estimatedSdate_end}
              msg={filterData.estimatedSdate_end_msg}
              onChange={handleFilterDataChange('estimatedSdate_end')}
            />
          </Col>
          <Col span={12}>
            <DatePickerInline
              label="預計完成日(起)"
              value={filterData.estimatedEdate_begin}
              msg={filterData.estimatedEdate_begin_msg}
              onChange={handleFilterDataChange('estimatedEdate_begin')}
            />
          </Col>
          <Col span={12}>
            <DatePickerInline
              label="預計完成日(迄)"
              value={filterData.estimatedEdate_end}
              msg={filterData.estimatedEdate_end_msg}
              onChange={handleFilterDataChange('estimatedEdate_end')}
            />
          </Col>
          <Col span={12}>
            <DatePickerInline
              label="實際開工日(起)"
              value={filterData.actualSdate_begin}
              msg={filterData.actualSdate_begin_msg}
              onChange={handleFilterDataChange('actualSdate_begin')}
            />
          </Col>
          <Col span={12}>
            <DatePickerInline
              label="實際開工日(迄)"
              value={filterData.actualSdate_end}
              msg={filterData.actualSdate_end_msg}
              onChange={handleFilterDataChange('actualSdate_end')}
            />
          </Col>
          <Col span={12}>
            <DatePickerInline
              label="實際完成日(起)"
              value={filterData.actualEdate_begin}
              msg={filterData.actualEdate_begin_msg}
              onChange={handleFilterDataChange('actualEdate_begin')}
            />
          </Col>
          <Col span={12}>
            <DatePickerInline
              label="實際完成日(迄)"
              value={filterData.actualEdate_end}
              msg={filterData.actualEdate_end_msg}
              onChange={handleFilterDataChange('actualEdate_end')}
            />
          </Col>
          <Col span={12}>
            <RadioInline
              label="狀態"
              options={TrelloStatus}
              value={filterData.completeness}
              msg={filterData.completeness_msg}
              onChange={handleFilterDataChange('completeness')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleExportCsv}>
              下載
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const GenerateListColumns = (editFn, canEdit) => {
  const col = [
    {
      title: '列表名稱',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: '是否匯出卡片',
      dataIndex: 'export',
      key: 'export',
      width: 120,
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Switch
              style={{ minWidth: '50px' }}
              checkedChildren="是"
              unCheckedChildren="否"
              checked={record.export}
              disabled={!canEdit}
              onChange={editFn(record.listId)}
            />
          </Col>
        </Row>
      ),
    },
  ];
  return col;
};

const GenerateLabelColumns = (editFn, canEdit) => {
  const col = [
    {
      title: '標籤名稱',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: '是否匯出卡片',
      dataIndex: 'export',
      key: 'export',
      width: 120,
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Switch
              style={{ minWidth: '50px' }}
              checkedChildren="是"
              unCheckedChildren="否"
              checked={record.export}
              disabled={!canEdit}
              onChange={editFn(record.labelId)}
            />
          </Col>
        </Row>
      ),
    },
  ];
  return col;
};

const GenerateCustomFieldColumns = (editFn, canEdit) => {
  const col = [
    {
      title: '自訂欄位名稱',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: '是否匯出欄位',
      dataIndex: 'export',
      key: 'export',
      width: 120,
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Switch
              style={{ minWidth: '50px' }}
              checkedChildren="是"
              unCheckedChildren="否"
              checked={record.export}
              disabled={!canEdit}
              onChange={editFn(record.customFieldId)}
            />
          </Col>
        </Row>
      ),
    },
  ];
  return col;
};

export default SettingTrello;
