import moment from 'moment';
import { useState } from 'react';
import { Container } from './index';
import { ParseJwt } from '../utils/tool';

const GetInitUser = () => {
  const userId = window.sessionStorage.getItem('userId');
  const userName = window.sessionStorage.getItem('userName');
  const deptName = window.sessionStorage.getItem('deptName');
  const comId = window.sessionStorage.getItem('comId');
  const comName = window.sessionStorage.getItem('comName');
  const roles = window.sessionStorage.getItem('roles');
  const otherCompanies = window.sessionStorage.getItem('otherCompanies');
  const token = window.sessionStorage.getItem('token');

  if (userId && userName) {
    return {
      userId,
      userName,
      deptName,
      comId,
      comName,
      roles: roles.split(','),
      otherCompanies: JSON.parse(otherCompanies),
      token,
    };
  } else {
    return null;
  }
};

const useAuth = () => {
  const [user, setUser] = useState(GetInitUser());

  const AuthUser = (user, token) => {
    if (user && token) {
      const formatToken = `Bearer ${token}`;

      window.sessionStorage.setItem('userId', user.userId || '');
      window.sessionStorage.setItem('userName', user.userName || '');
      window.sessionStorage.setItem('deptName', user.deptName || '');
      window.sessionStorage.setItem('comId', user.comId || '');
      window.sessionStorage.setItem('comName', user.comName || '');
      window.sessionStorage.setItem('roles', user.roles.join(','));
      window.sessionStorage.setItem('otherCompanies', JSON.stringify(user.otherCompanies));
      window.sessionStorage.setItem('token', formatToken || '');
      const decodedJwt = ParseJwt(token);
      if (decodedJwt && decodedJwt.exp) {
        window.sessionStorage.setItem(
          'tokenTimeStamp',
          moment(new Date(decodedJwt.exp * 1000)).format('YYYY-MM-DD HH:mm:ss')
        );
      }
      window.sessionStorage.setItem('lastTimeStamp', moment().format('YYYY-MM-DD HH:mm:ss'));

      setUser({ ...user, token: formatToken });
    }
  };
  const UnAuthUser = () => {
    window.sessionStorage.removeItem('userId');
    window.sessionStorage.removeItem('userName');
    window.sessionStorage.removeItem('deptName');
    window.sessionStorage.removeItem('comId');
    window.sessionStorage.removeItem('comName');
    window.sessionStorage.removeItem('roles');
    window.sessionStorage.removeItem('otherCompanies');
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('tokenTimeStamp');
    window.sessionStorage.removeItem('lastTimeStamp');
    if (user) {
      window.sessionStorage.removeItem('formVacationCond_' + user.userId);
      window.sessionStorage.removeItem('formSealCond_' + user.userId);
      window.sessionStorage.removeItem('formInvoiceCond_' + user.userId);
      window.sessionStorage.removeItem('formChargeCond_' + user.userId);
    }
    setUser(null);
  };

  return { user, AuthUser, UnAuthUser };
};
export default Container(useAuth); //remenber use Container to wrap
