import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import File from '../../components/File';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button, Card, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
//
import keys from '../../configs/keys';
import { ListFormat, NumberFormat } from '../../modules/utils/format';
import { SalaryType } from '../../modules/utils/list';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData, IsNumber } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';
const TaxDependentNumList = [
  { label: '無', value: 0 },
  { label: '1人', value: 1 },
  { label: '2人', value: 2 },
  { label: '3人', value: 3 },
  { label: '4人', value: 4 },
  { label: '5人', value: 5 },
  { label: '6人', value: 6 },
  { label: '7人', value: 7 },
  { label: '8人', value: 8 },
  { label: '9人', value: 9 },
  { label: '10人', value: 10 },
  { label: '11人', value: 11 },
];

const SettingUserSalary = () => {
  const { user } = useProvided(useAuth);
  const SECOND_NHI_RATE = keys.secondNHIRate;
  const TAX_PREPAID_RATE = keys.taxPrepaidRate;
  const FIVE_MORE_OWNER_NHI_GRADE = keys.fiveMoreOwnerNHIGrade;
  const FIVE_LESS_OWNER_NHI_GRADE = keys.fiveLessOwnerNHIGrade;
  const MIM_SALARY_WH_TAX = keys.minSalaryWHTax;
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [keyword, setKeyword] = useState('');
  const [salaryData, setSalaryData] = useState({});
  const [salaryList, setSalaryList] = useState([]);
  const [insuranceData, setInsuranceData] = useState({});
  const [insuranceList, setInsuranceList] = useState([]);
  const [isSelectList, setIsSelectListList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [maxSalary, setMaxSalary] = useState(0);
  const [ownerNHIGrade, setOwnerNHIGrade] = useState(1);
  const [salaryWHTaxData, setSalaryWHTaxData] = useState({});
  const [salaryWHTaxFlag, setSalaryWHTaxFlag] = useState(false);
  const [salaryWHTaxAmount, setSalaryWHTaxAmount] = useState(0);
  //
  const viewList = useMemo(() => {
    return salaryList.filter((u) => !keyword || u.userId.match(keyword));
  }, [salaryList, keyword]);

  const isNewData = useMemo(() => {
    return modalProps.type === 'add';
  }, [modalProps.type]);

  const isEmployee = useMemo(() => {
    return salaryData.type === '0';
  }, [salaryData.type]);
  const isEmployer = useMemo(() => {
    return salaryData.type === '1';
  }, [salaryData.type]);
  const isConsultant = useMemo(() => {
    return salaryData.type === '2';
  }, [salaryData.type]);
  const isPartTime = useMemo(() => {
    return salaryData.type === '3';
  }, [salaryData.type]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrUserSalary' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load user list.
  useEffect(() => {
    axios
      .get('/api/setting/user/salary', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList([
            { label: '不限', value: '' },
            ...res.data.result.users.map((v) => ({
              label: v.userName,
              value: v.userId,
            })),
          ]);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  //load salary list
  const loadSalaryList = useCallback(() => {
    axios
      .get('/api/setting/user/salary/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const array = res.data.result.salaries;
          setSalaryList(array.map(SetKeyIntoArray));
          let employee = res.data.result.salaries.filter((e) => e.type === '0') || [];
          let numOfEmployee = employee.length;
          if (numOfEmployee > 0) {
            const basic = numOfEmployee > 5 ? FIVE_MORE_OWNER_NHI_GRADE : FIVE_LESS_OWNER_NHI_GRADE;
            const mSalary = Math.max.apply(
              Math,
              employee.map(function (o) {
                return o.salary;
              })
            );
            setMaxSalary(mSalary);
            //確認是否高於最低級距
            setOwnerNHIGrade(mSalary >= basic ? mSalary : basic);
          }
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user, FIVE_MORE_OWNER_NHI_GRADE, FIVE_LESS_OWNER_NHI_GRADE]);

  //load data
  const loadInsuranceList = useCallback(() => {
    axios
      .get(`/api/setting/insurance/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setIsSelectListList(
            res.data.result.insurances.map((c) => {
              return {
                label:
                  c.type === '0'
                    ? `${NumberFormat(c.startSalary)} - ${NumberFormat(c.endSalary)}`
                    : NumberFormat(c.grade),
                value: c.insuranceId,
              };
            })
          );
          setInsuranceList(res.data.result.insurances.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  useEffect(() => {
    loadSalaryList();
    loadInsuranceList();
  }, [loadSalaryList, loadInsuranceList]);

  //load one salary data
  const loadSalary = (userSalaryId) => {
    if (userSalaryId) {
      axios
        .get(`/api/setting/user/salary/info?id=${userSalaryId}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const info = res.data.result.userSalaryInfo;
            setSalaryData(info);
            if (info.insuranceId) {
              const data = insuranceList.filter((u) => u.insuranceId.match(info.insuranceId));
              if (data && data.length > 0) setInsuranceData(data[0]);
            }

            //取得所得稅額設定
            loadSalaryWHTax(info.salaryWHTaxId, info.salary, info.taxDependentNum);
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  const loadSalaryWHTax = (taxId, salary, taxDependentNum) => {
    if (taxId || salary) {
      axios
        .post(
          `/api/setting/salaryWithholdingTax/info`,
          { taxId: taxId, salary: salary },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            const taxes = res.data.result.taxes;
            if (taxes.length > 0) {
              setSalaryWHTaxData(taxes[0]);
              setSalaryWHTaxFlag(true);
              setSalaryData((prev) => ({
                ...prev,
                salaryWHTaxId: taxes[0].taxId,
              }));
              //計算稅額
              const num = Number(taxDependentNum || 0) + 1;
              let amount = 0;
              for (let i = 0; i < num; i++) {
                amount += taxes[0][`dependent${i}`];
              }
              setSalaryWHTaxAmount(amount);
            } else {
              setSalaryWHTaxData({});
              setSalaryWHTaxFlag(false);
              setSalaryData((prev) => ({
                ...prev,
                salaryWHTaxId: null,
              }));
              setSalaryWHTaxAmount(0);
            }
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //============================================================
  const handleModalOpen = (type, userSalaryId) => () => {
    if (userSalaryId) {
      loadSalary(userSalaryId);
    }
    setModalProps({ open: true, type: type });
  };
  const handleModalClose = () => {
    setSalaryData({});
    setInsuranceData({});
    setModalProps({ open: false, type: '' });
  };
  const handleKeywordChange = (e) => {
    setKeyword(e && e.target ? e.target.value : e);
  };
  const handleSalaryDataChange = (name) => (e) => {
    const value = e && e.target ? e.target.value : e;
    setSalaryData((prev) => ({
      ...prev,
      [name]: value,
      [name + '_msg']: '',
    }));

    if (name === 'salary') {
      if (isEmployee && IsNumber(value)) {
        const salary = Number(value);
        //一般員工
        const data = insuranceList.filter(
          (u) => u.type === '0' && u.startSalary <= salary && u.endSalary >= salary
        );
        if (data && data.length > 0) {
          setSalaryData((prev) => ({
            ...prev,
            insuranceId: data[0].insuranceId,
          }));
          setInsuranceData(data[0]);
        } else {
          setSalaryData((prev) => ({
            ...prev,
            insuranceId: null,
          }));
          setInsuranceData({});
        }
        if (salary >= MIM_SALARY_WH_TAX) {
          loadSalaryWHTax(null, salary, salaryData.taxDependentNum);
        } else {
          setSalaryWHTaxData({});
          setSalaryWHTaxFlag(false);
          setSalaryData((prev) => ({
            ...prev,
            salaryWHTaxId: null,
          }));
          setSalaryWHTaxAmount(0);
        }
      } else if (isEmployer && IsNumber(value)) {
        const salary = Number(value);
        if (salary >= MIM_SALARY_WH_TAX) {
          loadSalaryWHTax(null, salary, salaryData.taxDependentNum);
        } else {
          setSalaryWHTaxData({});
          setSalaryWHTaxFlag(false);
          setSalaryData((prev) => ({
            ...prev,
            salaryWHTaxId: null,
          }));
          setSalaryWHTaxAmount(0);
        }
      } else if (isConsultant) {
        //顧問
        if (value) {
          salaryData.healthInsurance = Math.round(value * SECOND_NHI_RATE).toString();
          salaryData.taxPrepaid = Math.round(value * TAX_PREPAID_RATE).toString();
        } else {
          salaryData.healthInsurance = null;
          salaryData.taxPrepaid = null;
        }
      }
    } else if (name === 'type') {
      if (value === '1') {
        //雇主
        //依公司內最高薪資帶入健保級距
        const data = insuranceList.filter(
          (u) =>
            u.type === '1' &&
            u.startSalary <= Number.parseInt(ownerNHIGrade) &&
            u.endSalary >= Number.parseInt(ownerNHIGrade)
        );
        if (data && data.length > 0) {
          setSalaryData((prev) => ({
            ...prev,
            insuranceId: data[0].insuranceId,
          }));
          setInsuranceData(data[0]);
        }
      }
    } else if (name === 'taxDependentNum') {
      //計算稅額
      const num = value + 1;
      let amount = 0;
      for (let i = 0; i < num; i++) {
        amount += salaryWHTaxData[`dependent${i}`];
      }
      setSalaryWHTaxAmount(amount);
    }
  };

  const handleSave = () => {
    //valid
    const validResult = ValidFormData(salaryData, [
      { name: 'salary', type: 'input', required: true },
      { name: 'userId', type: 'select', required: true },
    ]);
    if (!validResult.status) {
      setSalaryData(validResult.data);
      return;
    }

    let updateOwner = false;
    if (isEmployee) {
      const tmpList = salaryList
        .filter((sf) => sf.type === '0' && sf.userSalaryId !== salaryData.userSalaryId)
        .map((s) => s.salary);
      tmpList.push(salaryData.salary);
      const max = Math.max(...tmpList);
      updateOwner = max !== maxSalary;
    }

    axios
      .post(
        '/api/setting/user/salary/save',
        { salaryData: salaryData, updateOwner: updateOwner },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadSalaryList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = (userSalaryId) => () => {
    axios
      .post('/api/setting/user/salary/void', { userSalaryId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('作廢完成');
          loadSalaryList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col lg={20}>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>薪資管理</Title>
            </Col>
            <Col>
              <SelectInline
                canSearch
                label="人員"
                options={userList}
                value={keyword}
                onChange={handleKeywordChange}
                style={{ minWidth: '200px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen('add', null)} icon={<PlusOutlined />}>
            新增薪資設定
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete, isSelectList)}
        dataSource={viewList}
        scroll={{ x: 800, y: 'calc(100vh - 135px)' }}
      />
      <Modal width="800px" visible={modalProps.open}>
        <Row>
          <Col span={12}>
            <Display label="薪資識別碼" value={salaryData.userSalaryId || '自動產生'} />
          </Col>
          <Col span={12}>
            {isNewData ? (
              <Select
                required
                label="員工"
                options={userList}
                value={salaryData.userId}
                msg={salaryData.userId_msg}
                onChange={handleSalaryDataChange('userId')}
              />
            ) : (
              <Display label="員工" value={salaryData.userName} />
            )}
          </Col>
          <Col span={12}>
            {isNewData ? (
              <Select
                required
                label="類別"
                options={SalaryType}
                value={salaryData.type}
                msg={salaryData.type_msg}
                onChange={handleSalaryDataChange('type')}
              />
            ) : (
              <Display label="類別" value={ListFormat(SalaryType, salaryData.type)} />
            )}
          </Col>
          <Col span={12}>
            <Input
              required
              label="薪資"
              value={salaryData.salary}
              msg={salaryData.salary_msg}
              onChange={handleSalaryDataChange('salary')}
            />
          </Col>
          {isEmployee && (
            <Col span={24}>
              <Col span={12}>
                <Display label="投保級距" value={ListFormat(isSelectList, salaryData.insuranceId)} />
              </Col>
              <Col span={24}>
                <Card
                  title="雇主負擔"
                  style={{ marginTop: '8px', marginBottom: '8px', background: '#D9D9D9' }}
                >
                  <Row>
                    <Col span={8}>
                      <Display label="勞保雇主負擔" value={NumberFormat(insuranceData.employerLabor)} />
                    </Col>
                    <Col span={8}>
                      <Display label="勞退雇主負擔" value={NumberFormat(insuranceData.employerPension)} />
                    </Col>
                    <Col span={8}>
                      <Display label="健保雇主負擔" value={NumberFormat(insuranceData.employerHealth)} />
                    </Col>
                  </Row>
                </Card>
                <Card
                  title="個人負擔"
                  style={{ marginTop: '8px', marginBottom: '8px', background: '#D9D9D9' }}
                >
                  <Row>
                    <Col span={12}>
                      <Display label="勞保個人負擔" value={NumberFormat(insuranceData.employeeLabor)} />
                    </Col>
                    <Col span={12}>
                      <Display label="健保個人負擔" value={NumberFormat(insuranceData.employeeHealth)} />
                    </Col>
                    <Col span={12}>
                      <Input
                        label="健保眷屬"
                        value={salaryData.dependents}
                        onChange={handleSalaryDataChange('dependents')}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        label="健保眷屬費用"
                        value={salaryData.dependentsFee}
                        onChange={handleSalaryDataChange('dependentsFee')}
                      />
                    </Col>
                  </Row>
                </Card>
                {salaryWHTaxFlag && (
                  <Card
                    title="薪資所得扣繳稅額"
                    style={{ marginTop: '8px', marginBottom: '8px', background: '#D9D9D9' }}
                  >
                    <Row>
                      <Col span={12}>
                        <Select
                          label="配偶及受扶養親屬人數"
                          options={TaxDependentNumList}
                          value={salaryData.taxDependentNum || 0}
                          msg={salaryData.taxDependentNum_msg}
                          onChange={handleSalaryDataChange('taxDependentNum')}
                        />
                      </Col>
                      <Col span={12}>
                        <Display label="扣繳稅額" value={NumberFormat(salaryWHTaxAmount)} />
                      </Col>
                    </Row>
                  </Card>
                )}
              </Col>
            </Col>
          )}
          {isEmployer && (
            <Col span={24}>
              <Col span={24}>
                <Card
                  title="勞保費用"
                  style={{ marginTop: '8px', marginBottom: '8px', background: '#D9D9D9' }}
                >
                  <Row>
                    <Col span={8}>
                      <Input
                        label="勞保金額"
                        value={salaryData.laborInsurance}
                        onChange={handleSalaryDataChange('laborInsurance')}
                      />
                    </Col>
                  </Row>
                </Card>
                <Card
                  title="健保費用"
                  style={{ marginTop: '8px', marginBottom: '8px', background: '#D9D9D9' }}
                >
                  <Row>
                    <Col span={12}>
                      <Display label="投保級距" value={NumberFormat(insuranceData.grade)} />
                    </Col>
                    <Col span={12}>
                      <Display label="健保金額" value={NumberFormat(insuranceData.employeeHealth)} />
                    </Col>
                    <Col span={12}>
                      <Input
                        label="健保眷屬"
                        value={salaryData.dependents}
                        onChange={handleSalaryDataChange('dependents')}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        label="健保眷屬費用"
                        value={salaryData.dependentsFee}
                        onChange={handleSalaryDataChange('dependentsFee')}
                      />
                    </Col>
                  </Row>
                </Card>
                {salaryWHTaxFlag && (
                  <Card
                    title="薪資所得扣繳稅額"
                    style={{ marginTop: '8px', marginBottom: '8px', background: '#D9D9D9' }}
                  >
                    <Row>
                      <Col span={12}>
                        <Select
                          label="配偶及受扶養親屬人數"
                          options={TaxDependentNumList}
                          value={salaryData.taxDependentNum || 0}
                          msg={salaryData.taxDependentNum_msg}
                          onChange={handleSalaryDataChange('taxDependentNum')}
                        />
                      </Col>
                      <Col span={12}>
                        <Display label="扣繳稅額" value={NumberFormat(salaryWHTaxAmount)} />
                      </Col>
                    </Row>
                  </Card>
                )}
              </Col>
            </Col>
          )}
          {isConsultant && (
            <Col span={24}>
              <Col span={24}>
                <Card
                  title="負擔費用"
                  style={{ marginTop: '8px', marginBottom: '8px', background: '#D9D9D9' }}
                >
                  <Row>
                    <Col span={12}>
                      <Display label="二代健保補充保費" value={NumberFormat(salaryData.healthInsurance)} />
                      <strong style={{ color: '#D73141', float: 'right' }}>
                        (費率：{NumberFormat(SECOND_NHI_RATE * 100)}%)
                      </strong>
                    </Col>
                    <Col span={12}>
                      <Display label="國稅局預繳稅款" value={NumberFormat(salaryData.taxPrepaid)} />
                      <strong style={{ color: '#D73141', float: 'right' }}>
                        (費率：{NumberFormat(TAX_PREPAID_RATE * 100)}%)
                      </strong>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Col>
          )}
          {isPartTime && (
            <Col span={24}>
              <strong style={{ color: '#D73141' }}>
                ※部分工時人員的薪資為【時薪】，投保級距會以該月上班總工時計算
              </strong>
            </Col>
          )}
          <Col span={24}>
            <File label="附件" value={salaryData.files} onChange={handleSalaryDataChange('files')} />
          </Col>
          <Col span={24}>
            <Textarea
              label="備註"
              rows={5}
              value={salaryData.memo}
              onChange={handleSalaryDataChange('memo')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const GenerateColumns = (openFn, deleteFn, selectList) => {
  return [
    {
      title: '員工姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
    },
    {
      title: '類別',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (value) => ListFormat(SalaryType, value),
    },
    {
      title: '薪資',
      dataIndex: 'salary',
      key: 'salary',
      width: 150,
      render: (value) => NumberFormat(value),
    },
    {
      title: '投保級距',
      dataIndex: 'insuranceId',
      key: 'insuranceId',
      width: 200,
      render: (value) => ListFormat(selectList, value),
    },
    {
      title: '備註',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => {
        return (
          <Row justify="center">
            <Col>
              <Tooltip title="編輯">
                <Button icon={<EditOutlined />} onClick={openFn('edit', record.userSalaryId)} />
              </Tooltip>
            </Col>
            <Col>
              <Popconfirm
                title="是否確認作廢此薪資設定？"
                placement="topRight"
                okText="確定"
                cancelText="取消"
                onConfirm={deleteFn(record.userSalaryId)}
              >
                <Tooltip title="作廢">
                  <Button icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];
};

export default SettingUserSalary;
