import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import SubTitle from '../../components/SubTitle';
import { Button, Tooltip, Popconfirm, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
//
import { ListFormat, NumberFormat } from '../../modules/utils/format';
import { GetFormFlowString, SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const GenerateColumns = (openFn, delFn, formList, deptList) => {
  return [
    {
      title: '表單名稱',
      dataIndex: 'formCode',
      key: 'formCode',
      width: 110,
      render: (value) => ListFormat(formList, value),
    },
    {
      title: '部門',
      dataIndex: 'deptId',
      key: 'deptId',
      width: 110,
      render: (value) => ListFormat(deptList, value),
    },
    {
      title: '核決內容',
      dataIndex: 'details',
      key: 'details',
      className: 'j-table-col',
      width: 700,
      render: (value) => {
        if (Array.isArray(value) && value.length > 0) {
          return (
            <Table
              className="j-inner-table"
              columns={DetailColumns}
              dataSource={value.map(SetKeyIntoArray)}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button onClick={openFn('edit', record.approvalId)} icon={<EditOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定作廢此權限設定？"
              placement="topRight"
              okText="確定"
              cancelText="關閉"
              onConfirm={delFn(record.approvalId)}
            >
              <Tooltip title="作廢">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

const DetailColumns = [
  {
    title: '核決金額',
    dataIndex: 'amount',
    key: 'amount',
    width: 200,
    render: (value, record) =>
      `${NumberFormat(record.startAmount)} - ${record.endAmount ? NumberFormat(record.endAmount) : ''}`,
  },
  {
    title: '核決人員',
    dataIndex: 'flow',
    key: 'flow',
    render: (value) => GetFormFlowString(value),
  },
];

const GenerateModalColumns = (editFn, delFn) => {
  return [
    {
      title: '核決金額',
      dataIndex: 'amount',
      key: 'amount',
      width: 200,
      render: (value, record) =>
        `${NumberFormat(record.startAmount)} - ${record.endAmount ? NumberFormat(record.endAmount) : ''}`,
    },
    {
      title: '核決人員',
      dataIndex: 'flow',
      key: 'flow',
      render: (value) => GetFormFlowString(value),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 110,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={editFn(record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定刪除此權限設定？"
              placement="topRight"
              okText="確認刪除"
              cancelText="取消"
              onConfirm={delFn(record.seq)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

const SettingApproval = () => {
  const { user } = useProvided(useAuth);
  const [redirectFlag, setRedirectFlag] = useState('');
  const [filterData, setFilterData] = useState({ formCode: '', deptId: '' });
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [mainData, setMainData] = useState({});
  const [mainList, setMainList] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [detailList, setDetailList] = useState([]);
  const [formList, setFormList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const viewList = useMemo(() => {
    return mainList.filter(
      (u) =>
        (filterData.formCode === '' || u.formCode === filterData.formCode) &&
        (filterData.deptId === '' || u.deptId === filterData.deptId)
    );
  }, [mainList, filterData]);

  const isNewData = useMemo(() => {
    return modalProps.type === 'add';
  }, [modalProps.type]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrApproval' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load form, dept list
  useEffect(() => {
    //departments
    axios
      .get(`/api/setting/dept/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setDeptList([
            { label: '不限', value: '' },
            ...res.data.result.depts.map((u) => ({
              label: u.deptName,
              value: u.deptId,
            })),
          ]);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });

    //form
    axios
      .get(`/api/setting/flow/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setFormList([
            { label: '不限', value: '' },
            ...res.data.result.forms.map((u) => ({
              ...u,
              label: u.formName,
              value: u.formCode,
            })),
          ]);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load approval list
  const loadApprovalList = useCallback(() => {
    axios
      .get('/api/setting/approval/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setMainList(res.data.result.approvals.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  useEffect(() => {
    loadApprovalList();
  }, [loadApprovalList]);

  //load one approval data
  const loadApproval = (approvalId) => {
    if (approvalId) {
      axios
        .get(`/api/setting/approval/info?id=${approvalId}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { m, d } = res.data.result;
            if (m.formCode) {
              const item = formList.find((v) => v.formCode === m.formCode);
              if (item) {
                setItemList(
                  item.flow.map((u) => ({
                    ...u,
                    label: u.stage,
                    value: u.seq,
                    disabled: u.fixed,
                  }))
                );

                const items = item.flow.filter((u) => u.fixed === true);
                if (items.length > 0) {
                  setCheckedList(items.map((u) => u.seq));
                }
              }
            }
            setMainData(m);
            setDetailList(d.map(SetKeyIntoArray));
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //============================================================
  const handleFilterDataChange = (name) => (e) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
    }));
  };

  //============================================================
  const handleMainDataChange = (name) => (e) => {
    const value = e && e.target ? e.target.value : e;
    setMainData((prev) => ({
      ...prev,
      [name]: value,
      [name + '_msg']: '',
    }));

    if (name === 'formCode') {
      const item = formList.find((v) => v.formCode === value);
      if (item) {
        setDetailData((prev) => ({
          ...prev,
          flow: item.flow.map((u) => ({
            seq: u.seq,
            stage: u.stage,
            stop: u.stop,
            fixed: u.fixed,
          })),
        }));
        setItemList(
          item.flow.map((u) => ({
            ...u,
            label: u.stage,
            value: u.seq,
            disabled: u.fixed,
          }))
        );
        const items = item.flow.filter((u) => u.fixed === true);
        if (items.length > 0) {
          setCheckedList(items.map((u) => u.seq));
        }
      }
    }
  };

  const handleDetailDataChange = (name) => (e) => {
    let value = e && e.target ? e.target.value : e;
    if (name === 'flow') {
      setCheckedList(value);
      const flow = itemList.map((u) => ({
        seq: u.seq,
        stage: u.stage,
        fixed: u.fixed,
        stop: u.stop,
        sign: value.includes(u.seq),
      }));
      value = flow;
    }
    setDetailData((prev) => ({
      ...prev,
      [name]: value,
      [name + '_msg']: '',
    }));
  };

  const handleDetailListSelected = (record) => () => {
    if (record) {
      setDetailData(record);
      if (record.flow) {
        const items = record.flow.filter((u) => u.sign === true);
        if (items.length > 0) {
          setCheckedList(items.map((u) => u.seq));
        }
      }
    } else {
      setDetailData({});
    }
  };

  const handleDetailListAdd = () => {
    const validResult = ValidFormData(detailData, [{ name: 'startAmount', type: 'input', required: true }]);
    if (!validResult.status) {
      setDetailData(validResult.data);
      return;
    }

    const tmpList = detailList.filter((df) => df.seq !== detailData.seq);
    tmpList.push({
      seq: detailData.seq || tmpList.length + 1,
      startAmount: detailData.startAmount,
      endAmount: detailData.endAmount,
      flow: detailData.flow,
    });
    tmpList.sort((a, b) => {
      if (a.seq < b.seq) {
        return -1;
      } else {
        return 1;
      }
    });
    setDetailList(tmpList.map(SetKeyIntoArray));
    setDetailData({});
  };

  const handleDetailListDelete = (seq) => () => {
    setDetailData({});
    setDetailList((prev) => prev.filter((p) => p.seq !== seq));
  };

  const handleSave = () => {
    //valid
    const validResult = ValidFormData(mainData, [{ name: 'formCode', type: 'select', required: true }]);
    if (!validResult.status) {
      setMainData(validResult.data);
      return;
    }
    if (!Array.isArray(detailList) || detailList.length < 1) {
      Notify.warn('請填寫核決權限設定');
      return;
    }

    const postData = {
      approvalId: mainData.approvalId,
      deptId: mainData.deptId,
      formCode: mainData.formCode,
      memo: mainData.memo,
      details: detailList,
    };
    //
    axios
      .post('/api/setting/approval/save', postData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          loadApprovalList();
          handleModalClose();
          Notify.success('儲存成功');
        } else {
          Notify.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  const handleVoid = (approvalId) => () => {
    axios
      .post('/api/setting/approval/void', { approvalId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('作廢完成');
          loadApprovalList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleModalOpen = (type, approvalId) => () => {
    if (approvalId) {
      loadApproval(approvalId);
    }
    setModalProps({ open: true, type: type });
  };

  const handleModalClose = () => {
    setMainData({});
    setDetailData({});
    setDetailList([]);
    setItemList([]);
    setCheckedList([]);
    setModalProps({ open: false, type: '' });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>核決權限管理</Title>
            </Col>
            <Col>
              <SelectInline
                label="表單"
                options={formList}
                value={filterData.formCode}
                onChange={handleFilterDataChange('formCode')}
                style={{ minWidth: '200px' }}
              />
            </Col>

            <Col>
              <SelectInline
                label="部門"
                options={deptList}
                value={filterData.deptId}
                onChange={handleFilterDataChange('deptId')}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen('add', null)} icon={<PlusOutlined />}>
            新增核決權限
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleVoid, formList, deptList)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal visible={modalProps.open} width="1000px">
        <Title>{modalProps.type === 'add' ? '新增' : '編輯'}權限</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <Display label="核決識別碼" value={mainData.approvalId || '自動產生'} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {isNewData ? (
              <Select
                required
                canSearch
                label="表單"
                options={formList}
                value={mainData.formCode}
                msg={mainData.formCode_msg}
                onChange={handleMainDataChange('formCode')}
              />
            ) : (
              <Display label="表單" value={ListFormat(formList, mainData.formCode)} />
            )}
          </Col>
          <Col span={12}>
            {isNewData ? (
              <Select
                canSearch
                label="部門"
                options={deptList}
                value={mainData.deptId}
                msg={mainData.deptId_msg}
                onChange={handleMainDataChange('deptId')}
              />
            ) : (
              <Display label="部門" value={ListFormat(deptList, mainData.deptId)} />
            )}
          </Col>
          <Col span={24}>
            <Textarea label="備註" value={mainData.memo} onChange={handleMainDataChange('memo')} />
          </Col>
        </Row>
        <Divider dashed />
        <SubTitle>核決內容</SubTitle>
        <br />
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <Input
              required
              label="核決金額-起"
              value={detailData.startAmount}
              msg={detailData.startAmount_msg}
              onChange={handleDetailDataChange('startAmount')}
            />
          </Col>
          <Col span={12}>
            <Input
              label="核決金額-迄"
              value={detailData.endAmount}
              msg={detailData.endAmount_msg}
              onChange={handleDetailDataChange('endAmount')}
            />
          </Col>
          <Col span={24}>
            <Checkbox.Group
              onChange={handleDetailDataChange('flow')}
              options={itemList}
              value={checkedList}
              style={{ width: '100%' }}
            ></Checkbox.Group>
          </Col>
          <Divider />
          <Col span={4}>
            <Button block type="primary" size="large" onClick={handleDetailListAdd}>
              {detailData.seq ? '儲存' : '新增'}
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          columns={GenerateModalColumns(handleDetailListSelected, handleDetailListDelete)}
          dataSource={detailList}
        />
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SettingApproval;
