import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
//
import Col from '../Col';
import Divider from '../Divider';
import Modal from '../Modal';
import Row from '../Row';
import Textarea from '../Textarea';
import { Button, Popconfirm } from 'antd';
import { LeftOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { SolutionOutlined, CheckCircleOutlined, UndoOutlined, CloseCircleOutlined } from '@ant-design/icons';

const SignControlBar = ({ btnCtrl, saveFn, deleteFn, submitFn, approveFn, returnFn, voidFn, backUrl }) => {
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [commentData, setCommentData] = useState({ value: '', value_msg: '' });
  const history = useHistory();

  const handleCommentDataChange = (e) => {
    setCommentData({ value: e.target.value, value_msg: '' });
  };

  const validComment = () => {
    if (!commentData.value) {
      setCommentData({ value: '', value_msg: '請填寫意見與原因' });
      return false;
    }
    return true;
  };
  const handleAction = () => {
    switch (modalProps.type) {
      case 'submit':
        submitFn && submitFn(commentData.value);
        break;
      case 'approve':
        approveFn && approveFn(commentData.value);
        break;
      case 'return':
        if (validComment()) {
          returnFn && returnFn(commentData.value);
        }
        break;
      case 'void':
        if (validComment()) {
          voidFn && voidFn(commentData.value);
        }
        break;
      default:
        break;
    }
  };

  const handleModalOpen = useCallback(
    (type) => () => {
      setModalProps({ open: true, type: type });
    },
    []
  );
  const handleModalClose = () => {
    setModalProps({ open: false, type: '' });
    setCommentData({ value: '', value_msg: '' });
  };

  const buttonDom = useMemo(() => {
    const dom = [];
    //save
    if (btnCtrl.save) {
      dom.push(
        <Col key="save-1">
          <Button size="large" icon={<SaveOutlined />} onClick={saveFn()} className="j-btn-save">
            儲存
          </Button>
        </Col>
      );
    }
    //save
    if (btnCtrl.delete) {
      dom.push(
        <Col key="delete-1">
          <Popconfirm title="是否確認刪除此表單？" okText="確認" cancelText="取消" onConfirm={deleteFn}>
            <Button size="large" icon={<DeleteOutlined />} className="j-btn-delete">
              刪除
            </Button>
          </Popconfirm>
        </Col>
      );
    }
    //submit
    if (btnCtrl.submit) {
      dom.push(
        <Col key="submit-1">
          <Button
            size="large"
            icon={<SolutionOutlined />}
            onClick={saveFn(handleModalOpen('submit'))}
            className="j-btn-submit"
          >
            呈送
          </Button>
        </Col>
      );
    }
    //approve
    if (btnCtrl.approve) {
      dom.push(
        <Col key="approve-1">
          <Button
            size="large"
            icon={<CheckCircleOutlined />}
            onClick={handleModalOpen('approve')}
            className="j-btn-approve"
          >
            核准
          </Button>
        </Col>
      );
    }
    //return
    if (btnCtrl.return) {
      dom.push(
        <Col key="return-1">
          <Button
            size="large"
            icon={<UndoOutlined />}
            onClick={handleModalOpen('return')}
            className="j-btn-return"
          >
            退回
          </Button>
        </Col>
      );
    }
    //void
    if (btnCtrl.void) {
      dom.push(
        <Col key="void-1">
          <Button
            size="large"
            icon={<CloseCircleOutlined />}
            onClick={handleModalOpen('void')}
            className="j-btn-void"
          >
            作廢
          </Button>
        </Col>
      );
    }
    return dom;
  }, [btnCtrl, handleModalOpen, saveFn, deleteFn]);

  return (
    <div>
      <Row>
        <Col>
          {/* <Link to={backUrl || '/home'}>
            <Button size="large" icon={<LeftOutlined />} className="j-btn-back">
              回上頁
            </Button>
          </Link> */}
          <Button
            size="large"
            icon={<LeftOutlined />}
            className="j-btn-back"
            onClick={() => history.goBack()}
          >
            回上頁
          </Button>
        </Col>
        {buttonDom}
      </Row>
      <Modal visible={modalProps.open} width="800px">
        <Row>
          <Col span={24}>
            <Textarea
              required={['return', 'void'].includes(modalProps.type)}
              label="意見與原因"
              rows={5}
              value={commentData.value}
              msg={commentData.value_msg}
              onChange={handleCommentDataChange}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleAction}>
              確認
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SignControlBar;
