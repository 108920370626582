import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Title from '../../components/Title';
import SubTitle from '../../components/SubTitle';
import { Button, Tooltip, Popconfirm, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
//
import { ListFormat } from '../../modules/utils/format';
import { GetFormFlowString, SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '表單代碼',
      dataIndex: 'formCode',
      key: 'formName',
      width: 150,
    },
    {
      title: '表單名稱',
      dataIndex: 'formName',
      key: 'formName',
      width: 150,
    },
    {
      title: '表單流程',
      dataIndex: 'flow',
      key: 'flow',
      width: 250,
      render: (value) => GetFormFlowString(value),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={openFn('edit', record.formCode)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確認刪除此表單？"
              placement="topRight"
              okText="確定刪除"
              cancelText="取消"
              onConfirm={deleteFn(record.formCode)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

const SettingFlow = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [keyword, setKeyword] = useState('');
  const [flowData, setFlowData] = useState({});
  const [flowList, setFlowList] = useState([]);
  const [formItem, setFormItem] = useState([]);
  const [formList, setFormList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  //
  const viewList = useMemo(() => {
    return flowList.filter((m) => !keyword || m.formName.match(keyword) || m.formCode.match(keyword));
  }, [flowList, keyword]);

  const isNewData = useMemo(() => {
    return modalProps.type === 'add';
  }, [modalProps.type]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrFlow' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load form list
  useEffect(() => {
    //form
    axios
      .get(`/api/setting/form/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setFormList(
            res.data.result.forms.map((u) => ({
              ...u,
              label: u.formName,
              value: u.formCode,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load flows
  const loadFlowList = useCallback(() => {
    axios
      .get('/api/setting/flow/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setFlowList(res.data.result.forms.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  useEffect(() => {
    loadFlowList();
  }, [loadFlowList]);

  //load one form data
  const loadFlow = (formCode) => {
    if (formCode) {
      axios
        .get(`/api/setting/flow/info?id=${formCode}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { flowInfo } = res.data.result;
            setFlowData(flowInfo);
            if (flowInfo) {
              let cklist1 = [];
              let cklist2 = [];
              const item = formList.find((v) => v.formCode === formCode);
              if (item) {
                setFormItem(
                  item.flow.map((u) => ({
                    ...u,
                    label: u.stage,
                    value: u.seq,
                  }))
                );

                const disabled = item.flow.filter((u) => u.fixed === true);
                if (disabled.length > 0) {
                  cklist1 = disabled.map((u) => u.seq);
                }
              }

              const items = flowInfo.flow.filter((u) => u.fixed === true);
              if (items.length > 0) {
                cklist2 = items.map((u) => u.seq);
              }
              const cklist = _.union(cklist1, cklist2);
              setCheckedList(cklist);
            }
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //============================================================
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleFlowDataChange = (name) => (e) => {
    let value = e && e.target ? e.target.value : e;
    if (name === 'flow') {
      setCheckedList(value);
      const flow = formItem.map((u) => ({
        seq: u.seq,
        stage: u.stage,
        fixed: value.includes(u.seq),
        stop: u.stop,
      }));
      value = flow;
    } else if (name === 'formCode') {
      const item = formList.find((v) => v.formCode === value);
      if (item) {
        const flow = item.flow.map((u) => ({
          seq: u.seq,
          stage: u.stage,
          stop: u.stop,
          fixed: true,
        }));
        setCheckedList(item.flow.map((u) => u.seq));
        setFlowData((prev) => ({
          ...prev,
          formName: item.formName,
          flow: flow,
        }));
        setFormItem(
          item.flow.map((u) => ({
            ...u,
            label: u.stage,
            value: u.seq,
          }))
        );
      }
    }

    setFlowData((prev) => ({
      ...prev,
      [name]: value,
      [name + '_msg']: '',
    }));
  };

  const handleSave = () => {
    //valid
    const validResult = ValidFormData(flowData, [{ name: 'formCode', type: 'input', required: true }]);
    if (!validResult.status) {
      setFlowData(validResult.data);
      return;
    }

    if (isNewData) {
      if (flowList.find((m) => m.formCode === flowData.formCode)) {
        setFlowData((prev) => ({
          ...prev,
          formCode: '',
          formCode_msg: '此表單已有設定資料，請重新輸入',
        }));
        return;
      }
    }

    axios
      .post('/api/setting/flow/save', flowData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadFlowList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  const handleDelete = (formCode) => () => {
    axios
      .post('/api/setting/flow/delete', { formCode }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除成功');
          loadFlowList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleModalOpen = (type, formCode) => () => {
    if (formCode) {
      loadFlow(formCode);
    }
    setModalProps({ open: true, type: type });
  };

  const handleModalClose = () => {
    setFlowData({});
    setFormItem([]);
    setModalProps({ open: false, type: '' });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>流程管理</Title>
            </Col>
            <Col>
              <InputInline
                label="關鍵字"
                placeholder="請輸入表單代碼或名稱"
                value={keyword}
                onChange={handleKeywordChange}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen('add', null)} icon={<PlusOutlined />}>
            新增表單流程
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal visible={modalProps.open} width="1000px">
        <Title>{modalProps.type === 'add' ? '新增' : '編輯'}流程</Title>
        <br />
        <Row>
          <Col span={12}>
            {isNewData ? (
              <SelectInline
                required
                canSearch
                label="表單"
                options={formList}
                value={flowData.formCode}
                msg={flowData.formCode_msg}
                onChange={handleFlowDataChange('formCode')}
                style={{ minWidth: '300px' }}
              />
            ) : (
              <Display label="表單" value={ListFormat(formList, flowData.formCode)} />
            )}
          </Col>
        </Row>
        <Divider dashed />
        <SubTitle>流程關卡</SubTitle>
        <Row>
          {/* <Checkbox.Group
            onChange={handleFlowDataChange('flow')}
            options={formItem}
            value={checkedList}
            style={{ width: '100%' }}
          ></Checkbox.Group> */}
          <Checkbox.Group
            value={checkedList}
            onChange={handleFlowDataChange('flow')}
            style={{ width: '100%' }}
          >
            <Row gutter={[8, 8]}>
              {formItem.map((f, fIdx) => (
                <Col key={`ckItem-${fIdx}`} span={4}>
                  <Checkbox value={f.value} disabled={f.fixed}>
                    {f.label}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SettingFlow;
