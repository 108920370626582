import styled from 'styled-components';

const JSubtitle = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 1.5em;
  font-weight: bold;
`;

export default JSubtitle;
