import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import Notify from '../../components/Notify';
import Password from '../../components/Password';
import Row from '../../components/Row';
import Title from '../../components/Title';
import { Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
//
import { IsEmail, ValidFormData } from '../../modules/utils/valid';

const Register = () => {
  const [toLogin, setToLogin] = useState(false);
  const [registerData, setRegisterData] = useState({});

  const handleInitDataChange = (name) => (e) => {
    setRegisterData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };

  const handleRegister = () => {
    //
    const validResult = ValidFormData(registerData, [
      { name: 'userName', type: 'input', required: true },
      { name: 'email', type: 'input', required: true },
      { name: 'pwd', type: 'input', required: true },
      { name: 'comName', type: 'input', required: true },
      { name: 'workOn', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setRegisterData(validResult.data);
      return;
    }
    //
    if (!IsEmail(registerData.email)) {
      setRegisterData((prev) => ({
        ...prev,
        email: '',
        email_msg: '信箱格式不正確',
      }));
      return;
    }

    //
    const saveData = {
      userName: registerData.userName,
      email: registerData.email,
      pwd: registerData.pwd,
      comName: registerData.comName,
      workOn: moment(registerData.workOn).format('YYYY-MM-DD'),
    };
    axios
      .post('/api/outer/registerCompany', saveData)
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('註冊成功');
          setToLogin(true);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err.message);
      });
  };

  if (toLogin) {
    return <Redirect to="/login" />;
  }
  return (
    <Div>
      <InnerDiv>
        <Title>註冊新公司</Title>
        <Row>
          <Col span={24}>
            <Input
              required
              label="管理者姓名"
              value={registerData.userName}
              msg={registerData.userName_msg}
              onChange={handleInitDataChange('userName')}
            />
          </Col>
          <Col span={24}>
            <Input
              required
              label="管理者帳號"
              value={registerData.email}
              msg={registerData.email_msg}
              onChange={handleInitDataChange('email')}
            />
          </Col>
          <Col span={24}>
            <Password
              required
              label="管理者密碼"
              value={registerData.pwd}
              msg={registerData.pwd_msg}
              onChange={handleInitDataChange('pwd')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DatePicker
              required
              label="到職日"
              value={registerData.workOn}
              msg={registerData.workOn_msg}
              onChange={handleInitDataChange('workOn')}
            />
          </Col>
        </Row>
        <Divider dashed />
        <Row>
          <Col span={24}>
            <Input
              required
              label="公司名稱"
              value={registerData.comName}
              msg={registerData.comName_msg}
              onChange={handleInitDataChange('comName')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="center">
          <Col span={24}>
            <Button block size="large" type="primary" icon={<SendOutlined />} onClick={handleRegister}>
              註冊
            </Button>
          </Col>
        </Row>
        <br />
        <Row justify="end">
          <Col>
            <Link to="/login">
              <Button type="link">回到登入頁</Button>
            </Link>
          </Col>
        </Row>
      </InnerDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const InnerDiv = styled.div`
  width: 500px;
  margin-top: 50px;
  padding: 30px 30px 20px;
  background-color: #fff;
  border-radius: 2px;
`;

export default Register;
