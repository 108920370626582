import React, { useState, useEffect } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
//
import Notify from '../../components/Notify';
import { Menu } from 'antd';
//
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
import { MenuData } from './menuData';

const { SubMenu } = Menu;

const GenerateMenuItem = (menu, pKey) => {
  return (
    <Menu.Item key={menu.key || pKey}>
      <Link to={menu.link}>
        {menu.iconType}
        {menu.title}
      </Link>
    </Menu.Item>
  );
};

const JMenu = () => {
  const { user } = useProvided(useAuth);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/setting/menu/byRole?code=${user.roles.join(',')}`, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setMenus(res.data.result.menus);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  return (
    <MenuContainer>
      <InnerDiv>
        <Switch>
          <Route
            render={(routeProps) => {
              let currentKey = '';
              const menusDOM = [];
              const currentPath = routeProps.location.pathname;

              MenuData &&
                MenuData.forEach((menu, mIdx) => {
                  if (menu.submenu) {
                    const submenusDOM = menu.submenu
                      .map((sub, sIdx) => {
                        if (menus.includes(sub.key)) {
                          if (sub.link.startsWith(currentPath)) {
                            currentKey = sub.key;
                          }
                          return GenerateMenuItem(sub, `${menu.key}_${sIdx}`);
                        } else {
                          return null;
                        }
                      })
                      .filter((m) => m);

                    if (submenusDOM.length > 0) {
                      menusDOM.push(
                        <SubMenu
                          key={menu.key}
                          title={
                            <span>
                              {menu.iconType}
                              <span>{menu.title}</span>
                            </span>
                          }
                        >
                          {submenusDOM}
                        </SubMenu>
                      );
                    }
                  } else {
                    if (menus.includes(menu.key)) {
                      if (menu.link === currentPath) {
                        currentKey = menu.key;
                      }

                      menusDOM.push(GenerateMenuItem(menu, `${mIdx}`));
                    }
                  }
                });

              return (
                <Menu mode="inline" selectedKeys={[currentKey]}>
                  {menusDOM}
                </Menu>
              );
            }}
          />
        </Switch>
      </InnerDiv>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #fff;
  padding-top: 5px;
`;
const InnerDiv = styled.div`
  width: 100%;
  height: calc(100vh - 220px);
  overflow-x: hidden;
  overflow-y: auto;
`;

export default JMenu;
