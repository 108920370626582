import moment from 'moment-timezone';
import keys from '../../configs/keys';
import { IsNullOrUndefined } from './valid';

export const SetKeyIntoArray = (k, kIdx) => {
  k.key = kIdx;
  return k;
};

export const GetFormFlowString = (flow) => {
  const fullStageNames = []; //全部的關卡名稱
  for (let i = 0; i < flow.length; i++) {
    const f = flow[i];
    if (f.fixed === true || f.sign === true || (f.fixed === undefined && f.sign === undefined)) {
      fullStageNames.push(f.stage);
    }
  }
  return fullStageNames.join(' ➡️ ');
};

export const GetQueryString = () => {
  const rtnVal = {};
  const qryString = window.location.search.substring(1);
  const params = qryString.split('&');
  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=');
    rtnVal[pair[0]] = pair[1];
  }
  return rtnVal;
};

export const GenerateFormMode = (formId, applyUserId, signStatus, signUser, currentUserId) => {
  const result = {
    mode: '',
    canEdit: false,
    isCurrentSigner: false,
    button: {
      save: false,
      delete: false,
      submit: false,
      approve: false,
      return: false,
      void: false,
    },
  };

  if (Array.isArray(signUser)) {
    if (signUser.includes(currentUserId)) {
      result.isCurrentSigner = true;
    }
  } else {
    if (signUser === currentUserId) {
      result.isCurrentSigner = true;
    }
  }

  //decide mode.
  if (formId) {
    if (signStatus) {
      switch (signStatus) {
        case 'R':
          //退回至申請人(第一關)模式
          result.mode = 'R';
          if (result.isCurrentSigner) {
            result.canEdit = true;
          }
          break;
        default:
          result.mode = signStatus;
          break;
      }
    } else {
      //編輯模式
      result.mode = 'W';
      if (Array.isArray(applyUserId)) {
        if (applyUserId.includes(currentUserId)) {
          result.canEdit = true;
        }
      } else {
        if (applyUserId === currentUserId) {
          result.canEdit = true;
        }
      }
    }
  } else {
    //新增模式
    result.mode = 'A';
    result.canEdit = true;
  }

  //decide button display
  switch (result.mode) {
    case 'A':
      if (result.canEdit) {
        result.button.save = true;
        result.button.submit = true;
      }
      break;
    case 'W':
      if (result.canEdit) {
        result.button.save = true;
        result.button.delete = true;
        result.button.submit = true;
      }
      break;
    case 'S':
      if (result.isCurrentSigner) {
        result.button.approve = true;
        result.button.return = true;
      }
      break;
    case 'R':
      if (result.canEdit) {
        result.button.save = true;
        result.button.submit = true;
        result.button.void = true;
      }
      break;
    case 'X':
    case 'Z':
    default:
      break;
  }

  return result;
};

export const GenerateFormLink = (formCode, formId) => {
  let link = '';
  if (formCode && formId) {
    switch (formCode) {
      default:
        link = `/form/${formCode}?formId=${formId}`;
        break;
    }
  }
  return link;
};

export const ToMoment = (val) => {
  if (IsNullOrUndefined(val)) return null;

  moment.tz.setDefault(keys.timeZone);
  const m = moment(val);
  if (m.isValid()) return null;
  return m;
};

export const DiffMinutes = (start, end) => {
  const s = ToMoment(start);
  const e = ToMoment(end);

  if (s && e) {
    //秒數歸零
    s.second(0);
    e.second(0);

    return e.diff(s, 'minute');
  } else {
    return 0;
  }
};

export const UseDebounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const ParseJwt = (tokenString) => {
  try {
    return JSON.parse(Buffer.from(tokenString.split('.')[1], 'base64').toString());
  } catch (e) {
    return null;
  }
};
