import styled from 'styled-components';

const JTitle = styled.div`
  width: 100%;
  font-size: 30px;
  font-weight: 400;
  color: #73879c;
`;

export default JTitle;
