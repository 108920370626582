import React from 'react';
import moment from 'moment';
import { Col, DatePicker, Row, Select } from 'antd';
import JLayout from '../layout';

const { Option } = Select;

const HourSelector = ({ value, changeFn, style, disabled }) => {
  const hours = [];
  for (let i = 9; i <= 18; i++) {
    if (i < 10) {
      hours.push('0' + i.toString());
    } else {
      hours.push(i.toString());
    }
  }
  return (
    <Select
      value={value}
      onChange={changeFn}
      style={{ minWidth: '60px', width: '100%', ...style }}
      placeholder="時"
      disabled={disabled}
    >
      {hours.map((h, idx) => {
        return (
          <Option key={idx} value={h}>
            {h}
          </Option>
        );
      })}
    </Select>
  );
};

const MinuteSelector = ({ value, sixty = false, ten = false, changeFn, style, disabled }) => {
  let tmp = '';
  const options = [];
  if (sixty) {
    for (let i = 0; i < 60; i++) {
      if (i < 10) {
        tmp = '0' + i.toString();
      } else {
        tmp = i.toString();
      }
      options.push(
        <Option key={tmp} value={tmp}>
          {tmp}
        </Option>
      );
    }
  } else if (ten) {
    for (let i = 0; i < 60; i += 10) {
      if (i < 10) {
        tmp = '0' + i.toString();
      } else {
        tmp = i.toString();
      }
      options.push(
        <Option key={tmp} value={tmp}>
          {tmp}
        </Option>
      );
    }
  } else {
    options.push(
      <Option key="00" value="00">
        00
      </Option>
    );
    options.push(
      <Option key="30" value="30">
        30
      </Option>
    );
  }

  return (
    <Select
      value={value}
      onChange={changeFn}
      style={{ minWidth: '60px', width: '100%', ...style }}
      placeholder="分"
      disabled={disabled}
    >
      {options}
    </Select>
  );
};

const HandleChange = (currentValue, name, changeFn) => (val) => {
  if (!currentValue) {
    currentValue = moment().hour(9).minute(0).second(0);
  }

  let dateString = currentValue.format('YYYY-MM-DD');
  let hourString = currentValue.format('HH');
  let minuteString = currentValue.format('mm');

  switch (name) {
    case 'date':
      //"val" is moment.
      dateString = val.format('YYYY-MM-DD');
      break;
    case 'hour':
      hourString = val;
      break;
    case 'minute':
      minuteString = val;
      break;
    default:
      break;
  }

  if (hourString === '18') {
    minuteString = '00';
  }

  changeFn(moment(dateString + hourString + minuteString, 'YYYY-MM-DDHHmm'));
};

const JTimePicker = ({
  label,
  msg,
  required,
  sixty = false,
  ten = false,
  dateOnly = false,
  dateOnlyType,
  ...props
}) => {
  //format "value".
  let innerValue = undefined;
  if (props.value) {
    if (moment.isMoment(props.value)) {
      innerValue = props.value;
    } else {
      innerValue = moment(props.value);
    }

    if (dateOnly) {
      if (dateOnlyType === 'end') {
        innerValue.hours(18).minutes(0).seconds(0);
      } else {
        innerValue.hours(9).minutes(0).seconds(0);
      }
    }
  } else {
    if (dateOnly) {
      if (dateOnlyType === 'end') {
        innerValue = moment().hours(18).minutes(0).seconds(0);
      } else {
        innerValue = moment().hours(9).minutes(0).seconds(0);
      }
      props.onChange(innerValue);
    }
  }

  let inputStyle = { width: '100%' };
  if (msg) {
    inputStyle.border = '1px solid #f00';
    inputStyle.borderRadius = '4px';
  }

  return (
    <div>
      <JLayout label={label} msg={msg} required={required}>
        <Row align="middle" gutter={[5, 0]} style={{ width: '100%' }}>
          <Col span={12}>
            <DatePicker
              {...props}
              block
              allowClear={false}
              placeholder="請選擇日期與時間"
              style={inputStyle}
              value={innerValue}
              onChange={HandleChange(innerValue, 'date', props.onChange)}
            />
          </Col>
          <Col span={6}>
            <HourSelector
              style={inputStyle}
              value={innerValue && innerValue.format('HH')}
              changeFn={HandleChange(innerValue, 'hour', props.onChange)}
              disabled={dateOnly}
            />
          </Col>
          <Col span={1}>：</Col>
          <Col span={5}>
            <MinuteSelector
              sixty={sixty}
              ten={ten}
              style={inputStyle}
              value={innerValue && innerValue.format('mm')}
              changeFn={HandleChange(innerValue, 'minute', props.onChange)}
              disabled={dateOnly || (innerValue && innerValue.format('HH') === '18')}
            />
          </Col>
        </Row>
      </JLayout>
    </div>
  );
};

export default JTimePicker;
