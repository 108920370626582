import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import bn from 'bignumber.js';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, SyncOutlined } from '@ant-design/icons';
//
import { DateFormat, ListFormat, NumberFormat } from '../../modules/utils/format';
import { UserVacationRefType } from '../../modules/utils/list';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const SettingUserVacation = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [filterData, setFilterData] = useState({ vacationId: '', userId: user.userId });
  const [uvData, setUvData] = useState({}); //user vacation => uv
  const [uvList, setUvList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [vacationList, setVacationList] = useState([]);
  //
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [syncData, setSyncData] = useState({});
  //
  const isNew = useMemo(() => {
    return modalProps.type === 'add';
  }, [modalProps.type]);

  //============================================================
  useEffect(() => {
    axios
      .post(
        `/api/setting/verifyMenu`,
        { menuId: 'hrVacationHour' },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  const loadVacationList = useCallback(() => {
    axios
      .get('/api/setting/vacation/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setVacationList([
            { label: '不限', value: '' },
            ...res.data.result.vacations.map((v) => ({
              label: v.vacationName,
              value: v.vacationId,
            })),
          ]);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  const loadUserList = useCallback(() => {
    axios
      .get('/api/setting/user/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList([
            { label: '不限', value: '' },
            ...res.data.result.users.map((v) => ({
              label: v.userName,
              value: v.userId,
            })),
          ]);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadVacationList();
    loadUserList();
  }, [loadVacationList, loadUserList]);

  const loadUvList = useCallback(() => {
    if (filterData.vacationId || filterData.userId) {
      axios
        .get(
          `/api/setting/user/vacation/all?vId=${filterData.vacationId || ''}&uId=${filterData.userId || ''}`,
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            setUvList(res.data.result.userVacations.map(SetKeyIntoArray));
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    } else {
      Notify.warn('請選擇「假別」或「員工」作為查詢條件');
    }
  }, [user, filterData]);
  useEffect(() => {
    loadUvList();
  }, [loadUvList]);

  //============================================================
  const handleModalOpen = (type, record) => () => {
    if (record) {
      record.startDate = record.startDate && moment(record.startDate);
      record.endDate = record.endDate && moment(record.endDate);
      setUvData(record);
    }
    setModalProps({ open: true, type: type });
  };
  const handleModalClose = () => {
    setUvData({});
    setModalProps({ open: false, type: '' });
  };
  const handleFilterDataChange = (name) => (val) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: val,
    }));
  };
  const handleUvDataChange = (name) => (e) => {
    setUvData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleSave = () => {
    //valid
    const validResult = ValidFormData(uvData, [
      { name: 'vacationId', type: 'select', required: true },
      { name: 'userId', type: 'select', required: true },
      { name: 'availableHours', type: 'number', required: true },
      { name: 'startDate', type: 'date', required: true },
      { name: 'endDate', type: 'date', required: true },
    ]);
    if (!validResult.status) {
      setUvData(validResult.data);
      return;
    }

    //
    const saveData = { ...uvData };
    saveData.startDate = DateFormat(saveData.startDate);
    saveData.endDate = DateFormat(saveData.endDate);

    //
    axios
      .post('/api/setting/user/vacation/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadUvList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = (userVacationId) => () => {
    axios
      .post(
        '/api/setting/user/vacation/delete',
        { userVacationId },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除成功');
          loadUvList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleSyncModalOpen = () => {
    setSyncModalOpen(true);
  };
  const handleSyncModalClose = () => {
    setSyncModalOpen(false);
    setSyncData({});
  };
  const handleSyncDataChange = (name) => (e) => {
    setSyncData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleValidSyncData = () => {
    const validResult = ValidFormData(syncData, [{ name: 'year', type: 'date', required: true }]);
    if (validResult.status) {
      return true;
    } else {
      setSyncData(validResult.data);
      return false;
    }
  };
  const handleAnnualLeaveSync = () => {
    //
    if (!handleValidSyncData()) return;

    //
    const postData = {
      userId: syncData.userId || '',
      year: syncData.year.format('YYYY'),
    };
    axios
      .post('/api/setting/user/vacation/generate/annualLeave', postData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('轉入特休完成');
          loadUvList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handlePersonalLeaveSync = () => {
    //
    if (!handleValidSyncData()) return;

    //
    const postData = {
      userId: syncData.userId || '',
      year: syncData.year.format('YYYY'),
    };
    axios
      .post('/api/setting/user/vacation/generate/personalLeave', postData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('轉入事假完成');
          loadUvList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleSickLeaveSync = () => {
    //
    if (!handleValidSyncData()) return;

    //
    const postData = {
      userId: syncData.userId || '',
      year: syncData.year.format('YYYY'),
    };
    axios
      .post('/api/setting/user/vacation/generate/sickLeave', postData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('轉入病假完成');
          loadUvList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleMcLeaveSync = () => {
    //
    if (!handleValidSyncData()) return;

    //
    const postData = {
      userId: syncData.userId || '',
      year: syncData.year.format('YYYY'),
    };
    axios
      .post('/api/setting/user/vacation/generate/mcLeave', postData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('轉入生理假完成');
          loadUvList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>休假時數管理</Title>
            </Col>
            <Col>
              <SelectInline
                label="假別"
                options={vacationList}
                value={filterData.vacationId}
                onChange={handleFilterDataChange('vacationId')}
                style={{ minWidth: '200px' }}
              />
            </Col>
            <Col>
              <SelectInline
                label="員工"
                options={userList}
                value={filterData.userId}
                onChange={handleFilterDataChange('userId')}
                style={{ minWidth: '200px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Button size="large" icon={<SyncOutlined />} onClick={handleSyncModalOpen}>
                轉入時數
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                onClick={handleModalOpen('add', null)}
                icon={<PlusOutlined />}
              >
                新增休假時數
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={uvList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal width="800px" visible={modalProps.open}>
        <Row>
          <Col span={12}>
            <Display label="休假紀錄識別碼" value={uvData.userVacationId || '自動產生'} />
          </Col>
          <Col span={12} />
          <Col span={12}>
            {isNew ? (
              <Select
                required
                label="假別"
                options={vacationList}
                value={uvData.vacationId}
                msg={uvData.vacationId_msg}
                onChange={handleUvDataChange('vacationId')}
              />
            ) : (
              <Display label="假別" value={uvData.vacationName} />
            )}
          </Col>
          <Col span={12}>
            {isNew ? (
              <Select
                required
                label="員工"
                options={userList}
                value={uvData.userId}
                msg={uvData.userId_msg}
                onChange={handleUvDataChange('userId')}
              />
            ) : (
              <Display label="員工" value={uvData.userName} />
            )}
          </Col>
          <Col span={12}>
            <Input
              required
              label="可請時數"
              value={uvData.availableHours}
              msg={uvData.availableHours_msg}
              onChange={handleUvDataChange('availableHours')}
            />
          </Col>
          <Col span={12}>
            <Display label="已請時數" value={NumberFormat(uvData.usedHours)} />
          </Col>
          <Col span={12}>
            <DatePicker
              required
              label="開始日期"
              value={uvData.startDate}
              msg={uvData.startDate_msg}
              onChange={handleUvDataChange('startDate')}
            />
          </Col>
          <Col span={12}>
            <DatePicker
              required
              label="結束日期"
              value={uvData.endDate}
              msg={uvData.endDate_msg}
              onChange={handleUvDataChange('endDate')}
            />
          </Col>
          <Col span={24}>
            <Textarea label="備註" rows={5} value={uvData.memo} onChange={handleUvDataChange('memo')} />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="800px" visible={syncModalOpen}>
        <Title>轉入時數</Title>
        <Divider />
        <Row>
          <Col span={10}>
            <Select
              label="員工"
              options={userList}
              value={syncData.userId}
              onChange={handleSyncDataChange('userId')}
            />
          </Col>
          <Col span={10}>
            <DatePicker
              required
              label="轉入年份"
              picker="year"
              value={syncData.year}
              msg={syncData.year_msg}
              onChange={handleSyncDataChange('year')}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button size="large" type="primary" onClick={handleAnnualLeaveSync}>
              特休轉入
            </Button>
          </Col>
          <Col>
            <Button size="large" type="primary" onClick={handlePersonalLeaveSync}>
              事假轉入
            </Button>
          </Col>
          <Col>
            <Button size="large" type="primary" onClick={handleSickLeaveSync}>
              病假轉入
            </Button>
          </Col>
          <Col>
            <Button size="large" type="primary" onClick={handleMcLeaveSync}>
              生理假轉入
            </Button>
          </Col>
        </Row>
        <br />
        <Divider />
        <Row justify="end">
          <Col>
            <Button onClick={handleSyncModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '員工姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 120,
    },
    {
      title: '假別名稱',
      dataIndex: 'vacationName',
      key: 'vacationName',
    },
    {
      title: '開始日期',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 120,
      render: (value) => DateFormat(value),
    },
    {
      title: '結束日期',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 120,
      render: (value) => DateFormat(value),
    },
    {
      title: '可用時數',
      dataIndex: 'availableHours',
      key: 'availableHours',
      width: 120,
      render: (value) => `${value} ( ${bn(value).div(8).dp(1)}天 )`,
    },
    {
      title: '已用時數',
      dataIndex: 'usedHours',
      key: 'usedHours',
      width: 120,
      render: (value) => `${value} ( ${bn(value).div(8).dp(1)}天 )`,
    },
    {
      title: '來源',
      dataIndex: 'refSourceType',
      key: 'refSourceType',
      width: 120,
      render: (value) => ListFormat(UserVacationRefType, value),
    },
    {
      title: '備註',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={openFn('edit', record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確認刪除此休假紀錄？"
              placement="topRight"
              okText="確定刪除"
              cancelText="取消"
              onConfirm={deleteFn(record.userVacationId)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

export default SettingUserVacation;
