import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Textarea from '../../components/Textarea';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip, Spin } from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
  MailOutlined,
  SaveOutlined,
  SelectOutlined,
} from '@ant-design/icons';
//
import { DateFormat, NumberFormat } from '../../modules/utils/format';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const SettingPayrollSearch = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [userList, setUserList] = useState([]);
  const [searchUserId, setSearchUserId] = useState('');
  const [searchYearMon, setSearchYearMon] = useState('');
  const [payrollList, setPayrollList] = useState([]);
  const [payYearMonList, setPayYearMonList] = useState([]);
  //
  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [configData, setConfigData] = useState({});
  const [generateModalOpen, setGenerateModalOpen] = useState(false);
  const [generateData, setGenerateData] = useState({ payYearMon: moment().format('YYYYMM'), userId: '' });
  const [mailModalOpen, setMailModalOpen] = useState(false);
  const [mailData, setMailData] = useState({ payYearMon: moment().format('YYYYMM'), userId: '' });
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [payData, setPayData] = useState({
    payYearMon: moment().format('YYYYMM'),
    userId: '',
    payDate: moment(),
  });
  const [reimburseModalOpen, setReimburseModalOpen] = useState(false);
  const [reimburseData, setReimburseData] = useState({
    payYearMon: moment().format('YYYYMM'),
    userId: '',
    payDate: moment(),
  });
  //
  const [loading, setLoading] = useState(false);
  //
  const viewList = useMemo(() => {
    if (searchUserId) return payrollList.filter((v) => v.userId === searchUserId);
    else return payrollList;
  }, [payrollList, searchUserId]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrPayroll' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load user list
  useEffect(() => {
    axios
      .get('/api/setting/user/salary', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList([
            { label: '不限', value: '' },
            ...res.data.result.users.map((v) => ({
              label: v.userName,
              value: v.userId,
            })),
          ]);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  const loadFilterList = useCallback(() => {
    axios
      .get('/api/payroll/filter', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setPayYearMonList(
            res.data.result.filters.map((u) => ({ label: u.payYearMon, value: u.payYearMon }))
          );
          if (res.data.result.filters.length > 0) {
            setSearchYearMon(res.data.result.filters[0].payYearMon);
          }
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);

  //load payroll list
  const loadPayrollList = useCallback(() => {
    axios
      .get(`/api/payroll/list?payYearMon=${searchYearMon}`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setPayrollList(res.data.result.payrolls.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user, searchYearMon]);

  useEffect(() => {
    loadFilterList();
  }, [loadFilterList]);

  useEffect(() => {
    loadPayrollList();
  }, [loadPayrollList]);

  //load config data
  const loadConfig = () => {
    axios
      .get(`/api/setting/payroll/all`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setConfigData(res.data.result.config);
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleSearchUserIdChange = (val) => {
    setSearchUserId(val);
  };
  const handleSearchYearMonChange = (date) => {
    setSearchYearMon(date);
  };
  const handleDelete = (payrollId) => () => {
    axios
      .post('/api/payroll/delete', { payrollId }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除完成');
          loadFilterList();
          loadPayrollList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleConfigModalOpen = () => {
    loadConfig();
    setConfigModalOpen(true);
  };
  const handleConfigModalClose = () => {
    setConfigData({});
    setConfigModalOpen(false);
  };
  const handleConfigDataChange = (name) => (e) => {
    setConfigData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleConfigSave = () => {
    axios
      .post('/api/setting/payroll/save', configData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          handleConfigModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleGenerateModalOpen = () => {
    setGenerateModalOpen(true);
  };
  const handleGenerateModalClose = () => {
    setGenerateModalOpen(false);
  };
  const handleGenerateDataChange = (name) => (e) => {
    setGenerateData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleGenerateClick = () => {
    //valid
    const validResult = ValidFormData(generateData, [{ name: 'payYearMon', type: 'input', required: true }]);
    if (!validResult.status) {
      setGenerateData(validResult.data);
      return;
    }
    setLoading(true);
    axios
      .post('/api/payroll/generate', generateData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('產生完成');
          loadFilterList();
          loadPayrollList();
          handleGenerateModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      })
      .then(() => {
        setLoading(false);
      });
  };

  //============================================================
  const handleMailModalOpen = () => {
    setMailModalOpen(true);
  };
  const handleMailModalClose = () => {
    setMailModalOpen(false);
  };
  const handleMailDataChange = (name) => (e) => {
    setMailData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleMailClick = () => {
    //valid
    const validResult = ValidFormData(mailData, [{ name: 'payYearMon', type: 'input', required: true }]);
    if (!validResult.status) {
      setMailData(validResult.data);
      return;
    }
    setLoading(true);
    axios
      .post('/api/payroll/send', mailData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('發送完成');
          loadPayrollList();
          handleMailModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      })
      .then(() => {
        setLoading(false);
      });
  };

  //============================================================
  const handlePayModalOpen = () => {
    setPayModalOpen(true);
  };
  const handlePayModalClose = () => {
    setPayModalOpen(false);
  };
  const handlePayDataChange = (name) => (e) => {
    setPayData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handlePayClick = () => {
    //valid
    const validResult = ValidFormData(payData, [{ name: 'payYearMon', type: 'input', required: true }]);
    if (!validResult.status) {
      setPayData(validResult.data);
      return;
    }
    setLoading(true);
    axios
      .post('/api/payroll/updatePayDate', payData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('薪資付款日更新完成');
          loadPayrollList();
          handlePayModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      })
      .then(() => {
        setLoading(false);
      });
  };

  //============================================================
  const handleReimburseModalOpen = () => {
    setReimburseModalOpen(true);
  };
  const handleReimburseModalClose = () => {
    setReimburseModalOpen(false);
    setReimburseData((prev) => ({
      ...prev,
      file: '',
      file_msg: '',
    }));
  };
  const handleReimburseDataChange = (name) => (e) => {
    setReimburseData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleReimburseClick = () => {
    //valid
    const validResult = ValidFormData(reimburseData, [{ name: 'payYearMon', type: 'input', required: true }]);
    if (!validResult.status) {
      setReimburseData(validResult.data);
      return;
    }
    setLoading(true);
    axios
      .post('/api/payroll/updateReimburseDate', reimburseData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('代墊費用付款日更新完成');
          loadPayrollList();
          handleReimburseModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      })
      .then(() => {
        setLoading(false);
      });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>薪資發放作業</Title>
            </Col>
            <Col>
              <SelectInline
                label="薪資年月"
                options={payYearMonList}
                value={searchYearMon}
                onChange={handleSearchYearMonChange}
                style={{ minWidth: '150px' }}
              />
            </Col>
            <Col>
              <SelectInline
                canSearch
                label="人員"
                options={userList}
                value={searchUserId}
                onChange={handleSearchUserIdChange}
                style={{ minWidth: '150px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle">
            <Col>
              <Button size="large" icon={<EditOutlined />} onClick={handleConfigModalOpen}>
                備註維護
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                icon={<FileDoneOutlined />}
                onClick={handleGenerateModalOpen}
              >
                薪資結算
              </Button>
            </Col>

            <Col>
              <Button size="large" type="primary" icon={<MailOutlined />} onClick={handleMailModalOpen}>
                信件發送
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ float: 'right' }}>
        <Col>
          <Row gutter={[10, 10]}>
            <Col>
              <Button size="large" danger icon={<CheckOutlined />} onClick={handlePayModalOpen}>
                薪資付款日
              </Button>
            </Col>
            <Col>
              <Button size="large" danger icon={<CheckOutlined />} onClick={handleReimburseModalOpen}>
                代墊費用付款日
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal width="800px" visible={configModalOpen}>
        <Title>備註維護</Title>
        <Divider />
        <Row>
          <Col span={24}>
            <Textarea
              required
              rows={10}
              label="薪資單備註"
              value={configData.memo}
              onChange={handleConfigDataChange('memo')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleConfigSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleConfigModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="800px" visible={generateModalOpen}>
        <Spin spinning={loading}>
          <Title>薪資結算</Title>
          <Divider />
          <Row>
            <Col span={10}>
              <Input
                required
                label="薪資年月"
                value={generateData.payYearMon}
                msg={generateData.payYearMon_msg}
                onChange={handleGenerateDataChange('payYearMon')}
              />
            </Col>
            <Col span={10}>
              <Select
                label="員工"
                options={userList}
                value={generateData.userId}
                onChange={handleGenerateDataChange('userId')}
              />
            </Col>
          </Row>
          <Row gutter={[20, 10]}>
            <Col span={24}>
              <strong style={{ color: '#D73141' }}>
                ※ 結算動作會直接覆蓋相同薪資年月的資料，不會檢核是否已確認，請注意！
              </strong>
            </Col>
          </Row>
          <br />
          <Divider />
          <Row justify="end" align="middle">
            <Col>
              <Button size="large" type="primary" onClick={handleGenerateClick}>
                結算
              </Button>
            </Col>
            <Col>
              <Button onClick={handleGenerateModalClose}>關閉</Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
      <Modal width="800px" visible={mailModalOpen}>
        <Spin spinning={loading}>
          <Title>信件發送</Title>
          <Divider />
          <Row>
            <Col span={10}>
              <Input
                required
                label="薪資年月"
                value={mailData.payYearMon}
                msg={mailData.payYearMon_msg}
                onChange={handleMailDataChange('payYearMon')}
              />
            </Col>
            <Col span={10}>
              <Select
                label="員工"
                options={userList}
                value={mailData.userId}
                onChange={handleMailDataChange('userId')}
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={[20, 10]}>
            <Col span={24}>
              <strong style={{ color: '#D73141' }}>※ 通知信件僅會發送已確認的資料</strong>
            </Col>
          </Row>
          <Row justify="end" align="middle">
            <Col>
              <Button size="large" type="primary" onClick={handleMailClick}>
                發送
              </Button>
            </Col>
            <Col>
              <Button onClick={handleMailModalClose}>關閉</Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
      <Modal width="800px" visible={payModalOpen}>
        <Spin spinning={loading}>
          <Title>薪資付款日</Title>
          <Divider />
          <Row>
            <Col span={8}>
              <Input
                required
                label="薪資年月"
                value={payData.payYearMon}
                msg={payData.payYearMon_msg}
                onChange={handlePayDataChange('payYearMon')}
              />
            </Col>
            <Col span={8}>
              <Select
                label="員工"
                options={userList}
                value={payData.userId}
                onChange={handlePayDataChange('userId')}
              />
            </Col>
            <Col span={16}>
              <DatePicker
                required
                label="薪資付款日"
                picker="date"
                allowClear={false}
                value={payData.payDate}
                onChange={handlePayDataChange('payDate')}
              />
            </Col>
          </Row>
          <Row gutter={[20, 10]}>
            <Col span={24}>
              <strong style={{ color: '#D73141' }}>※ 該動作會直接更新選擇條件下已確認的資料</strong>
            </Col>
          </Row>
          <br />
          <Divider />
          <Row justify="end" align="middle">
            <Col>
              <Button size="large" type="primary" onClick={handlePayClick}>
                付款日期確認
              </Button>
            </Col>
            <Col>
              <Button onClick={handlePayModalClose}>關閉</Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
      <Modal width="800px" visible={reimburseModalOpen}>
        <Spin spinning={loading}>
          <Title>代墊費用付款日</Title>
          <Divider />
          <Row>
            <Col span={8}>
              <Input
                required
                label="薪資年月"
                value={reimburseData.payYearMon}
                msg={reimburseData.payYearMon_msg}
                onChange={handleReimburseDataChange('payYearMon')}
              />
            </Col>
            <Col span={8}>
              <Select
                required
                label="員工"
                options={userList}
                value={reimburseData.userId}
                onChange={handleReimburseDataChange('userId')}
              />
            </Col>
            <Col span={8}>
              <DatePicker
                required
                label="代墊費用付款日"
                picker="date"
                allowClear={false}
                value={reimburseData.reimburseDate}
                onChange={handleReimburseDataChange('reimburseDate')}
              />
            </Col>
          </Row>
          <Row gutter={[20, 10]}>
            <Col span={24}>
              <strong style={{ color: '#D73141' }}>
                ※ 該動作會直接更新選擇條件下已確認的資料，並且將日期同步至薪資單內的所有請款單
              </strong>
            </Col>
          </Row>
          <br />
          <Divider />
          <Row justify="end" align="middle">
            <Col>
              <Button size="large" type="primary" onClick={handleReimburseClick}>
                付款日期確認
              </Button>
            </Col>
            <Col>
              <Button onClick={handleReimburseModalClose}>關閉</Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </div>
  );
};

const GenerateColumns = (deleteFn) => {
  return [
    {
      title: '員工姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
    },
    {
      title: '薪資年月',
      dataIndex: 'payYearMon',
      key: 'payYearMon',
      width: 150,
    },
    {
      title: '確認日期',
      dataIndex: 'confirmDate',
      key: 'confirmDate',
      width: 150,
      render: (value) => DateFormat(value),
    },
    {
      title: '薪資付款日',
      dataIndex: 'payDate',
      key: 'payDate',
      width: 150,
      render: (value) => DateFormat(value),
    },
    {
      title: '代墊費用付款日',
      dataIndex: 'reimburseDate',
      key: 'reimburseDate',
      width: 150,
      render: (value, record) => {
        return <Row>{record.reimburseFlag === 'Y' ? DateFormat(value) : '無請款單資料'}</Row>;
      },
    },
    {
      title: '發信次數',
      dataIndex: 'count',
      key: 'count',
      width: 150,
      render: (value) => NumberFormat(value),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (value, record) => {
        return (
          <Row justify="center">
            {!record.confirmDate && (
              <Col>
                <Tooltip title="編輯">
                  <Link to={'/setting/payroll?payrollId=' + record.payrollId}>
                    <Button icon={<EditOutlined />} />
                  </Link>
                </Tooltip>
              </Col>
            )}
            {!record.confirmDate && (
              <Col>
                <Popconfirm
                  title="是否確認刪除此薪資明細？"
                  placement="topRight"
                  okText="確定"
                  cancelText="取消"
                  onConfirm={deleteFn(record.payrollId)}
                >
                  <Tooltip title="刪除">
                    <Button icon={<DeleteOutlined />} />
                  </Tooltip>
                </Popconfirm>
              </Col>
            )}
            {record.confirmDate && (
              <Col>
                <Tooltip title="開啟">
                  <Link to={'/setting/payroll?payrollId=' + record.payrollId}>
                    <Button icon={<SelectOutlined />} />
                  </Link>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];
};

export default SettingPayrollSearch;
