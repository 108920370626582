import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button, Tree } from 'antd';
import { ApartmentOutlined, ClearOutlined, SaveOutlined } from '@ant-design/icons';
//
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';
const { TreeNode } = Tree;
const GenerateTreeNode = (source, node) => {
  if (!node) return null;

  const childNode = source.filter((s) => s.rootDeptId === node.deptId);
  if (childNode.length > 0) {
    return (
      <TreeNode title={node.deptName} key={node.deptId}>
        {childNode.map((m) => {
          return GenerateTreeNode(source, m);
        })}
      </TreeNode>
    );
  } else {
    return <TreeNode switcherIcon={<ApartmentOutlined />} title={node.deptName} key={node.deptId} />;
  }
};
const FindAllChildren = (source, currentId) => {
  const result = [currentId];

  const tmp = source.filter((s) => result.includes(s.rootDeptId)).map((m) => m.deptId);
  if (tmp.length > 0) {
    result.push(
      ...tmp.map((mId) => {
        return FindAllChildren(source, mId);
      })
    );
  }

  return result;
};

const SettingDepartment = () => {
  const { user } = useProvided(useAuth);
  const [deptData, setDeptData] = useState({});
  const [deptList, setDeptList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [redirectFlag, setRedirectFlag] = useState('');

  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrDept' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false)
            setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  const rootList = useMemo(() => {
    return deptList
      .filter((d) => !FindAllChildren(deptList, deptData.deptId).flat().includes(d.deptId))
      .map((d) => {
        return { label: d.deptName, value: d.deptId };
      });
  }, [deptList, deptData.deptId]);

  //load dept data.
  const loadDeptList = useCallback(() => {
    axios
      .get(`/api/setting/dept/all`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setDeptList(res.data.result.depts.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);
  useEffect(() => {
    loadDeptList();
  }, [loadDeptList]);

  //load user data.
  const loadUserList = useCallback(() => {
    axios
      .get(`/api/setting/user/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList(res.data.result.users.map((u) => ({ label: u.userName, value: u.userId })));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);
  useEffect(() => {
    loadUserList();
  }, [loadUserList]);

  const handleDeptDataChange = (name) => (e) => {
    setDeptData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleTreeSelect = (selectedKeys) => {
    const t = deptList.find((d) => d.deptId === selectedKeys[0]);
    if (t && t.deptId) {
      setDeptData(t);
    } else {
      setDeptData({});
    }
  };
  const handleClear = () => {
    setDeptData({});
  };
  const handleSave = () => {
    //valid
    const validResult = ValidFormData(deptData, [
      { name: 'deptName', type: 'input', required: true },
      { name: 'rootDeptId', type: 'select', required: true },
    ]);
    if (!validResult.status) {
      setDeptData(validResult.data);
      return;
    }

    //
    const saveData = {
      deptId: deptData.deptId || null,
      deptName: deptData.deptName,
      rootDeptId: deptData.rootDeptId,
      leader: deptData.leader || null,
      memo: deptData.memo || null,
    };
    axios
      .post('/api/setting/dept/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          loadDeptList();
          handleClear();
          Notify.success('儲存完成');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  };

  const treeDOM = useMemo(() => {
    return GenerateTreeNode(
      deptList,
      deptList.find((d) => !d.rootDeptId)
    );
  }, [deptList]);

  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Title>部門管理</Title>
      <Divider />
      <Row gutter={[20, 10]}>
        <Col xs={12}>
          <Tree
            showLine
            expandedKeys={deptList.map((d) => d.deptId)}
            onSelect={handleTreeSelect}
            style={{ minHeight: '620px', padding: '10px 0px 0px 10px' }}
          >
            {treeDOM}
          </Tree>
        </Col>
        <Col xs={12} xxl={8}>
          <Row>
            <Col span={16}>
              <Display label="公司名稱" value={user.comName} />
            </Col>
            <Col span={12} />
            <Col span={16}>
              <Display label="部門識別碼" value={deptData.deptId} />
            </Col>
            <Col span={24}>
              <Input
                label="部門名稱"
                value={deptData.deptName}
                msg={deptData.deptName_msg}
                onChange={handleDeptDataChange('deptName')}
              />
            </Col>
            <Col span={24}>
              <Input
                label="部門名稱 (英文)"
                value={deptData.deptNameEn}
                onChange={handleDeptDataChange('deptNameEn')}
              />
            </Col>
            <Col span={24}>
              <Select
                canSearch
                label="父部門"
                options={rootList}
                value={deptData.rootDeptId}
                msg={deptData.rootDeptId_msg}
                onChange={handleDeptDataChange('rootDeptId')}
              />
            </Col>
            <Col span={24}>
              <Select
                canSearch
                label="部門主管"
                options={userList}
                value={deptData.leader}
                onChange={handleDeptDataChange('leader')}
              />
            </Col>
            <Col span={24}>
              <Textarea rows={3} label="備註" value={deptData.memo} onChange={handleDeptDataChange('memo')} />
            </Col>
          </Row>
          <Divider />
          <Row justify="end" align="middle">
            <Col>
              <Button type="primary" size="large" onClick={handleSave} icon={<SaveOutlined />}>
                {deptData.deptId ? '更新' : '新增'}
              </Button>
            </Col>
            <Col>
              <Button onClick={handleClear} icon={<ClearOutlined />}>
                清空
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SettingDepartment;
