import moment from 'moment-timezone';
import keys from '../../configs/keys';
import { IsNullOrUndefined } from './valid';

export const DateFormat = (val) => {
  if (IsNullOrUndefined(val)) return '';

  moment.tz.setDefault(keys.timeZone);
  const m = moment(val);
  if (!m.isValid()) {
    return '';
  } else {
    return m.format('YYYY-MM-DD');
  }
};

export const DateTimeFormat = (val) => {
  if (IsNullOrUndefined(val)) return '';

  moment.tz.setDefault(keys.timeZone);
  const m = moment(val);
  if (!m.isValid()) {
    return '';
  } else {
    return m.format('YYYY-MM-DD HH:mm:ss');
  }
};

export const DateTimeWithZoneFormat = (val) => {
  if (IsNullOrUndefined(val)) return '';

  moment.tz.setDefault(keys.timeZone);
  const m = moment(val);
  if (!m.isValid()) {
    return '';
  } else {
    return m.format('YYYY-MM-DD HH:mm:ssZ');
  }
};

export const ListFormat = (options, value) => {
  if (IsNullOrUndefined(options) || IsNullOrUndefined(value)) return '';

  if (!Array.isArray(options) || options.length <= 0) return '';

  let name = '';
  let item = null;
  if (Array.isArray(value)) {
    if (value.length > 0) {
      const items = [];
      for (let i = 0; i < value.length; i++) {
        item = options.find((o) => o.value === value[i]);
        if (item) {
          items.push(item.label);
        }
      }
      name = items.join(', ');
    }
  } else {
    item = options.find((o) => o.value === value);
    if (item) {
      name = item.label;
    }
  }
  return name;
};

export const IntegerFormat = (value, prefix, postfix) => {
  if (value || value === 0) {
    let content = Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    });

    return `${prefix || ''}${content} ${postfix || ''}`;
  } else {
    return '--';
  }
};

export const MinuteFormat = (val) => {
  if (IsNullOrUndefined(val)) return '';

  moment.tz.setDefault(keys.timeZone);
  const m = moment(val);
  if (!m.isValid()) {
    return '';
  } else {
    return m.format('HH:mm');
  }
};

export const MonthFormat = (val) => {
  if (IsNullOrUndefined(val)) return '';

  moment.tz.setDefault(keys.timeZone);
  const MONTH_FORMAT = 'YYYY-MM';
  const m = moment(val, MONTH_FORMAT);
  if (!m.isValid()) {
    return '';
  } else {
    return m.format(MONTH_FORMAT);
  }
};

export const NumberFormat = (value, prefix, postfix) => {
  if (value || value === 0) {
    let content = Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });

    return `${prefix || ''}${content} ${postfix || ''}`;
  } else {
    return '--';
  }
};

export const PercentFormat = (value) => {
  if (value || value === 0) {
    return Number(value).toLocaleString(undefined, {
      style: 'percent',
      maximumFractionDigits: 2,
    });
  } else {
    return '--';
  }
};

export const UploadFormat = (annexies) => {
  if (Array.isArray(annexies)) {
    return annexies.map((a, idx) => {
      return {
        uid: a.annexId,
        name: a.annexName,
        status: 'done',
        url: '#',
        ...a,
      };
    });
  } else {
    return [];
  }
};

export const WeekFormat = (dayOfweek) => {
  let weekName = '';
  switch (dayOfweek) {
    case '1':
      weekName = '一';
      break;
    case '2':
      weekName = '二';
      break;
    case '3':
      weekName = '三';
      break;
    case '4':
      weekName = '四';
      break;
    case '5':
      weekName = '五';
      break;
    case '6':
      weekName = '六';
      break;
    case '7':
      weekName = '日';
      break;
    default:
      break;
  }
  return weekName;
};
