import { message } from 'antd';

const ParseMsg = (obj) => {
  if (obj && obj.message) {
    return obj.message;
  } else {
    return obj;
  }
};

class Nofity {
  success = (msg) => {
    message.success({ content: ParseMsg(msg), className: 'j-message-success' });
  };

  info = (msg) => {
    message.info({ content: ParseMsg(msg), className: 'j-message-info' });
  };

  warn = (msg) => {
    message.warning({ content: ParseMsg(msg), className: 'j-message-warn' });
  };

  error = (msg) => {
    message.error({ content: ParseMsg(msg), className: 'j-message-error' });
  };
}

export default new Nofity();
