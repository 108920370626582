import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import DatePickerInline from '../../components/DatePicker/inline';
import Divider from '../../components/Divider';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
//
import { DateFormat, DateTimeFormat } from '../../modules/utils/format';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const SettingClockRecord = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [userList, setUserList] = useState([]);
  const [searchDate, setSearchDate] = useState(moment());
  const [searchUserId, setSearchUserId] = useState('');
  const [clockList, setClockList] = useState([]);
  //
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [exportData, setExporteData] = useState({ month: moment(), userId: '' });
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState({ date: moment(), userId: '' });
  //
  const viewList = useMemo(() => {
    if (searchUserId) {
      return clockList.filter((s) => s.userId === searchUserId);
    } else {
      return clockList;
    }
  }, [searchUserId, clockList]);

  //===============================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'hrClock' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load users
  useEffect(() => {
    axios
      .get(`/api/setting/user/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList([
            { label: '不限', value: '' },
            ...res.data.result.users.map((m) => {
              return {
                label: m.userName,
                value: m.userId,
              };
            }),
          ]);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load all users' clock record
  const loadClockRecord = useCallback(() => {
    const date = DateFormat(searchDate);
    axios
      .get(`/api/setting/clock/all?date=${date}`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setClockList(res.data.result.clocks.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user, searchDate]);

  useEffect(() => {
    loadClockRecord();
  }, [loadClockRecord]);

  //===============================================
  const handleSearchDateChange = (date) => {
    setSearchDate(date);
  };
  const handleSearchUserIdChange = (val) => {
    setSearchUserId(val);
  };

  //===============================================
  const handleExportModalOpen = () => {
    setExportModalOpen(true);
  };
  const handleExportModalClose = () => {
    setExportModalOpen(false);
  };
  const handleExportDataChange = (name) => (e) => {
    setExporteData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleExportCsv = () => {
    let month = moment(exportData.month).format('YYYY-MM');
    let title = `${user.comName}股份有限公司_${month.substring(0, 4)}年${month.substring(
      5,
      7
    )}月份出勤紀錄表`;
    axios
      .post(
        '/api/setting/clock/export',
        {
          title: `${user.comName}股份有限公司 「@@userName」 ${month.substring(0, 4)}年${month.substring(
            5,
            7
          )}月份出勤紀錄`,
          month: month,
          userId: exportData.userId,
        },
        { headers: { Authorization: user.token }, responseType: 'blob' }
      )
      .then((res) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${title}.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
          handleExportModalClose();
        } else {
          Notify.warn('下載出勤紀錄失敗');
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //===============================================
  //   const handleUpdateModalOpen = () => {
  //     setUpdateModalOpen(true);
  //   };
  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
  };
  const handleUpdateDataChange = (name) => (e) => {
    setUpdateData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleUpdate = () => {
    //
    axios
      .post(
        '/api/setting/clock/update',
        { userId: updateData.userId, date: moment(updateData.date).format('YYYY-MM-DD') },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('更新完成');
          loadClockRecord();
          handleUpdateModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  const handleDelete = () => {};
  //===============================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>打卡紀錄查詢</Title>
            </Col>
            <Col>
              <DatePickerInline
                label="打卡日期"
                allowClear={false}
                value={searchDate}
                onChange={handleSearchDateChange}
              />
            </Col>
            <Col>
              <SelectInline
                canSearch
                label="人員"
                options={userList}
                value={searchUserId}
                onChange={handleSearchUserIdChange}
                style={{ minWidth: '200px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle">
            <Col>
              <Button size="large" type="primary" icon={<SyncOutlined />} onClick={handleExportModalOpen}>
                匯出出勤紀錄
              </Button>
            </Col>
            {/* <Col>
                            <Button size="large" type="primary" icon={<EditOutlined />} onClick={handleUpdateModalOpen}>
                                更新打卡紀錄
                            </Button>
                        </Col> */}
          </Row>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleDelete)}
        dataSource={viewList}
        scroll={{ x: 800, y: 'calc(100vh - 135px)' }}
      />
      <Modal width="600px" visible={exportModalOpen}>
        <Title>匯出出勤紀錄</Title>
        <Divider />
        <Row>
          <Col span={10}>
            <DatePicker
              required
              label="匯出月份"
              picker="month"
              value={exportData.month}
              msg={exportData.month_msg}
              onChange={handleExportDataChange('month')}
            />
          </Col>
          <Col span={10}>
            <Select
              label="員工"
              options={userList}
              value={exportData.userId}
              onChange={handleExportDataChange('userId')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleExportCsv}>
              匯出
            </Button>
          </Col>
          <Col>
            <Button onClick={handleExportModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="600px" visible={updateModalOpen}>
        <Title>更新打卡紀錄</Title>
        <Divider />
        <Row>
          <Col span={10}>
            <DatePicker
              required
              label="更新日期"
              value={updateData.date}
              msg={updateData.date_msg}
              onChange={handleUpdateDataChange('date')}
            />
          </Col>
          <Col span={10}>
            <Select
              label="員工"
              options={userList}
              value={updateData.userId}
              onChange={handleUpdateDataChange('userId')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleUpdate}>
              更新
            </Button>
          </Col>
          <Col>
            <Button onClick={handleUpdateModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const GenerateColumns = (deleteFn) => {
  return [
    {
      title: '打卡日期',
      dataIndex: 'clockDate',
      key: 'clockDate',
      width: 200,
      render: (value) => DateFormat(value),
    },
    {
      title: '員工姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 200,
    },
    {
      title: '上班時間',
      dataIndex: 'clockIn',
      key: 'clockIn',
      width: 200,
      render: (value, record) => DateTimeFormat(record.clockDate + ' ' + value),
    },
    {
      title: '下班時間',
      dataIndex: 'clockOut',
      key: 'clockOut',
      width: 200,
      render: (value, record) => DateTimeFormat(record.clockDate + ' ' + value),
    },
  ];
};

export default SettingClockRecord;
