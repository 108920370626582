import {
  HomeOutlined,
  FormOutlined,
  ApartmentOutlined,
  TeamOutlined,
  MenuOutlined,
  UnorderedListOutlined,
  UserOutlined,
  CalendarOutlined,
  FileOutlined,
  FieldTimeOutlined,
  ProfileOutlined,
  DollarOutlined,
} from '@ant-design/icons';

export const MenuData = [
  {
    key: 'home',
    title: '首頁',
    iconType: <HomeOutlined />,
    link: '/home',
    submenu: null,
  },
  {
    key: 'leaveCalendar',
    title: '行事曆',
    iconType: <CalendarOutlined />,
    link: '/leaveCalendar',
    submenu: null,
  },
  {
    key: 'hrUser',
    title: '個人資料管理',
    iconType: <UserOutlined />,
    link: '/setting/userinfo',
    submenu: null,
  },
  {
    key: 'hrTrello',
    title: 'Trello管理',
    iconType: <ProfileOutlined />,
    link: '/setting/trello',
    submenu: null,
  },
  {
    key: 'ef',
    title: '電子表單',
    iconType: <MenuOutlined />,
    link: '',
    submenu: [
      {
        key: 'efVacation',
        title: '請假單',
        iconType: <FormOutlined />,
        link: '/form/vacation/search',
        submenu: null,
      },
      {
        key: 'efSeal',
        title: '用印申請單',
        iconType: <FormOutlined />,
        link: '/form/seal/search',
        submenu: null,
      },
      {
        key: 'efInvoice',
        title: '請款單',
        iconType: <FormOutlined />,
        link: '/form/invoice/search',
        submenu: null,
      },
      {
        key: 'efCharge',
        title: '收款單',
        iconType: <FormOutlined />,
        link: '/form/charge/search',
        submenu: null,
      },
    ],
  },
  {
    key: 'hr',
    title: '公司管理',
    iconType: <MenuOutlined />,
    link: '',
    submenu: [
      {
        key: 'hrDept',
        title: '部門管理',
        iconType: <ApartmentOutlined />,
        link: '/setting/department',
        submenu: null,
      },
      {
        key: 'hrEmployee',
        title: '員工管理',
        iconType: <TeamOutlined />,
        link: '/setting/employee',
        submenu: null,
      },
      {
        key: 'hrSchedule',
        title: '排班管理',
        iconType: <CalendarOutlined />,
        link: '/setting/schedule',
        submenu: null,
      },
      {
        key: 'hrVacationHour',
        title: '休假時數管理',
        iconType: <FieldTimeOutlined />,
        link: '/setting/userVacation',
        submenu: null,
      },
      {
        key: 'hrVacation',
        title: '假別管理',
        iconType: <FileOutlined />,
        link: '/setting/vacation',
        submenu: null,
      },
      {
        key: 'hrRole',
        title: '角色管理',
        iconType: <UserOutlined />,
        link: '/setting/role',
        submenu: null,
      },
      {
        key: 'hrInvoice',
        title: '請款項目管理',
        iconType: <ProfileOutlined />,
        link: '/setting/invoice',
        submenu: null,
      },
      {
        key: 'hrPayroll',
        title: '薪資發放作業',
        iconType: <DollarOutlined />,
        link: '/setting/payroll/search',
        submenu: null,
      },
      {
        key: 'hrUserSalary',
        title: '薪資管理',
        iconType: <TeamOutlined />,
        link: '/setting/userSalary',
        submenu: null,
      },
      {
        key: 'hrInsurance',
        title: '勞健保級距設定',
        iconType: <ProfileOutlined />,
        link: '/setting/insurance',
        submenu: null,
      },
      {
        key: 'hrSalaryWHTax',
        title: '薪資所得扣繳稅額設定',
        iconType: <ProfileOutlined />,
        link: '/setting/salaryWithholdingTax',
        submenu: null,
      },
      {
        key: 'hrApproval',
        title: '核決權限管理',
        iconType: <ApartmentOutlined />,
        link: '/setting/approval',
        submenu: null,
      },
      {
        key: 'hrFlow',
        title: '流程管理',
        iconType: <ApartmentOutlined />,
        link: '/setting/flow',
        submenu: null,
      },
      {
        key: 'hrClock',
        title: '打卡紀錄查詢',
        iconType: <ProfileOutlined />,
        link: '/setting/clock',
        submenu: null,
      },
    ],
  },
  {
    key: 'sys',
    title: '系統管理',
    iconType: <MenuOutlined />,
    link: '',
    submenu: [
      {
        key: 'sysMenu',
        title: '選單管理',
        iconType: <UnorderedListOutlined />,
        link: '/setting/menu',
        submenu: null,
      },
      {
        key: 'sysFeature',
        title: '功能管理',
        iconType: <UnorderedListOutlined />,
        link: '/setting/feature',
        submenu: null,
      },
      {
        key: 'sysForm',
        title: '表單管理',
        iconType: <UnorderedListOutlined />,
        link: '/setting/form',
        submenu: null,
      },
    ],
  },
];
