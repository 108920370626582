import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//
import Col from '../../components/Col';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import SubTitle from '../../components/SubTitle';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { Button, Tooltip, Popconfirm, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
//
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';

const GenerateColumns = (openFn, deleteFn) => {
  return [
    {
      title: '表單代碼',
      dataIndex: 'formCode',
      key: 'formName',
      width: 200,
    },
    {
      title: '表單名稱',
      dataIndex: 'formName',
      key: 'formName',
      width: 250,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={openFn('edit', record.formCode)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確認刪除此表單？"
              placement="topRight"
              okText="確定刪除"
              cancelText="取消"
              onConfirm={deleteFn(record.featureId)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

const GenerateDetailColumns = (editFn, delFn) => {
  return [
    {
      title: '序號',
      dataIndex: 'seq',
      key: 'seq',
      width: 100,
    },
    {
      title: '關卡名稱',
      dataIndex: 'stage',
      key: 'stage',
    },
    {
      title: '固定關卡',
      dataIndex: 'fixed',
      key: 'fixed',
      render: (value) => (value === true ? '是' : '否'),
      width: 100,
    },
    {
      title: '簽核停留',
      dataIndex: 'stop',
      key: 'stop',
      render: (value) => (value === true ? '是' : '否'),
      width: 100,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 110,
      fixed: 'right',
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={editFn(record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定刪除此關卡？"
              placement="topRight"
              okText="確認刪除"
              cancelText="取消"
              onConfirm={delFn(record.seq)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];
};

const SettingForm = () => {
  const { user } = useProvided(useAuth);
  //
  const [redirectFlag, setRedirectFlag] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, type: '' });
  const [keyword, setKeyword] = useState('');
  const [formData, setFormData] = useState({});
  const [formList, setFormList] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [detailList, setDetailList] = useState([]);
  //
  const viewList = useMemo(() => {
    return formList.filter((m) => !keyword || m.formName.match(keyword) || m.formCode.match(keyword));
  }, [formList, keyword]);

  const isNewData = useMemo(() => {
    return modalProps.type === 'add';
  }, [modalProps.type]);

  //============================================================
  useEffect(() => {
    axios
      .post(`/api/setting/verifyMenu`, { menuId: 'sysForm' }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result === false) setRedirectFlag('home');
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load forms
  const loadFormList = useCallback(() => {
    axios
      .get('/api/setting/form/all', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setFormList(res.data.result.forms.map(SetKeyIntoArray));
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  }, [user]);
  useEffect(() => {
    loadFormList();
  }, [loadFormList]);

  //load one form data
  const loadForm = (formCode) => {
    if (formCode) {
      axios
        .get(`/api/setting/form/info?id=${formCode}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { m, d } = res.data.result;
            setFormData(m);
            setDetailList(d);
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //============================================================
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleFormDataChange = (name) => (e) => {
    setFormData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };

  const handleDetailDataChange = (name) => (e) => {
    let value;
    if (name === 'fixed' || name === 'stop') {
      value = e.target.checked;
    } else {
      value = e && e.target ? e.target.value : e;
    }

    setDetailData((prev) => ({
      ...prev,
      [name]: value,
      [name + '_msg']: '',
    }));
  };

  const handleDetailListSelected = (record) => () => {
    if (record) {
      setDetailData(record);
    } else {
      setDetailData({});
    }
  };

  const handleDetailListAdd = () => {
    const validResult = ValidFormData(detailData, [{ name: 'stage', type: 'input', required: true }]);
    if (!validResult.status) {
      setDetailData(validResult.data);
      return;
    }

    const tmpList = detailList.filter((df) => df.seq !== detailData.seq);
    tmpList.push({
      seq: detailData.seq || tmpList.length + 1,
      stage: detailData.stage,
      fixed: detailData.fixed,
      stop: detailData.stop,
    });
    tmpList.sort((a, b) => {
      if (a.seq < b.seq) {
        return -1;
      } else {
        return 1;
      }
    });
    setDetailList(tmpList.map(SetKeyIntoArray));
    setDetailData({});
  };

  const handleDetailListDelete = (seq) => () => {
    setDetailData({});
    setDetailList((prev) => prev.filter((p) => p.seq !== seq));
  };

  const handleSave = () => {
    //valid
    const validResult = ValidFormData(formData, [
      { name: 'formCode', type: 'input', required: true },
      { name: 'formName', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setFormData(validResult.data);
      return;
    }
    if (!Array.isArray(detailList) || detailList.length < 1) {
      Notify.warn('請設定表單關卡');
      return;
    }

    const postData = {
      formCode: formData.formCode,
      formName: formData.formName,
      details: detailList,
    };
    axios
      .post('/api/setting/form/save', postData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('儲存完成');
          loadFormList();
          handleModalClose();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  const handleDelete = (formCode) => () => {
    axios
      .post('/api/setting/form/delete', { formCode }, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('刪除成功');
          loadFormList();
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //============================================================
  const handleModalOpen = (type, formCode) => () => {
    if (formCode) {
      loadForm(formCode);
    }
    setModalProps({ open: true, type: type });
  };

  const handleModalClose = () => {
    setFormData({});
    setDetailData({});
    setDetailList([]);
    setModalProps({ open: false, type: '' });
  };

  //============================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={[40, 0]}>
            <Col>
              <Title>表單管理</Title>
            </Col>
            <Col>
              <InputInline
                label="關鍵字"
                placeholder="請輸入表單代碼或名稱"
                value={keyword}
                onChange={handleKeywordChange}
                style={{ minWidth: '300px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={handleModalOpen('add', null)} icon={<PlusOutlined />}>
            新增表單
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        columns={GenerateColumns(handleModalOpen, handleDelete)}
        dataSource={viewList}
        scroll={{ y: 'calc(100vh - 135px)' }}
      />
      <Modal visible={modalProps.open} width="800px">
        <Title>{modalProps.type === 'add' ? '新增' : '編輯'}表單</Title>
        <Row>
          <Col span={12}>
            {isNewData ? (
              <Input
                required
                label="表單代碼"
                value={formData.formCode}
                msg={formData.formCode_msg}
                onChange={handleFormDataChange('formCode')}
              />
            ) : (
              <Display label="表單代碼" value={formData.formCode} />
            )}
          </Col>
          <Col span={12}>
            <Input
              required
              label="表單名稱"
              value={formData.formName}
              msg={formData.formName_msg}
              onChange={handleFormDataChange('formName')}
            />
          </Col>
        </Row>
        <Divider dashed />
        <SubTitle>流程關卡</SubTitle>
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <Input
              required
              label="關卡名稱"
              value={detailData.stage}
              msg={detailData.stage_msg}
              onChange={handleDetailDataChange('stage')}
            />
          </Col>
          <Col span={4}>
            <Tooltip title="若勾選則為固定關卡，不可跳過">
              <Checkbox checked={detailData.fixed} onChange={handleDetailDataChange('fixed')}>
                固定關卡
              </Checkbox>
            </Tooltip>
          </Col>
          <Col span={4}>
            <Tooltip title="若勾選則於簽核時停留，不自動審核">
              <Checkbox checked={detailData.stop} onChange={handleDetailDataChange('stop')}>
                簽核停留
              </Checkbox>
            </Tooltip>
          </Col>
          <Col span={4}>
            <Button block type="primary" onClick={handleDetailListAdd}>
              {detailData.seq ? '儲存' : '新增'}
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          columns={GenerateDetailColumns(handleDetailListSelected, handleDetailListDelete)}
          dataSource={detailList}
        />
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
              儲存
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SettingForm;
