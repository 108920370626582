import styled from 'styled-components';

const JLayout = ({ label, msg, required, ...props }) => {
  return (
    <Div>
      <InputDiv>
        <LabelDiv required={required}>{label}</LabelDiv>
        <ValueDiv showBg={props.showBg}>{props.children}</ValueDiv>
      </InputDiv>
      <MsgDiv>{msg || '　'}</MsgDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
`;
const InputDiv = styled.div`
  width: 100%;
`;
const LabelDiv = styled.div`
  width: 100%;
  margin-bottom: 3px;

  ${(p) =>
    p.required
      ? `
        font-weight: bold;
        ::after {
            content: "*";
            color: #FF9F9F;
            margin-left: 8px;
        }
        `
      : ''}
`;
const ValueDiv = styled.div`
  width: 100%;
  min-height: 30px;
  ${(p) => (p.showBg ? 'background-color: #F7F7F7' : '')};
  display: flex;
  align-items: center;
`;
const MsgDiv = styled.div`
  text-align: right;
  font-size: 12px;
  color: #f00;
`;

export default JLayout;
