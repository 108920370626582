import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import File from '../../components/File';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Radio from '../../components/Radio';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectCustom from '../../components/Select/custom';
import SignControlBar from '../../components/Sign/controlBar';
import SignHistory from '../../components/Sign/history';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import { DateFormat, ListFormat, NumberFormat } from '../../modules/utils/format';
import {
  SetKeyIntoArray,
  GetFormFlowString,
  GetQueryString,
  GenerateFormMode,
} from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';

//
const HOME_PAGE = '/home';
const SEARCH_PAGE = '/form/invoice/search';
const GenerateColumns = (editFn, delFn, invoiceItemList, canEdit) => {
  const col = [
    {
      title: '請款項目',
      dataIndex: 'invoiceItemId',
      key: 'invoiceItemId',
      width: 150,
      render: (value) => ListFormat(invoiceItemList, value),
    },
    {
      title: '單據日期',
      dataIndex: 'documentDate',
      key: 'documentDate',
      width: 110,
      render: (value) => DateFormat(value),
    },
    {
      title: '請款事由或用途說明',
      dataIndex: 'reason',
      key: 'reason',
      width: 250,
    },
    {
      title: '收款對象',
      dataIndex: 'payee',
      key: 'payee',
      width: 150,
    },
    {
      title: '金額',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (value) => NumberFormat(value, 'NT$'),
    },
    {
      title: '附件',
      dataIndex: 'files',
      key: 'files',
      width: 150,
      render: (value) => <File disabled={true} value={value} />,
    },
    {
      title: '匯款日期',
      dataIndex: 'remitDate',
      key: 'remitDate',
      width: 100,
      render: (value) => DateFormat(value),
    },
    {
      title: '手續費',
      dataIndex: 'remitFee',
      key: 'remitFee',
      width: 80,
      render: (value) => NumberFormat(value, 'NT$'),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: (value, record) => (
        <Row justify="center">
          <Col>
            <Tooltip title="編輯">
              <Button icon={<EditOutlined />} onClick={editFn(record)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定刪除此請款明細？"
              placement="topRight"
              okText="確認刪除"
              cancelText="取消"
              onConfirm={delFn(record.seq)}
            >
              <Tooltip title="刪除">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  if (!canEdit) {
    delete col[col.length - 1];
  }

  if (canEdit) {
    delete col[col.length - 2];
    delete col[col.length - 3];
  }

  return col;
};

const FormInvoice = () => {
  const { user } = useProvided(useAuth);
  const { formId } = GetQueryString();
  const [redirectFlag, setRedirectFlag] = useState('');
  const [mainData, setMainData] = useState({});
  const [detailData, setDetailData] = useState({ payee: user.userName });
  const [detailList, setDetailList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [formFlow, setFormFlow] = useState([]);
  const [invoiceItemList, setinvoiceItemList] = useState([]);
  const [detailItemList, setDetailItemList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [userList, setUserList] = useState([]);
  const editRef = useRef(null);
  //
  const [modalOpen, setModalOpen] = useState(false);
  const [remitDate, setRemitDate] = useState({ value: moment(), value_msg: '' });
  const [remitItem, setRemitItem] = useState({ value: '', value_msg: '' });
  const [remitFee, setRemitFee] = useState({ value: '', value_msg: '' });
  const [feeItem, setFeeItem] = useState({ value: '', value_msg: '' });
  //
  const formMode = useMemo(() => {
    return GenerateFormMode(
      mainData.formInvoiceId,
      mainData.userId,
      mainData.signStatus,
      mainData.signUser,
      user.userId
    );
  }, [user, mainData]);

  const showDownloadPdfBtn = useMemo(() => {
    if (mainData.canDownloadPdf || mainData.canUpdateRemitDate) {
      return true;
    }
    return false;
  }, [mainData]);

  const showUpdateRemitDateBtn = useMemo(() => {
    if (
      Array.isArray(mainData.caseOfficers) &&
      mainData.caseOfficers.includes(user.userId) &&
      mainData.canUpdateRemitDate
    ) {
      return true;
    }
    return false;
  }, [user, mainData]);

  //=======================================================
  //load invoice from data.
  useEffect(() => {
    if (formId) {
      axios
        .post(
          `/api/setting/verifyForm`,
          { formCode: 'invoice', formId: formId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            if (res.data.result === false) {
              Notify.warn(res.data.msg);
              setRedirectFlag('home');
            }
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });

      axios
        .get(`/api/invoice/form/${formId}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { m, d, h, f } = res.data.result;
            setMainData(m);
            setDetailList(d.map(SetKeyIntoArray));
            setDetailItemList([
              { label: '不限', value: '' },
              ...d.map((u) => ({
                label: u.reason,
                value: u.seq,
              })),
            ]);
            setHistoryList(h.map(SetKeyIntoArray));
            setFormFlow(f);
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    } else {
      setMainData({
        comName: user.comName,
        deptName: user.deptName,
        userId: user.userId,
        userName: user.userName,
      });

      axios
        .get(`/api/sign/formFlow/invoice`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const r = res.data.result;
            setFormFlow(r.flow);
          } else {
            setFormFlow([]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user, formId]);

  //load account title & outward account list
  useEffect(() => {
    axios
      .get('/api/setting/invoiceItem/list', { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setinvoiceItemList(
            res.data.result.invoiceItems.map((u) => ({
              label: u.invoiceItemName,
              value: u.invoiceItemId,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });

    axios
      .post(
        '/api/setting/config/info',
        { type: 'outwardAccount' },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          const accountString = res.data.result.configInfo.value;
          if (accountString) {
            const list = accountString.split(',');
            setAccountList(
              list.map((u) => ({
                label: u,
                value: u,
              }))
            );
          }
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });

    axios
      .get(`/api/setting/user/list`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          setUserList(
            res.data.result.users.map((m) => ({
              label: m.userName,
              value: m.userName,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //=======================================================
  const handleMainDataChange = (name) => (e) => {
    setMainData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleDetailDataChange = (name) => (e) => {
    setDetailData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };
  const handleDetailListSelected = (record) => () => {
    if (record) {
      record.documentDate = record.documentDate && moment(record.documentDate);
      if (record.payee) {
        const item = userList.find((v) => v.value === record.payee);
        if (!item) {
          userList.push({ label: record.payee, value: record.payee });
        }
      }
      setDetailData(record);

      editRef.current.focus();
    } else {
      setDetailData({});
    }
  };
  const handleDetailListAdd = () => {
    //確認欄位是否都有填寫
    const validResult = ValidFormData(detailData, [
      { name: 'invoiceItemId', type: 'select', required: true },
      { name: 'documentDate', type: 'date', required: true },
      { name: 'reason', type: 'input', required: true },
      { name: 'amount', type: 'number', required: true },
      { name: 'payee', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setDetailData(validResult.data);
      return;
    }
    //確認明細金額不能為0
    if (Number(detailData.amount) === 0) {
      setDetailData((prev) => ({
        ...prev,
        amount: '',
        amount_msg: '金額不可為0',
      }));
      return;
    }

    //
    const tmpList = detailList.filter((df) => df.seq !== detailData.seq);
    tmpList.push({
      seq: detailData.seq || tmpList.length + 1,
      invoiceItemId: detailData.invoiceItemId,
      documentDate: detailData.documentDate,
      reason: detailData.reason,
      amount: detailData.amount,
      files: detailData.files,
      payee: detailData.payee,
    });
    tmpList.sort((a, b) => {
      if (a.seq < b.seq) {
        return -1;
      } else {
        return 1;
      }
    });
    setDetailList(tmpList.map(SetKeyIntoArray));

    //將對象、件數回填
    setMainData((prev) => ({
      ...prev,
      totalAmount: tmpList.reduce((a, v) => a + parseInt(v.amount), 0),
    }));

    const lastData = detailData;
    setDetailData({
      invoiceItemId: lastData.invoiceItemId,
      documentDate: lastData.documentDate,
      payee: lastData.payee,
    });
  };
  const handleDetailListDelete = (seq) => () => {
    const tmpList = detailList
      .filter((df) => df.seq !== seq)
      .map((dm, dmIdx) => {
        dm.seq = dmIdx + 1;
        return dm;
      });

    setDetailList(tmpList.map(SetKeyIntoArray));

    //將對象、件數回填
    setMainData((prev) => ({
      ...prev,
      totalAmount: tmpList.reduce((a, v) => a + parseInt(v.amount), 0),
    }));
    setDetailData({});
  };

  //=======================================================
  const handleSave = (cbFn) => () => {
    //
    if (accountList && Array.isArray(accountList) && accountList.length > 0) {
      const validResult = ValidFormData(mainData, [
        { name: 'outwardAccount', type: 'select', required: true },
      ]);
      if (!validResult.status) {
        setMainData(validResult.data);
        return;
      }
    }
    if (!Array.isArray(detailList) || detailList.length < 1) {
      Notify.warn('請填寫請款明細');
      return;
    }
    //
    const saveData = {
      formInvoiceId: mainData.formInvoiceId,
      totalAmount: mainData.totalAmount,
      projectName: mainData.projectName,
      outwardAccount: mainData.outwardAccount,
      details: detailList,
    };
    axios
      .post('/api/invoice/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result.formInvoiceId) {
            setMainData((prev) => ({
              ...prev,
              formInvoiceId: res.data.result.formInvoiceId,
            }));
          }
          Notify.success('儲存成功');
          cbFn && cbFn();
        } else {
          Notify.warn('儲存失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleDelete = () => {
    if (mainData.formInvoiceId) {
      axios
        .post(
          '/api/invoice/delete',
          { formInvoiceId: mainData.formInvoiceId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            Notify.success('刪除完成');
            setRedirectFlag('search');
          } else {
            Notify.warn('刪除失敗:' + res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //=======================================================
  const handleSubmit = (comment) => {
    axios
      .post(
        '/api/sign/submit',
        { formCode: 'invoice', formId: mainData.formInvoiceId, comment, amonut: mainData.totalAmount },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('呈送完成');
          setRedirectFlag('search');
        } else {
          Notify.warn('呈送失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleApprove = (comment) => {
    axios
      .post(
        '/api/sign/approve',
        { formId: mainData.formInvoiceId, formCode: 'invoice', comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('審核完成');
          setRedirectFlag('home');
        } else {
          Notify.warn('審核失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleReturn = (comment) => {
    axios
      .post(
        '/api/sign/return',
        { formId: mainData.formInvoiceId, formCode: 'invoice', comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('退回完成');
          setRedirectFlag('home');
        } else {
          Notify.warn('退回失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleVoid = (comment) => {
    axios
      .post(
        '/api/sign/void',
        { formId: mainData.formInvoiceId, comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('作廢完成');
          setRedirectFlag('search');
        } else {
          Notify.warn('作廢失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setRemitFee({ value: '', value_msg: '' });
    setModalOpen(false);
  };
  //
  const handleRemitItemChange = (val) => {
    setRemitItem({ value: val, value_msg: '' });
  };
  const handleUpdateRemitDate = () => {
    if (!remitDate.value) {
      setRemitDate({ value: moment(), value_msg: '請輸入匯款日期' });
      return;
    }
    //產生PDF所需資料
    const pdfData = {
      comName: user.comName,
      formInvoiceId: mainData.formInvoiceId,
      formNumber: mainData.formNumber,
      deptName: mainData.deptName,
      applyDate: mainData.crDate,
      applyName: mainData.userName,
      projectName: mainData.projectName,
      outwardAccount: mainData.outwardAccount,
      totalAmount: mainData.totalAmount,
      details: detailList,
      historys: historyList,
    };

    axios
      .post(
        '/api/invoice/updateRemitDate',
        {
          formInvoiceId: mainData.formInvoiceId,
          remitDate: remitDate.value.format('YYYY-MM-DD'),
          seq: remitItem.value,
          pdfData: pdfData,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('更新匯款日期完成');
          if (remitItem.value) {
            const detailItem = detailList.find((v) => v.seq === remitItem.value);
            if (detailItem) {
              detailItem.remitDate = remitDate.value.format('YYYY-MM-DD');
            }
          } else {
            detailList.forEach((v) => (v.remitDate = remitDate.value.format('YYYY-MM-DD')));
          }
          if (res.data.result && res.data.result.annex) {
            const file = [];
            file.push(res.data.result.annex);
            setMainData((prev) => ({
              ...prev,
              file: file,
            }));
          }
          handleModalClose();
        } else {
          Notify.warn('更新匯款日期失敗: ' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleRemitDateChange = (val) => {
    setRemitDate({ value: val, value_msg: '' });
  };
  //
  const handleFeeItemChange = (val) => {
    setFeeItem({ value: val, value_msg: '' });
  };
  const handleUpdateRemitFee = () => {
    if (!remitFee.value) {
      setRemitFee({ value: '', value_msg: '請輸入手續費金額' });
      return;
    }

    axios
      .post(
        '/api/invoice/updateRemitFee',
        {
          formInvoiceId: mainData.formInvoiceId,
          remitFee: remitFee.value,
          seq: feeItem.value,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('更新手續費完成');
          if (feeItem.value) {
            const detailItem = detailList.find((v) => v.seq === feeItem.value);
            if (detailItem) {
              detailItem.remitFee = remitFee.value;
            }
          } else {
            detailList.forEach((v) => (v.remitFee = remitFee.value));
          }
          handleModalClose();
        } else {
          Notify.warn('更新手續費失敗: ' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleRemitFeeChange = (e) => {
    setRemitFee({ value: e && e.target ? e.target.value : e, value_msg: '' });
  };
  //=======================================================
  const handleDownloadPdf = () => {
    const pdfData = {
      comName: user.comName,
      formInvoiceId: mainData.formInvoiceId,
      formNumber: mainData.formNumber,
      deptName: mainData.deptName,
      applyDate: mainData.crDate,
      applyName: mainData.userName,
      projectName: mainData.projectName,
      outwardAccount: mainData.outwardAccount,
      totalAmount: mainData.totalAmount,
      details: detailList,
      historys: historyList,
    };

    axios
      .post('/api/pdf/invoice/create', pdfData, {
        headers: { Authorization: user.token, Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
      .then((res) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.download = mainData.formNumber + '.pdf';
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          Notify.warn('下載PDF失敗');
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  const handleDownloadAll = () => {
    //
    axios
      .post(
        '/api/invoice/downloadAll',
        { formInvoiceId: mainData.formInvoiceId },
        { headers: { Authorization: user.token }, responseType: 'blob' }
      )
      .then((res) => {
        if (res && res.data && res.data.type !== 'application/json') {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/zip' }));
          const link = document.createElement('a');
          link.href = url;
          link.download = mainData.formNumber + '.zip';
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          Notify.warn('無可下載的附件');
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error('批次下載附件異常：' + err);
      });
  };
  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  if (redirectFlag === 'search') {
    return <Redirect to={SEARCH_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>請款單</Title>
        </Col>
        <Col>
          <Row>
            {showDownloadPdfBtn && (
              <Col>
                <Button size="large" onClick={handleDownloadAll}>
                  批次下載附件
                </Button>
              </Col>
            )}
            {showDownloadPdfBtn && (
              <Col>
                <Button size="large" onClick={handleDownloadPdf}>
                  下載PDF
                </Button>
              </Col>
            )}
            {showUpdateRemitDateBtn && (
              <Col>
                <Button size="large" onClick={handleModalOpen}>
                  更新匯款日期及手續費
                </Button>
              </Col>
            )}
            <Col>
              <SignControlBar
                backUrl="/form/invoice/search"
                btnCtrl={formMode.button}
                saveFn={handleSave}
                deleteFn={handleDelete}
                submitFn={handleSubmit}
                approveFn={handleApprove}
                returnFn={handleReturn}
                voidFn={handleVoid}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={6}>
          <Display label="表單單號" value={mainData.formNumber || '自動產生'} />
        </Col>
        <Col span={18}>
          {formFlow && Array.isArray(formFlow) && formFlow.length > 0 ? (
            <Display label="表單流程" value={GetFormFlowString(formFlow)} />
          ) : (
            <Display label="表單流程" value="申請人 ➡️ 部門主管 ➡️ 總經理 ➡️ 董事長 ➡️ 承辦" />
          )}
        </Col>
        <Col span={6}>
          <Display label="申請日期" value={DateFormat(mainData.crDate || moment())} />
        </Col>
        <Col span={6}>
          <Display label="部門" value={mainData.deptName} />
        </Col>
        <Col span={6}>
          <Display label="申請人員" value={mainData.userName} />
        </Col>
        <Col span={6}>
          <Display label="金額總計新台幣" value={NumberFormat(mainData.totalAmount || 0, 'NT$')} />
        </Col>
        <Col span={12}>
          {formMode.canEdit ? (
            <Input
              label="專案名稱"
              value={mainData.projectName}
              onChange={handleMainDataChange('projectName')}
            />
          ) : (
            <Display label="專案名稱" value={mainData.projectName} />
          )}
        </Col>
        <Col span={12}>
          <File label="請款單文件" disabled={true} value={mainData.file} />
        </Col>
        {mainData.remitDate && (
          <Col span={6}>
            <Display label="匯款日期" value={DateFormat(mainData.remitDate)} />
          </Col>
        )}
        {(mainData.outwardAccount || accountList.length > 0) &&
          (formMode.canEdit ? (
            <Col span={12}>
              <Radio
                required
                label="匯出帳戶"
                options={accountList}
                value={mainData.outwardAccount}
                msg={mainData.outwardAccount_msg}
                onChange={handleMainDataChange('outwardAccount')}
              />
            </Col>
          ) : (
            <Col span={6}>
              <Display label="匯出帳戶" value={mainData.outwardAccount} />
            </Col>
          ))}
      </Row>
      <Divider dashed />
      {formMode.canEdit && (
        <Row justify="space-between" align="middle">
          <Col span={16}>
            <Select
              required
              canSearch
              label="請款項目"
              options={invoiceItemList}
              value={detailData.invoiceItemId}
              msg={detailData.invoiceItemId_msg}
              onChange={handleDetailDataChange('invoiceItemId')}
              ref={editRef}
            />
          </Col>
          <Col span={8}>
            <File
              label="附件"
              multiple={true}
              value={detailData.files}
              msg={detailData.files_msg}
              onChange={handleDetailDataChange('files')}
            />
          </Col>
          <Col span={4}>
            <DatePicker
              required
              label="單據日期"
              value={detailData.documentDate}
              msg={detailData.documentDate_msg}
              onChange={handleDetailDataChange('documentDate')}
            />
          </Col>
          <Col span={10}>
            <Input
              required
              label="請款事由或用途說明"
              value={detailData.reason}
              msg={detailData.reason_msg}
              onChange={handleDetailDataChange('reason')}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              required
              canSearch
              label="收款對象"
              options={userList}
              value={detailData.payee}
              msg={detailData.payee_msg}
              onChange={handleDetailDataChange('payee')}
            />
          </Col>
          <Col span={4}>
            <Input
              required
              label="金額"
              value={detailData.amount}
              msg={detailData.amount_msg}
              onChange={handleDetailDataChange('amount')}
            />
          </Col>
          <Col span={4}>
            <Button block type="primary" size="large" onClick={handleDetailListAdd}>
              {detailData.seq ? '儲存' : '新增'}
            </Button>
          </Col>
        </Row>
      )}
      <br />
      <Table
        columns={GenerateColumns(
          handleDetailListSelected,
          handleDetailListDelete,
          invoiceItemList,
          formMode.canEdit
        )}
        dataSource={detailList}
      />
      <Divider dashed />
      <SignHistory data={historyList} signStatus={mainData.signStatus} signerNames={mainData.signerNames} />
      <Modal width="600px" visible={modalOpen}>
        <Title>更新匯款日期及手續費</Title>
        <Divider />
        <Row align="middle">
          <Col span={18}>
            <Select
              label="選擇項目"
              options={detailItemList}
              value={remitItem.value}
              msg={remitItem.value_msg}
              onChange={handleRemitItemChange}
            />
          </Col>
          <Col span={6}>
            <DatePicker
              required
              label="匯款日期"
              value={remitDate.value}
              msg={remitDate.value_msg}
              onChange={handleRemitDateChange}
            />
          </Col>
        </Row>
        <Divider dashed />
        <Row align="middle">
          <Col span={18}>
            <Select
              label="選擇項目"
              options={detailItemList}
              value={feeItem.value}
              msg={feeItem.value_msg}
              onChange={handleFeeItemChange}
            />
          </Col>
          <Col span={6}>
            <Input
              label="手續費"
              value={remitFee.value}
              msg={remitFee.value_msg}
              onChange={handleRemitFeeChange}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleUpdateRemitDate}>
              更新匯款日期
            </Button>
          </Col>
          <Col>
            <Popconfirm
              title="是否確定更新手續費？"
              placement="topRight"
              okText="確認"
              cancelText="取消"
              onConfirm={handleUpdateRemitFee}
            >
              <Button size="large">更新手續費</Button>
            </Popconfirm>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default FormInvoice;
