import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import _ from "lodash";
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import InputInline from '../../components/Input/inline';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectInline from '../../components/Select/inline';
import Table from '../../components/Table';
import Title from '../../components/Title';
import CSVReader from 'react-csv-reader';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, ImportOutlined, SaveOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
//
import { DateFormat, NumberFormat } from '../../modules/utils/format';
import { SetKeyIntoArray } from '../../modules/utils/tool';
import { ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
import csvSampleEmployee from './sample_employee.csv';
import csvSampleEmployer from './sample_employer.csv';
const HOME_PAGE = '/home';


const GenerateColumns = (openFn, deleteFn) => {
    return [
        {
            title: '啟用日期',
            dataIndex: 'activeDate',
            key: 'activeDate',
            width: 100,
            render: (value) => DateFormat(value),
        },
        {
            title: '級距',
            dataIndex: 'grade',
            key: 'grade',
            width: 100,
            render: (value) => NumberFormat(value),
        },
        {
            title: '月薪總額-起迄',
            dataIndex: 'startSalary',
            key: 'startSalary',
            width: 150,
            render: (value, record) => `${NumberFormat(record.startSalary)} - ${NumberFormat(record.endSalary)}`,
        },
        {
            title: '勞保個人負擔',
            dataIndex: 'employeeLabor',
            key: 'employeeLabor',
            width: 100,
            render: (value) => NumberFormat(value),
        },
        {
            title: '勞保雇主負擔',
            dataIndex: 'employerLabor',
            key: 'employeeLabor',
            width: 100,
            render: (value) => NumberFormat(value),
        },

        {
            title: '健保個人負擔',
            dataIndex: 'employeeHealth',
            key: 'employeeHealth',
            width: 100,
            render: (value) => NumberFormat(value),
        },

        {
            title: '健保雇主負擔',
            dataIndex: 'employerHealth',
            key: 'employerHealth',
            width: 100,
            render: (value) => NumberFormat(value),
        },

        {
            title: '勞退雇主負擔',
            dataIndex: 'employerPension',
            key: 'employerPension',
            width: 100,
            render: (value) => NumberFormat(value),
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 150,
            fixed: 'right',
            render: (value, record) => (
                <Row justify="center">
                    <Col>
                        <Tooltip title="編輯">
                            <Button icon={<EditOutlined />} onClick={openFn('edit', record)} />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="是否確認刪除此級距設定？"
                            placement="topRight"
                            okText="確定刪除"
                            cancelText="取消"
                            onConfirm={deleteFn(record.insuranceId)}
                        >
                            <Tooltip title="刪除">
                                <Button icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Col>
                </Row>
            ),
        },
    ];
};

const GenerateImportColumns_employee = [
    {
        title: '級距',
        dataIndex: 'grade',
        key: 'grade',
        width: 100,
        render: (value) => NumberFormat(value),
    },
    {
        title: '月薪總額-起',
        dataIndex: 'startSalary',
        key: 'startSalary',
        width: 100,
        render: (value) => NumberFormat(value),
    },
    {
        title: '月薪總額-迄',
        dataIndex: 'endSalary',
        key: 'endSalary',
        width: 100,
        render: (value) => NumberFormat(value),
    },
    {
        title: '勞保個人負擔',
        dataIndex: 'employeeLabor',
        key: 'employeeLabor',
        width: 100,
        render: (value) => NumberFormat(value),
    },
    {
        title: '勞保雇主負擔',
        dataIndex: 'employerLabor',
        key: 'employeeLabor',
        width: 100,
        render: (value) => NumberFormat(value),
    },
    {
        title: '健保個人負擔',
        dataIndex: 'employeeHealth',
        key: 'employeeHealth',
        width: 100,
        render: (value) => NumberFormat(value),
    },
    {
        title: '健保雇主負擔',
        dataIndex: 'employerHealth',
        key: 'employerHealth',
        width: 100,
        render: (value) => NumberFormat(value),
    },
    {
        title: '勞退雇主負擔',
        dataIndex: 'employerPension',
        key: 'employerPension',
        width: 100,
        render: (value) => NumberFormat(value),
    },
];

const GenerateImportColumns_employer = [
    {
        title: '級距',
        dataIndex: 'grade',
        key: 'grade',
        width: 100,
        render: (value) => NumberFormat(value),
    },
    {
        title: '健保費用',
        dataIndex: 'employeeHealth',
        key: 'employeeHealth',
        width: 100,
        render: (value) => NumberFormat(value),
    },
];

const SettingInsurance = () => {
    const { user } = useProvided(useAuth);
    //
    const [redirectFlag, setRedirectFlag] = useState('');
    const [modalProps, setModalProps] = useState({ open: false, type: '' });
    const [filterData, setFilterData] = useState({ type: '0', activeDate: '', keyword: '' });
    const [insuranceList, setInsuranceList] = useState([]);
    const [innsuranceData, setInsuranceData] = useState({});
    const [activeList, setActiveList] = useState([]);
    //
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [importList, setImportList] = useState([]);
    const [importType, setImportType] = useState('0');
    const [importDate, setImportDate] = useState(moment());
    //.
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    //
    const viewList = useMemo(() => {
        return insuranceList.filter(
            (v) =>
                (!filterData.type || v.type === filterData.type) &&
                (!filterData.activeDate || v.activeDate === filterData.activeDate) &&
                (!filterData.keyword || v.startSalary <= Number.parseInt(filterData.keyword)) &&
                (!filterData.keyword || v.endSalary >= Number.parseInt(filterData.keyword))
        );
    }, [insuranceList, filterData]);

    //============================================================
    useEffect(() => {
        axios
            .post(`/api/setting/verifyMenu`, { menuId: 'hrInsurance' }, { headers: { Authorization: user.token } })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    if (res.data.result === false)
                        setRedirectFlag('home');
                } else {
                    Notify.warn(res.data.msg);
                }
            })
            .catch((err) => {
                console.error(err.stack);
                Notify.error(err.message);
            });
    }, [user]);

    //load data
    const loadInsuranceList = useCallback(() => {
        axios
            .get('/api/setting/insurance/all', { headers: { Authorization: user.token } })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    setInsuranceList(res.data.result.insurances.map(SetKeyIntoArray));
                    if (res.data.result.insurances) {
                        let result = _.orderBy(_.uniq(_.map(res.data.result.insurances, item => item.activeDate)), [function (o) { return o; }], ['desc']);
                        setActiveList(result.map((u) => ({ label: DateFormat(u), value: u, })));
                        setFilterData((prev) => ({ ...prev, activeDate: result[0] }))
                    }
                } else {
                    Notify.warn(res.data.msg);
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.error(err);
            });
    }, [user]);

    useEffect(() => {
        loadInsuranceList();
    }, [loadInsuranceList]);

    //============================================================
    const handleModalOpen = (type, record) => () => {
        if (record) {
            setInsuranceData(record);
        }
        setModalProps({ open: true, type: type });
    };
    const handleModalClose = () => {
        setInsuranceData({});
        setModalProps({ open: false, type: '' });
    };
    const handleFilterDataChange = (name) => (e) => {
        setFilterData((prev) => ({
            ...prev,
            [name]: e && e.target ? e.target.value : e,
        }));
    };
    const handleDataChange = (name) => (e) => {
        setInsuranceData((prev) => ({
            ...prev,
            [name]: e && e.target ? e.target.value : e,
            [name + '_msg']: '',
        }));
    };
    const handleSave = () => {
        //valid
        const validResult = ValidFormData(innsuranceData, [
            { name: 'grade', type: 'number', required: true },
            { name: 'employeeLabor', type: 'number', required: (innsuranceData.type === '0') },
            { name: 'employerLabor', type: 'number', required: (innsuranceData.type === '0') },
            { name: 'employeeHealth', type: 'number', required: true },
            { name: 'employerHealth', type: 'number', required: (innsuranceData.type === '0') },
            { name: 'employerPension', type: 'number', required: (innsuranceData.type === '0') }
        ]);
        if (!validResult.status) {
            setInsuranceData(validResult.data);
            return;
        }

        axios
            .post('/api/setting/insurance/save', innsuranceData, { headers: { Authorization: user.token } })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    Notify.success('儲存完成');
                    loadInsuranceList();
                    handleModalClose();
                } else {
                    Notify.warn(res.data.msg);
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.error(err);
            });
    };
    const handleDelete = (insuranceId) => () => {
        axios
            .post('/api/setting/insurance/delete', { insuranceId }, { headers: { Authorization: user.token } })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    Notify.success('刪除成功');
                    loadInsuranceList();
                } else {
                    Notify.warn(res.data.msg);
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.error(err);
            });
    };

    //============================================================
    const handleImportModalOpen = () => {
        setImportModalOpen(true);
    };
    const handleImportModalClose = () => {
        setImportList([]);
        setImportModalOpen(false);
    };
    const handleTypeChange = (value) => {
        setImportType(value);
    };
    const handleImportDateChange = (date) => {
        setImportDate(date);
    };
    const handleCsvUploadPreAction = () => {
        document.getElementById('j-csv-reader').click();
    };
    const handleCsvUpload = (data) => {
        if (importType === '0') {
            setImportList(
                data.map((u) => ({
                    grade: u.級距,
                    startSalary: u.月薪總額起,
                    endSalary: u.月薪總額迄,
                    employeeLabor: u.勞保個人負擔,
                    employerLabor: u.勞保雇主負擔,
                    employeeHealth: u.健保個人負擔,
                    employerHealth: u.健保雇主負擔,
                    employerPension: u.勞退雇主負擔,
                })) || []);
        }
        else if (importType === '1') {
            setImportList(
                (data) ?
                    data.map((u, i, arr) => {
                        const previousItem = arr[i - 1];
                        return {
                            grade: u.級距,
                            startSalary: (i > 0) ? Number(previousItem.級距) + 1 : 1,
                            endSalary: u.級距,
                            employeeHealth: u.健保費用,
                        }
                    }) : []);
        }
    };
    const handleImport = () => {
        if (!Array.isArray(importList) || importList.length < 1) {
            Notify.warn('請先上傳檔案');
            return;
        }
        axios
            .post('/api/setting/insurance/import', { activeDate: importDate, type: importType, importList: importList }, {
                headers: { Authorization: user.token },
            })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    Notify.success(
                        <div>
                            <strong>匯入勞健保級距完成</strong>
                        </div>
                    );
                    handleImportModalClose();
                    loadInsuranceList();
                } else {
                    Notify.warn(res.data.msg);
                }
            })
            .catch((err) => {
                console.error(err.stack);
                Notify.error(err.message);
            });
    };

    //============================================================
    const handleUpdateModalOpen = () => {
        setUpdateModalOpen(true);
    };

    const handleUpdateModalClose = () => {
        setUpdateModalOpen(false);
    };

    const handleUserSalaryUpdate = () => {
        axios
            .get('/api/setting/user/salary/updateInsurance', { headers: { Authorization: user.token } })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    Notify.success(
                        <div>
                            <strong>更新人員薪資完成</strong>
                        </div>
                    );
                    handleUpdateModalClose();
                } else {
                    Notify.warn(res.data.msg);
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.error(err);
            });
    };

    //============================================================
    if (redirectFlag === 'home') {
        return <Redirect to={HOME_PAGE} />;
    }
    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col>
                    <Row align="middle" gutter={[40, 0]}>
                        <Col>
                            <Title>勞健保級距設定</Title>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Button size="large" icon={<SyncOutlined />} type="primary" onClick={handleUpdateModalOpen}>
                                更新人員薪資
                            </Button>
                        </Col>
                        <Col>
                            <Button size="large" icon={<ImportOutlined />} onClick={handleImportModalOpen}>
                                匯入級距設定
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <SelectInline
                        label="類別"
                        options={[{ label: '員工', value: '0' }, { label: '雇主', value: '1' }]}
                        value={filterData.type}
                        onChange={handleFilterDataChange('type')}
                        style={{ minWidth: '100px' }}
                    />
                </Col>
                <Col>
                    <SelectInline
                        label="啟用日期"
                        options={activeList}
                        value={filterData.activeDate}
                        onChange={handleFilterDataChange('activeDate')}
                        style={{ minWidth: '150px' }}
                    />
                </Col>
                <Col>
                    <InputInline
                        label="查詢月薪"
                        placeholder="請輸入查詢月薪"
                        value={filterData.keyword}
                        onChange={handleFilterDataChange('keyword')}
                        style={{ minWidth: '200px' }}
                    />
                </Col>
            </Row>
            <Divider />
            <Table
                columns={GenerateColumns(handleModalOpen, handleDelete)}
                dataSource={viewList}
                scroll={{ y: 'calc(100vh - 135px)' }}
            />
            <Modal width="600px" visible={modalProps.open}>
                <Title>勞健保級距</Title>
                <Divider />
                <Row>
                    <Col span={8}>
                        <Display label="級距" value={innsuranceData.grade} />
                    </Col>
                    <Col span={8}>
                        <Display label="月薪總額-起" value={innsuranceData.startSalary} />
                    </Col>
                    <Col span={8}>
                        <Display label="月薪總額-迄" value={innsuranceData.endSalary} />
                    </Col>
                </Row>
                <Row>
                    {(innsuranceData.type === '0') &&
                        <Col span={12}>
                            <Input
                                required
                                label="勞保個人負擔"
                                value={innsuranceData.employeeLabor}
                                msg={innsuranceData.employeeLabor_msg}
                                onChange={handleDataChange('employeeLabor')}
                            />
                        </Col>
                    }
                    {(innsuranceData.type === '0') &&
                        <Col span={12}>
                            <Input
                                required
                                label="勞保雇主負擔"
                                value={innsuranceData.employerLabor}
                                msg={innsuranceData.employerLabor_msg}
                                onChange={handleDataChange('employerLabor')}
                            />
                        </Col>
                    }
                    <Col span={12}>
                        <Input
                            required
                            label="健保個人負擔"
                            value={innsuranceData.employeeHealth}
                            msg={innsuranceData.employeeHealth_msg}
                            onChange={handleDataChange('employeeHealth')}
                        />
                    </Col>
                    {(innsuranceData.type === '0') &&
                        <Col span={12}>
                            <Input
                                required
                                label="健保雇主負擔"
                                value={innsuranceData.employerHealth}
                                msg={innsuranceData.employerHealth_msg}
                                onChange={handleDataChange('employerHealth')}
                            />
                        </Col>
                    }
                    {(innsuranceData.type === '0') &&
                        <Col span={12}>
                            <Input
                                required
                                label="勞退雇主負擔"
                                value={innsuranceData.employerPension}
                                msg={innsuranceData.employerPension_msg}
                                onChange={handleDataChange('employerPension')}
                            />
                        </Col>
                    }
                </Row>
                <Divider />
                <Row justify="end" align="middle">
                    <Col>
                        <Button size="large" type="primary" onClick={handleSave} icon={<SaveOutlined />}>
                            儲存
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={handleModalClose}>關閉</Button>
                    </Col>
                </Row>
            </Modal>
            <Modal width="1200px" visible={importModalOpen}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Title>匯入勞健保級距</Title>
                    </Col>
                    <Col>
                        <Row align="middle">
                            <Col>
                                <Button size="large" icon={<DownOutlined />}
                                    type="link"
                                    href={csvSampleEmployee}
                                    download="勞健保級距設定.csv"
                                >
                                    下載匯入範例(員工勞健保級距)
                                </Button>
                                <Button size="large" icon={<DownOutlined />}
                                    type="link"
                                    href={csvSampleEmployer}
                                    download="健保費負擔金額設定.csv"
                                >
                                    下載匯入範例(雇主健保費負擔金額)
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" size="large" icon={<UploadOutlined />} onClick={handleCsvUploadPreAction}>
                                    上傳
                                </Button>
                                <CSVReader
                                    inputId="j-csv-reader"
                                    onFileLoaded={handleCsvUpload}
                                    parserOptions={{ header: true, skipEmptyLines: true }}
                                    inputStyle={{ display: 'none' }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Select
                            required
                            label="匯入類別"
                            options={[{ label: '員工', value: '0' }, { label: '雇主', value: '1' }]}
                            value={importType}
                            onChange={handleTypeChange}
                            style={{ minWidth: '120px' }}
                        />
                    </Col>
                    <Col>
                        <DatePicker
                            required
                            label="啟用日期"
                            picker="date"
                            allowClear={false}
                            value={importDate}
                            onChange={handleImportDateChange}
                            style={{ minWidth: '120px' }}
                        />
                    </Col>
                </Row>
                <Divider />
                {importType === '0' &&
                    <Table columns={GenerateImportColumns_employee} dataSource={(importType === '0') ? importList : null} scroll={{ y: '500px' }} />
                }
                {importType === '1' &&
                    <Table columns={GenerateImportColumns_employer} dataSource={(importType === '1') ? importList : null} scroll={{ y: '500px' }} />
                }
                <Divider />
                <Row justify="end" align="middle">
                    <Col>
                        <Button type="primary" size="large" icon={<ImportOutlined />} onClick={handleImport}>
                            確定匯入
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={handleImportModalClose}>關閉</Button>
                    </Col>
                </Row>
            </Modal>
            <Modal width="800px" visible={updateModalOpen}>
                <Title>更新人員薪資</Title>
                <Divider />
                <Row gutter={[20, 10]}>
                    <Col span={24}>
                        <strong style={{ color: '#D73141' }}>
                            ※ 更新動作會直接以最新啟用日期的資料異動個人薪資內的勞健保個人負擔金額對應，請注意！
                        </strong>
                    </Col>
                </Row>
                <br />
                <Divider />
                <Row justify="end" align="middle">
                    <Col>
                        <Button size="large" type="primary" onClick={handleUserSalaryUpdate}>
                            更新設定
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={handleUpdateModalClose}>關閉</Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

export default SettingInsurance;