import React, { useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
//
import Col from '../../components/Col';
import DatePicker from '../../components/DatePicker';
import Display from '../../components/Display';
import Divider from '../../components/Divider';
import File from '../../components/File';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notify from '../../components/Notify';
import Row from '../../components/Row';
import Select from '../../components/Select';
import SelectCustom from '../../components/Select/custom';
import SignControlBar from '../../components/Sign/controlBar';
import SignHistory from '../../components/Sign/history';
import SubTitle from '../../components/SubTitle';
import Table from '../../components/Table';
import Textarea from '../../components/Textarea';
import Title from '../../components/Title';
import { Button } from 'antd';
//
import { DateFormat, NumberFormat } from '../../modules/utils/format';
import {
  SetKeyIntoArray,
  GetFormFlowString,
  GetQueryString,
  GenerateFormMode,
} from '../../modules/utils/tool';
import { IsNullOrUndefined, ValidFormData } from '../../modules/utils/valid';
import { useProvided } from '../../modules/stores';
import useAuth from '../../modules/stores/useAuth';
//
const HOME_PAGE = '/home';
const SEARCH_PAGE = '/form/charge/search';
const GenerateColumns = [
  {
    title: '案場',
    dataIndex: 'plant',
    key: 'plant',
    width: 150,
  },
  {
    title: '電費單計費起',
    dataIndex: 'startDate',
    key: 'startDate',
    width: 100,
    render: (value) => DateFormat(value),
  },
  {
    title: '電費單計費訖',
    dataIndex: 'endDate',
    key: 'endDate',
    width: 100,
    render: (value) => DateFormat(value),
  },
  {
    title: '金額',
    dataIndex: 'amount',
    key: 'amount',
    width: 150,
    render: (value) => NumberFormat(value, 'NT$'),
  },
];

const FormCharge = () => {
  const { user } = useProvided(useAuth);
  const { formId } = GetQueryString();
  const [redirectFlag, setRedirectFlag] = useState('');
  const [mainData, setMainData] = useState({});
  const [detailList, setDetailList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [formFlow, setFormFlow] = useState([]);
  //
  const [modalOpen, setModalOpen] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [remitDate, setRemitDate] = useState({ value: moment(), value_msg: '' });
  const [billFile, setBillFile] = useState({ value: '', value_msg: '' });
  const [companyList, setCompanyList] = useState([]);
  const [invoiceItemList, setinvoiceItemList] = useState([]);
  const [invoiceData, setInvoiceData] = useState({ documentDate: moment() });
  //
  const formMode = useMemo(() => {
    return GenerateFormMode(
      mainData.formChargeId,
      mainData.userId,
      mainData.signStatus,
      mainData.signUser,
      user.userId
    );
  }, [user, mainData]);
  //
  const showDownloadBtn = useMemo(() => {
    if (formMode.isCurrentSigner && mainData.canDownloadFile) {
      return true;
    }
    return false;
  }, [formMode.isCurrentSigner, mainData.canDownloadFile]);

  const showUploadBtn = useMemo(() => {
    if (formMode.isCurrentSigner && mainData.canUploadFile) {
      return true;
    }
    return false;
  }, [formMode.isCurrentSigner, mainData.canUploadFile]);

  const showUpdateRemitDateBtn = useMemo(() => {
    if (formMode.isCurrentSigner && mainData.canUpdateRemitDate) {
      return true;
    }
    return false;
  }, [formMode.isCurrentSigner, mainData.canUpdateRemitDate]);
  //
  const checkIfValidUUID = (str) => {
    if (!str) return false;

    // Regular expression to check if string is a valid UUID
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
  };
  const convertToFormInvoice = useMemo(() => {
    if (checkIfValidUUID(mainData.chargePartyId)) {
      return true;
    }
    return false;
  }, [mainData.chargePartyId]);

  //=======================================================
  //load company data.
  useEffect(() => {
    axios
      .get(`/api/setting/company/all`, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const companies = res.data.result.companies;
          const filtered = companies.filter((c) => c.comId !== user.comId);
          setCompanyList(
            filtered.map((u) => ({
              label: u.comName,
              value: u.comId,
            }))
          );
        } else {
          Notify.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err.stack);
        Notify.error(err.message);
      });
  }, [user]);

  //load charge from data.
  useEffect(() => {
    if (formId) {
      axios
        .post(
          `/api/setting/verifyForm`,
          { formCode: 'charge', formId: formId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            if (res.data.result === false) {
              Notify.warn(res.data.msg);
              setRedirectFlag('home');
            }
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err.stack);
          Notify.error(err.message);
        });

      axios
        .get(`/api/charge/form/${formId}`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { m, d, h, f } = res.data.result;
            if (IsNullOrUndefined(m.chargePartyId) && m.chargeParty) {
              m.chargePartyId = m.chargeParty;
            }
            setMainData(m);
            setDetailList(d.map(SetKeyIntoArray));
            setHistoryList(h.map(SetKeyIntoArray));
            setFormFlow(f);
            if (m.remitDate) {
              setRemitDate({ value: moment(m.remitDate), value_msg: '' });
            }
            if (m.file) {
              setBillFile({ value: m.file, value_msg: '' });
            }
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    } else {
      setMainData({
        comName: user.comName,
        deptName: user.deptName,
        userId: user.userId,
        userName: user.userName,
      });

      axios
        .get(`/api/sign/formFlow/charge`, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res && res.data && res.data.status) {
            const r = res.data.result;
            setFormFlow(r.flow);
          } else {
            setFormFlow([]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user, formId]);

  //load invoiceItem data.
  useEffect(() => {
    if (convertToFormInvoice) {
      axios
        .post(
          '/api/setting/invoiceItem/listByCompany',
          { comId: mainData.chargePartyId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            setinvoiceItemList(
              res.data.result.invoiceItems.map((u) => ({
                label: u.invoiceItemName,
                value: u.invoiceItemId,
              }))
            );
            //設定預設值
            const item = res.data.result.invoiceItems.find((i) => i.invoiceItemName === '服務費');
            if (item) {
              setInvoiceData((prev) => ({
                ...prev,
                invoiceItemId: item.invoiceItemId,
              }));
            }
          } else {
            Notify.warn(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  }, [user, convertToFormInvoice, mainData.chargePartyId]);

  //=======================================================
  const handleMainDataChange = (name) => (e) => {
    let value = e && e.target ? e.target.value : e;
    if (name === 'chargeParty') {
      if (checkIfValidUUID(value)) {
        const company = companyList.find((c) => c.value === value);
        if (company) {
          setMainData((prev) => ({
            ...prev,
            chargePartyId: value,
          }));
          value = company.label;
        }
      }
    }

    setMainData((prev) => ({
      ...prev,
      [name]: value,
      [name + '_msg']: '',
    }));
  };

  //=======================================================
  const handleSave = (cbFn) => () => {
    //
    const validResult = ValidFormData(mainData, [
      { name: 'chargeItem', type: 'input', required: true },
      { name: 'chargeParty', type: 'input', required: true },
      { name: 'totalAmount', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setMainData(validResult.data);
      return;
    }
    const saveData = { ...mainData, chargePartyId: convertToFormInvoice ? mainData.chargePartyId : null };
    //
    axios
      .post('/api/charge/save', saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          if (res.data.result.formChargeId) {
            setMainData((prev) => ({
              ...prev,
              formChargeId: res.data.result.formChargeId,
            }));
          }
          Notify.success('儲存成功');
          cbFn && cbFn();
        } else {
          Notify.warn('儲存失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  const handleDelete = () => {
    if (mainData.formChargeId) {
      axios
        .post(
          '/api/charge/delete',
          { formChargeId: mainData.formChargeId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res && res.data && res.data.status) {
            Notify.success('刪除完成');
            setRedirectFlag('search');
          } else {
            Notify.warn('刪除失敗:' + res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          Notify.error(err);
        });
    }
  };

  //=======================================================
  const handleSubmit = (comment) => {
    axios
      .post(
        '/api/sign/submit',
        { formCode: 'charge', formId: mainData.formChargeId, comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('呈送完成');
          setRedirectFlag('search');
        } else {
          Notify.warn('呈送失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleApprove = (comment) => {
    axios
      .post(
        '/api/sign/approve',
        { formId: mainData.formChargeId, formCode: 'charge', comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('審核完成');
          setRedirectFlag('home');
        } else {
          Notify.warn('審核失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleReturn = (comment) => {
    axios
      .post(
        '/api/sign/return',
        { formId: mainData.formChargeId, formCode: 'charge', comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('退回完成');
          setRedirectFlag('home');
        } else {
          Notify.warn('退回失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleVoid = (comment) => {
    axios
      .post(
        '/api/sign/void',
        { formId: mainData.formChargeId, comment },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('作廢完成');
          setRedirectFlag('search');
        } else {
          Notify.warn('作廢失敗:' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setRemitDate({ value: moment(), value_msg: '' });
  };
  const handleRemitDateChange = (val) => {
    setRemitDate({ value: val, value_msg: '' });
  };
  const handleUpdateRemitDate = () => {
    if (!remitDate.value) {
      setRemitDate({ value: moment(), value_msg: '請輸入匯款日期' });
      return;
    }

    axios
      .post(
        '/api/charge/updateRemitDate',
        {
          formChargeId: mainData.formChargeId,
          remitDate: remitDate.value.format('YYYY-MM-DD'),
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          Notify.success('更新匯款日期完成');
          setMainData((prev) => ({
            ...prev,
            remitDate: remitDate.value.format('YYYY-MM-DD'),
          }));
          handleModalClose();
        } else {
          Notify.warn('更新匯款日期失敗: ' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  const handleFileModalOpen = () => {
    setFileModalOpen(true);
  };
  const handleFileModalClose = () => {
    setFileModalOpen(false);
    setBillFile({ value: '', value_msg: '' });
  };
  const handleBillFileChange = (val) => {
    setBillFile({ value: val, value_msg: '' });
  };
  const handleUpdateBillFile = () => {
    if (!billFile.value) {
      setBillFile({ value: '', value_msg: '請上傳發票檔案' });
      return;
    }
    if (convertToFormInvoice) {
      const validResult = ValidFormData(invoiceData, [
        { name: 'invoiceItemId', type: 'input', required: true },
        { name: 'documentDate', type: 'date', required: true },
      ]);
      if (!validResult.status) {
        setInvoiceData(validResult.data);
        return;
      }
    }

    axios
      .post(
        '/api/charge/updateBillFileAndInvoiceData',
        {
          formChargeId: mainData.formChargeId,
          file: billFile.value,
          convert: convertToFormInvoice,
          invoiceData: invoiceData,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          setMainData((prev) => ({
            ...prev,
            file: billFile.value,
          }));
          const msg = convertToFormInvoice ? '上傳發票檔案及請款單資料完成' : '上傳發票檔案完成';
          Notify.success(msg);
          handleFileModalClose();
        } else {
          Notify.warn('上傳發票檔案失敗: ' + res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };
  const handleInvoiceDataChange = (name) => (e) => {
    setInvoiceData((prev) => ({
      ...prev,
      [name]: e && e.target ? e.target.value : e,
      [name + '_msg']: '',
    }));
  };

  //=======================================================
  const handleDownloadPdf = () => {
    axios
      .post(
        '/api/pdf/charge/create',
        {
          userId: mainData.userId,
          chargeYearMon: mainData.chargeYearMon,
        },
        { headers: { Authorization: user.token, Accept: 'application/pdf' }, responseType: 'arraybuffer' }
      )
      .then((res) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.download = mainData.formNumber + '.pdf';
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          Notify.warn('下載PDF失敗');
        }
      })
      .catch((err) => {
        console.error(err);
        Notify.error(err);
      });
  };

  //=======================================================
  if (redirectFlag === 'home') {
    return <Redirect to={HOME_PAGE} />;
  }
  if (redirectFlag === 'search') {
    return <Redirect to={SEARCH_PAGE} />;
  }
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title>收款單</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              {showDownloadBtn && (
                <Button size="large" onClick={handleDownloadPdf}>
                  下載PDF
                </Button>
              )}
            </Col>
            <Col>
              {showUploadBtn && (
                <Button size="large" onClick={handleFileModalOpen}>
                  上傳發票影本
                </Button>
              )}
            </Col>
            <Col>
              {showUpdateRemitDateBtn && (
                <Button size="large" onClick={handleModalOpen}>
                  更新匯款日期
                </Button>
              )}
            </Col>
            <Col>
              <SignControlBar
                backUrl="/form/charge/search"
                btnCtrl={formMode.button}
                saveFn={handleSave}
                deleteFn={handleDelete}
                submitFn={handleSubmit}
                approveFn={handleApprove}
                returnFn={handleReturn}
                voidFn={handleVoid}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={6}>
          <Display label="表單單號" value={mainData.formNumber || '自動產生'} />
        </Col>
        <Col span={18}>
          {formFlow && Array.isArray(formFlow) && formFlow.length > 0 ? (
            <Display label="表單流程" value={GetFormFlowString(formFlow)} />
          ) : (
            <Display label="表單流程" value="申請人 ➡️ 承辦 ➡️ 部門主管 ➡️ 承辦確認" />
          )}
        </Col>
        <Col span={8}>
          <Display label="申請日期" value={DateFormat(mainData.crDate || moment())} />
        </Col>
        <Col span={8}>
          <Display label="部門" value={mainData.deptName} />
        </Col>
        <Col span={8}>
          <Display label="申請人員" value={mainData.userName} />
        </Col>
        <Col span={12}>
          {formMode.canEdit ? (
            <Input
              required
              label="收款項目"
              value={mainData.chargeItem}
              msg={mainData.chargeItem_msg}
              onChange={handleMainDataChange('chargeItem')}
            />
          ) : (
            <Display label="收款項目" value={mainData.chargeItem} />
          )}
        </Col>
        <Col span={12}>
          {formMode.canEdit ? (
            <SelectCustom
              required
              canSearch
              label="收款對象"
              options={
                convertToFormInvoice
                  ? companyList
                  : _.union(
                      companyList,
                      mainData.chargeParty
                        ? [{ label: mainData.chargeParty, value: mainData.chargeParty }]
                        : []
                    )
              }
              value={mainData.chargePartyId}
              msg={mainData.chargeParty_msg}
              onChange={handleMainDataChange('chargeParty')}
            />
          ) : (
            <Display label="收款對象" value={mainData.chargeParty} />
          )}
        </Col>
        {mainData.chargeYearMon && (
          <Col span={8}>
            <Display label="月結月份" value={mainData.chargeYearMon} />
          </Col>
        )}
        <Col span={8}>
          {formMode.canEdit ? (
            <Input
              required
              label="金額總計"
              value={mainData.totalAmount}
              msg={mainData.totalAmount_msg}
              onChange={handleMainDataChange('totalAmount')}
            />
          ) : (
            <Display label="金額總計" value={NumberFormat(mainData.totalAmount)} />
          )}
        </Col>
        <Col span={8}>
          {formMode.canEdit ? (
            <File label="附件" value={mainData.chargeFiles} onChange={handleMainDataChange('chargeFiles')} />
          ) : (
            <File label="附件" disabled={true} value={mainData.chargeFiles} />
          )}
        </Col>
        <Col span={24}>
          {mainData.remitDate && (
            <Col span={8}>
              <Display label="匯款日期" value={DateFormat(mainData.remitDate)} />
            </Col>
          )}
          {mainData.file && (
            <Col span={8}>
              <File label="發票影本" disabled={true} value={mainData.file} />
            </Col>
          )}
        </Col>
        <Col span={24}>
          {formMode.canEdit ? (
            <Textarea label="備註" rows={3} value={mainData.memo} onChange={handleMainDataChange('memo')} />
          ) : (
            <Display label="備註" value={mainData.memo} />
          )}
        </Col>
      </Row>
      {detailList && detailList.length > 0 && (
        <Row>
          <Divider dashed />
          <Table columns={GenerateColumns} dataSource={detailList} />
        </Row>
      )}
      <Divider dashed />
      <SignHistory data={historyList} signStatus={mainData.signStatus} signerNames={mainData.signerNames} />
      <Modal width="600px" visible={modalOpen}>
        <Title>更新匯款日期</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <DatePicker
              required
              label="匯款日期"
              value={remitDate.value}
              msg={remitDate.value_msg}
              onChange={handleRemitDateChange}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleUpdateRemitDate}>
              更新
            </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
      <Modal width="600px" visible={fileModalOpen}>
        <Title>上傳發票影本</Title>
        <Divider />
        <Row>
          <Col span={12}>
            <File
              required
              label="發票影本"
              value={billFile.value}
              msg={billFile.value_msg}
              onChange={handleBillFileChange}
            />
          </Col>
        </Row>
        {convertToFormInvoice && (
          <Row>
            <SubTitle>轉請款單資訊</SubTitle>
            <Divider />
            <Col span={12}>
              <Select
                required
                canSearch
                label="請款項目"
                options={invoiceItemList}
                value={invoiceData.invoiceItemId}
                msg={invoiceData.invoiceItemId_msg}
                onChange={handleInvoiceDataChange('invoiceItemId')}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                required
                label="單據日期"
                value={invoiceData.documentDate}
                msg={invoiceData.documentDate_msg}
                onChange={handleInvoiceDataChange('documentDate')}
              />
            </Col>
          </Row>
        )}
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={handleUpdateBillFile}>
              上傳
            </Button>
          </Col>
          <Col>
            <Button onClick={handleFileModalClose}>關閉</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default FormCharge;
